import React, { useContext, useEffect } from 'react';
import { Provider } from 'react-redux';
import { globalConfigContext } from '@context/GlobalConfigContext';
//import store
import store from './store/index';
import moment from 'moment';
import 'moment/locale/ar';
// import router
import Router from './Router';
//import i18n
import './plugins/i18n';
//import compoents
//CSS Imports
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import '@assets/css/App.css';

function App() {
	const { arabic } = useContext(globalConfigContext);

	useEffect(()=>{
		caches.keys().then((names) => {
			names.forEach((name) => {
			  caches.delete(name);
			});
		  });
	})

	useEffect(() => {
		if (arabic) {
			moment.locale('ar');
		} else {
			moment.locale('en');
		}
	}, [arabic]);

	return (
		<Provider store={store}>
			<div className="App">
				<Router />
			</div>
		</Provider>
	);
}

export default App;
