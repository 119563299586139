import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Alert, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import screen from '../assets/imgs/svgs/screen.svg';
import { fetchShowDetails } from '@store/booking/bookingActions';
import MovieHeader from '@components/partials/MovieHeader';
import ip1 from '../assets/imgs/ip1.jpg';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import moment from 'moment';
import BookingService from '../ApiServices/BookingService';
import axios from 'axios';
import GoBack from '@components/partials/GoBack';

function FinalTicket(props) {
	const { booking_id } = props.match.params;
	const t = props.t;
	const dispatch = useDispatch();
	const showDetails = useSelector((state) => state.booking.show_details);
	const history = useHistory();

	const [bookingdata, setbookingdata] = useState([]);

	const redirectToHome = () => {
		history.push('/');
	};

	const getTicket = async () => {
		let a = await BookingService.FinalTicket(booking_id);
		setbookingdata(a.data.Records);

	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			history.push('/');
		}, 30000); // 60000 milliseconds = 1 minute

		return () => clearTimeout(timeout);
	}, [history]);



	useEffect(() => {
		window.scrollTo(0, 0);
		// console.log(booking_id, "booking id")
		getTicket();

	}, [booking_id]);


	useEffect(()=>{


	
		if(bookingdata && bookingdata.length > 0 ) {

	// 		console.log(bookingdata, "bookingdata >>>>>>>>>>>>>>>>>>")
	// 		console.log(bookingdata, "bookingdata 1 >>>>>>>>>>>>>>>>>>")

	// 		let bookingID = 	localStorage.getItem("booking_id", bookingdata[0]?.sb_id)
	// let printStatus =		localStorage.getItem("status_of_print", true)

	// let isPrinted = (printStatus == true && bookingID == bookingdata[0]?.sb_id) 

	// console.log(isPrinted, "isPrinted >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")


			// axios.get(`http://localhost:3001/print?data=pos/boplusfnb-ticket/${bookingdata[0]?.sb_id}/${bookingdata[0]?.cine_id}/0`)
			// .then((response) => {
			// 	 // Delay the second API call by 1.5 seconds (1500 milliseconds)
			// })
			// .catch((error) => {
			// 	// Handle any errobrs from the first API call
			// });

		// if((printStatus == true && bookingID == bookingdata[0]?.sb_id)){
			localStorage.setItem("booking_id", bookingdata[0]?.sb_id)
			localStorage.setItem("status_of_print", true)

			axios.get(`http://localhost:3001/print?data=pos/generate-entrance-ticket/${bookingdata[0]?.sb_id}`)
					.then((response) => {
						// Handle the response of the second API call
					})
					.catch((error) => {
						// Handle any errors from the second API call
					});
		// }


			// setTimeout(() => {
			// 	axios.get(`http://localhost:3001/print?data=pos/generate-entrance-ticket/${bookingdata[0]?.sb_id}`)
			// 		.then((response) => {
			// 			// Handle the response of the second API call
			// 		})
			// 		.catch((error) => {
			// 			// Handle any errors from the second API call
			// 		});
			// }, 1500);
		}
	}, [bookingdata])

	const onClickBack = () => {
		// history.push("/");
		window.location.href = "https://amwaj-kiosk-cinepolis-brij.binarynumbers.io";
	};


	return (
		<div className="">
			<MovieHeader />
			<div className="bg-light-black">
				{/* <div className="mx-3"><GoBack to="prev" className="" /></div> */}
				<Container fluid className="py-3 mb-4">
					<Row className='my-2'>
						<Col className="d-flex justify-content-center align-items-center" md={4}>
							<div onClick={onClickBack} className="go_back">
								<div className="button_wrap">
									<div className="go_back_button"></div>
								</div>
								<h2 className="big_text">{t('GO BACK')}</h2>
							</div>
						</Col>
					</Row>
					<Row className="my-5">
						<Col className="text-center">
							<p className="kumbh fw-bold">
								{t('Your booking is successful')},<br /> {t('enjoy the show')}.
							</p>
						</Col>
					</Row>

					<Row className="">
						<Col xs={8} className="mx-auto">
							<Row className="mxh-70 bg-pb d-flex align-items-center py-3">
								<Col>
									<p className="kumbh fw-bold text-center">{t('YOUR TICKET')}</p>
								</Col>
							</Row>
							<Row>
								<Col className="px-0">
									<div className="hero">
										<div className="imgOverlay d-flex justify-content-center">
											{/* <img className="" src={bookingdata[0]?.languageSpecificImages[0].artwork} style={{height:"300px"}} /> */}
											<img className="" src={bookingdata[0]?.languageSpecificImages[0]?.artwork} />
										</div>
										<div className="hero-text px-3 d-flex flex-column justify-content-end">
											<Container fluid>
												<Row className="pb-4">
													<Col>
														<Row className="pb-3">
															<Col md={8}>
																<div className="d-flex align-items-center">
																	<img src={bookingdata[0]?.languageSpecificImages[0].artwork} alt="title" height="128px" />
																	<div className="d-flex flex-column justify-content-end pl-3 kumbh fw-reg frxl-12 frs-10">
																		<p className="fw-bold frxl-18 frs-16">
																			{bookingdata[0]?.movie_title}
																		</p>
																		<p className="mb-2">{bookingdata[0]?.cine_name}</p>
																		<p className="mb-2">
																			{moment(bookingdata[0]?.sb_booking_date_time).format('ddd, Do, MMM, YYYY')}
																		</p>
																		<p className="mb-2">
																			{t('Seats')}:
																			{
																				bookingdata[0]?.seats.length > 0
																					? bookingdata[0]?.seats.map((item) => (
																						<>{item}</>

																					))
																					: <></>
																			}
																		</p>
																		{/* <p className="">4D E-motion</p> */}
																	</div>
																</div>
															</Col>
															<Col md={4} className="text-right">
																<div className="d-flex flex-column align-items-end">
																	<img
																		src={bookingdata[0]?.qrcode}
																		height="128px"
																		width="128px"
																		className=""
																		alt="picture"
																	/>
																	<p className="kumbh fw-reg">
																		{t('Scan this code and recieve the ticket on your phone')}
																	</p>
																</div>
															</Col>
														</Row>
													</Col>
												</Row>
											</Container>
										</div>
									</div>
								</Col>
							</Row>

							<Row className="bgb py-5 mb-4">
								<Col xs={8} className="br-1">
									<div className="px-3 frxl-20 frl-18 frs-16 text-light kumbh fw-reg">
										<p className="ls-2 fw-bold pb-3 bb-1 mb-3">{t('Order Details')}</p>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="">
												{/* {t('Tickets')} {showDetails.seats_name} */}
												{t('Tickets')}

											</span>
											<span className="fw-bold frxl-24 frl-22 frm-18 frs-14">
												{/* {(showDetails.ticket_price_in_cents - showDetails.ticket_taxes_in_cents) /
													100}{' '}
												SAR */}
												{
													bookingdata[0]?.seats.length > 0
														? bookingdata[0]?.seats.map((item) => (
															<>{item}</>
														))
														: <></>
												}
											</span>
										</div>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="">
												{t('Food and Beverages')}(x{bookingdata[0]?.fnb_items.length})
											</span>
											<span className="fw-bold frxl-24 frl-22 frm-18 frs-14">
												{/* {(showDetails.fnb_price_in_cents - showDetails.fnb_taxes_in_cents) / 100} SR */}
												{/* {
													bookingdata[0]?.fnb_items.length > 0
														?<>bookingdata[0]?.fnb_items.length</>
												: <>0 OMR</>
												} */}
												0 OMR
											</span>
										</div>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="frxl-18 frl-16 frs-14">
												{t('Taxes')}
												<br />
												{/* <span className="blurred-white frxl-14 frl-12 frm-10 frs-8">(15%)</span> */}
											</span>
											<span className="fw-bold frxl-24 frl-22 frm-18 frs-14">
												{/* {(showDetails.fnb_taxes_in_cents + showDetails.ticket_taxes_in_cents) / 100}{' '}SR */}
												{parseFloat(bookingdata[0]?.internet_handling_fee).toFixed(2)} OMR
											</span>
										</div>
										{/* <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
                                            <span className="frxl-18 frl-16 frs-14">Discount Applied</span>
                                            <span className="fw-bold frxl-24 frl-22 frm-18 frs-14">-15 SR</span>
                                        </div> */}
										<div className="pb-3 d-flex justify-content-between bb-1">
											<span className="frxl-18 frl-16 frs-14">{t('Total')}</span>
											<span className="fw-bold frxl-24 frl-22 frm-18 frs-14">
												{/* {showDetails.transaction_net_price} SAR */}
												{parseFloat(bookingdata[0]?.sb_total_amount + bookingdata[0]?.internet_handling_fee).toFixed(2)} OMR
											</span>
										</div>

										{/* <div className="mt-3 pb-3 d-flex justify-content-between bb-1">
                                            <span className="frxl-18 frl-16 frs-14">QR Code</span>
                                            <img src={ip1} width="150" height="150" />
                                        </div> */}
									</div>
								</Col>
								<Col xs={4} className="">
									<div className="px-4 kumbh fw-reg">
										<div className="d-flex flex-column mt-3 mb-5">
											<span className="blurred-white">{t('Booking Date')}</span>
											<span className="fw-bold frxl-28 frl-24 frs-20">
												{moment(bookingdata[0]?.transaction_date_time).format('DD/MM/YYYY')}
											</span>
										</div>
										<div className="d-flex flex-column my-5">
											<span className="blurred-white">{t('Booking Time')}</span>
											<span className="fw-bold frxl-28 frl-24 frs-20">
												{moment(bookingdata[0]?.transaction_date_time).format('HH:mm A')}
											</span>
										</div>
										<div className="d-flex flex-column my-5">
											<span className="blurred-white">{t('Payment Method')}</span>
											<span className="fw-bold frxl-28 frl-24 frs-20">{bookingdata[0]?.pm_payment_mode}</span>
										</div>
										<div className="d-flex flex-column mt-5">
											<span className="blurred-white">{t('Ticket ID')}</span>
											<span className="fw-bold frxl-28 frl-24 frs-20">{booking_id}</span>
										</div>
									</div>
								</Col>
							</Row>

							<Row className="mt-5 justify-content-center">
								<Col md={3}>
									<span className="ls-3 frxl-24 frl-22 frs-18 kumbh fw-bold p-3 blue-btn">
										{t('PRINT TICKET')}
									</span>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default withNamespaces()(FinalTicket);
