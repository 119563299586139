export const timeConvert = (n) => {
	var num = n;
	var hours = num / 60;
	var rhours = Math.floor(hours);
	var minutes = (hours - rhours) * 60;
	var rminutes = Math.round(minutes);
	return rhours + 'h' + rminutes + 'm';
};

export const getYoutubeVideoCode = (link) => {
	if (!link) {
		return '';
	}

	let code = null;
	if (link.indexOf('youtu.be') !== -1) {
		let res = link.split('/');
		code = res[3];
	} else {
		let res = link.split('=');
		code = res[1];
	}
	return code;
};

export const isImageURLNull = (url) => {
	if (url && (url.endsWith('null') || url.endsWith('undefined'))) {
		return true;
	}
	return false;
};

export function titleCase(word) {
	if (!word) return;
	var sentence = word.toLowerCase().split(' ');
	for (let i = 0; i < sentence.length; i++) {
		sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
	}

	return sentence.join(' ');
}

export const formatYuppErrors = (errs) => {
	return errs ? Object.assign({}, ...errs.inner.map((obj) => ({ [obj.path]: obj }))) : null;
};

export const translateSessionAttribute = (arabic, sessionAttribute) => {
	if (!sessionAttribute) return '';
	if (!arabic) return titleCase(sessionAttribute);


	// console.log(sessionAttribute, "KADSFJADSFJADSJF")

	const translations = {
		standard: 'ستاندر',
		macro: 'دقيق',
		junior: 'جونيور',
	};

	return translations[sessionAttribute.toLowerCase()]
		? translations[sessionAttribute.toLowerCase()]
		: '';
};

export function getArabicNumber(number) {
	var charIndex = 0;
	var NumericArabic = '';

	while (charIndex < number.length) {
		switch (number[charIndex]) {
			case '.':
				NumericArabic += '.';
				break;

			case '0':
				NumericArabic += '٠';
				break;

			case '1':
				NumericArabic += '١';
				break;

			case '2':
				NumericArabic += '٢';
				break;

			case '3':
				NumericArabic += '٣';
				break;

			case '4':
				NumericArabic += '٤';
				break;

			case '5':
				NumericArabic += '٥';
				break;

			case '6':
				NumericArabic += '٦';
				break;

			case '7':
				NumericArabic += '٧';
				break;

			case '8':
				NumericArabic += '٨';
				break;

			case '9':
				NumericArabic += '٩';
				break;

			default:
				NumericArabic += number[charIndex];
				break;
		}

		charIndex++;
	}

	return NumericArabic;
}

// //English to Arabic digits.
// String.prototype.EntoAr= function() {
//   return this.replace(/\d/g, d =>  '٠١٢٣٤٥٦٧٨٩'[d])
// }

// //Arabic to English digits.
// String.prototype.ArtoEn= function() {
//   return this.replace(/[\u0660-\u0669]/g,
//     d => d.charCodeAt() - 1632)
// }
