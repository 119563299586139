import Api from './Api';

export default {
	GetAllFnbCategories(payload) {
		return Api().get(`fnb/categories/${payload.cinema_id}`);
	},
	GetAllFnbItems(payload) {
		return Api().get(`fnb/fnb-items/${payload.cinema_id}/${payload.category_id}`);
	},
	GetModifierGroup(payload) {
		return Api().get(`fnb/modifier-group/${payload.cinema_id}/${payload.item_id}`);
	},
	GetAllModifiers(payload) {
		return Api().get(`fnb/modifiers/${payload.cinema_id}/${payload.item_id}`);
	},
	AddFnb(payload) {
		return Api().get(`fnb/add-fnb`, payload);
	},
	GetRecipeItems(payload) {
		return Api().get(`fnb/recipe-items/${payload.cinema_id}/${payload.item_id}`);
	},
};
