import React, { useEffect, useState, useContext, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import MovieHeader from '@components/partials/MovieHeader';
import { useSelector } from 'react-redux';
import { isImageURLNull, translateSessionAttribute } from '@helper/formatting';
import moment from 'moment';
import QRCode from 'qrcode';
import GoBack from '@components/partials/GoBack';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { withNamespaces } from 'react-i18next';
import round from 'lodash/round';
import { useReactToPrint } from 'react-to-print';
import ReceiptForCollectingTicket from '@components/ReceiptForCollectingTicket';

import ip1 from '../assets/imgs/ip1.jpg';
import bgticket from '../assets/imgs/ticket-bg.jpg';

import movieDef from '../assets/imgs/movie-default-portrait.jpg';
import movieDefLand from '../assets/imgs/movie-default-landscape.jpg';

import { Container, Row, Col } from 'react-bootstrap';

var opts = {
	errorCorrectionLevel: 'H',
	type: 'image/jpeg',
	quality: 0.3,
	margin: 1,
	width: '150',
	color: {
		dark: '#000000',
		light: '#FFFFFF',
	},
};

function KioskCollectTwo(props) {
	const ticketData = useSelector((state) => state.booking.ticketData);
	const { arabic, currency } = useContext(globalConfigContext);
	const [qrCodeData, setQrCodeDate] = useState('');
	const t = props.t;
	const receiptRef = useRef();

	const initPrint = useReactToPrint({
		content: () => receiptRef.current,
	});

	const bookingFeeTax = ticketData
		? round(ticketData.booking_fee - ticketData.booking_fee / (1 + ticketData.tax_percent / 100), 2)
		: 0;

	console.log(ticketData);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (ticketData && ticketData.bookingId) {
			QRCode.toDataURL(ticketData.bookingId, opts, function (err, url) {
				if (err) throw err;
				setQrCodeDate(url);
			});
		} else {
			setQrCodeDate(null);
		}
	}, [ticketData]);

	if (!ticketData) {
		return <Redirect to="/" />;
	}

	return (
		<div>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 1000,
					right: 0,
				}}
			>
				<MovieHeader />
			</div>

			<div className="receipt">
				<ReceiptForCollectingTicket data={ticketData} ref={receiptRef} />
			</div>

			<div className="receipt-overlay" />

			<Container fluid className="checkout-page-container py-3 mb-4">
				<Row>
					<Col>
						<GoBack to="prev" />
					</Col>
				</Row>

				<Row className="d-flex justify-content-center my-5 ">
					<Col xs={6}>
						<h1 className="fw-bold text-center">
							{t('collect_ticket.Print your ticket and enjoy the show!')}
						</h1>
					</Col>
				</Row>
				<Row className="fnb-checkout">
					<Col xs={8} className="mx-auto">
						<Row className="mxh-70 bg-pb d-flex align-items-center py-3">
							<Col>
								<p className="fw-bold text-center frs-24">{t('collect_ticket.YOUR TICKET')}</p>
							</Col>
						</Row>
						<Row>
							<Col className="px-0">
								<div className="hero">
									<div className="imgOverlay d-flex justify-content-center">
										<img
											className="bg-img"
											src={
												!isImageURLNull(ticketData.movie_image_url_2)
													? ticketData.movie_image_url_2
													: movieDefLand
											}
										/>
									</div>
									<div className="hero-text px-3 d-flex flex-column justify-content-end">
										<Container fluid>
											<Row className="pb-4">
												<Col>
													<Row className="pb-3">
														<Col xs={8}>
															<div className="d-flex align-items-center">
																<img
																	src={
																		!isImageURLNull(ticketData.movie_image_url_1)
																			? ticketData.movie_image_url_1
																			: movieDef
																	}
																	alt="title"
																	className="moviePoster"
																/>
																<div
																	className={`d-flex flex-column justify-content-end fw-reg frs-12 ${
																		arabic ? 'pr-3' : 'pl-3'
																	}`}
																>
																	<p className="fw-bold frs-18">
																		{arabic ? ticketData.movie_title_ar : ticketData.movie_title} (
																		{ticketData.SessionDimension ? ticketData.SessionDimension : ''}
																		)
																		{ticketData.movie_lang
																			? '(' +
																			  ticketData.movie_lang.substring(0, 2).toUpperCase() +
																			  ')'
																			: ''}
																	</p>
																	<p className="mb-2">{ticketData.cinema_name}</p>
																	<p className="mb-2">
																		{moment
																			.utc(ticketData.Showtime)
																			.format('ddd, Do, MMM, YYYY hh:mm A')}
																	</p>
																	<p className="mb-2">
																		{t('fnbconfirm.Seats')} {ticketData.seats_name}
																	</p>
																	<p className="">
																		{ticketData.SessionAttribute
																			? translateSessionAttribute(
																					arabic,
																					ticketData.SessionAttribute
																			  )
																			: translateSessionAttribute(arabic, 'premium')}
																	</p>
																</div>
															</div>
														</Col>
														<Col
															xs={4}
															className="d-flex flex-column justify-content-end align-items-end frs-12 fw-reg text-center"
														>
															<img src={qrCodeData} className="qr" />
															<p className="">{t('checkout.Scan this code and recieve')}</p>
															<p className="">{t('checkout.the ticket on your phone')}</p>
														</Col>
													</Row>
												</Col>
											</Row>
										</Container>
									</div>
								</div>
							</Col>
						</Row>

						<Row className="bgb py-5 mb-4">
							<Col xs={8} className="br-1">
								<div className="px-3 frs-20 fw-bold">
									<p className="ls-2 pb-3">{t('checkout.Order Details')}</p>
									<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
										<span className="blurred-text">
											{t('checkout.Tickets')}(x{ticketData.number_of_seats})
										</span>
										<span className="frs-24">
											{(
												(ticketData.ticket_price_in_cents - ticketData.ticket_taxes_in_cents) /
												currency.divided_by
											).toFixed(2)}{' '}
											{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
										</span>
									</div>
									<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
										<span className="blurred-text">
											{t('checkout.Food and Beverages')} (x{ticketData.fnb_items_count})
										</span>
										<span className="frs-24">
											{(
												(ticketData.fnb_price_in_cents - ticketData.fnb_taxes_in_cents) /
												currency.divided_by
											).toFixed(2)}{' '}
											{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
										</span>
									</div>
									<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
										<span className="blurred-text">
											{t('checkout.Taxes')}
											<br />
											<span className="blurred-text-2">({ticketData.tax_percent}%)</span>
										</span>
										<span className="frs-24">
											{(
												(ticketData.ticket_taxes_in_cents +
													ticketData.fnb_taxes_in_cents +
													bookingFeeTax) /
												currency.divided_by
											).toFixed(2)}{' '}
											{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
										</span>
									</div>
									{/* {ticketData.transaction_before_offer_net_price -
										ticketData.transaction_net_price >
										0 && (
										<div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
											<span className="blurred-text">{t('checkout.discount-applied')}</span>
											<span className="fw-bold frs-24">
												{(
													(ticketData.transaction_before_offer_net_price -
														ticketData.transaction_net_price) /
													currency.divided_by
												).toFixed(2)}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
									)} */}
									{/* <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
										<span className="blurred-text">{t('checkout.Booking Fee')}</span>
										<span className="frs-24">
											{((ticketData.booking_fee - bookingFeeTax) / currency.divided_by).toFixed(2)}{' '}
											{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
										</span>
									</div> */}
									{ticketData.transaction_before_offer_net_price -
										ticketData.transaction_net_price >
										0 && (
										<div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
											<span className="blurred-text">{t('checkout.discount-applied')}</span>
											<span className="frs-24">
												{(
													(ticketData.transaction_before_offer_net_price -
														ticketData.transaction_net_price) /
													currency.divided_by
												).toFixed()}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
									)}
									<div className="pb-3 d-flex justify-content-between bb-1">
										<span className="blurred-text">{t('collect_ticket.Total')}</span>
										<span className="frs-24">
											{(ticketData.transaction_net_price / currency.divided_by).toFixed(2)}{' '}
											{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
										</span>
									</div>
								</div>
							</Col>
							<Col xs={4}>
								<div className="h-100 w-100 d-flex flex-column justify-content-between frs-28 ml-4 fw-bold">
									<span>
										<p className="frs-16 blurred-text">{t('checkout.Booking Date')}</p>
										<p>{moment.utc(ticketData.transaction_date_time).format('DD/MM/YYYY')}</p>
									</span>
									<span>
										<p className="frs-16 blurred-text">{t('checkout.Booking Time')}</p>
										<p>{moment.utc(ticketData.transaction_date_time).format('hh:mm A')}</p>
									</span>
									<span>
										<p className="frs-16 blurred-text">{t('checkout.Payment Method')}</p>
										<p>{ticketData.payment_option}</p>
									</span>
									<span>
										<p className="frs-16 blurred-text">{t('checkout.Ticket ID')}</p>
										<p>{ticketData.bookingId}</p>
									</span>
								</div>
							</Col>
						</Row>

						<Row className="mt-5 justify-content-center">
							<Col className="text-center">
								<span
									className="d-inline-block ls-3 frs-24 fw-bold px-5 blue-btn"
									onClick={initPrint}
								>
									{t('checkout.PRINT TICKET')}
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

			{/* <Container>
				<h1 className="fw-bold text-center py-5">
					Print your ticket and enjoy <br /> the show!
				</h1>
				<div className="ticket-card pb-5">
					<h2 className="title">YOUR TICKET</h2>
					<Row>
						<Col md={12} className="ticket-header pt-4">
							<div className="ticket-overlay">
								<img
									src={
										!isImageURLNull(ticketData.movie_image_url_2)
											? ticketData.movie_image_url_2
											: movieDefLand
									}
									className="img-fluid"
								/>
							</div>
							<Row className="ticket-detail">
								<Col md={3}>
									<img
										className="img-fluid img-center w-75"
										src={
											!isImageURLNull(ticketData.movie_image_url_1)
												? ticketData.movie_image_url_1
												: movieDef
										}
									/>
								</Col>
								<Col md={3}>
									<h5 className="fw-bold">
										{ticketData.movie_title} (
										{ticketData.SessionDimension ? ticketData.SessionDimension : ''})
										{ticketData.movie_lang
											? '(' + ticketData.movie_lang.substring(0, 2).toUpperCase() + ')'
											: ''}
									</h5>
									<p>{ticketData.cinema_name}</p>
									<p>{moment(ticketData.Showtime).format('ddd, Do, MMM, YYYY hh:MM A')}</p>
									<p>Seats {ticketData.seats_name}</p>
									<p>{ticketData.SessionAttribute ? ticketData.SessionAttribute : 'PREMIUM'}</p>
								</Col>
								<Col md={3} className="offset-md-3">
									<p>Scan this code and recieve the ticket on your phone</p>
									<img src={qrCodeData} height="150" width="150" />
								</Col>
							</Row>
						</Col>
						<Col md={12} className="pt-5">
							<Row className="ticket-order">
								<Col md={8} className="left-content br-1 pb-1 px-5">
									<h5 className="fw-bold pl-5">Order Details</h5>
									<div className="px-5">
										<div className="d-flex justify-content-between align-items-center bb-1">
											<h6>Tickets(x{ticketData.number_of_seats})</h6>
											<h4>
												{(ticketData.ticket_price_in_cents - ticketData.ticket_taxes_in_cents) /
													100}{' '}
												SR
											</h4>
										</div>
										<div className="d-flex justify-content-between align-items-center bb-1">
											<h6>
												Food and <br />
												Beverages (x{ticketData.fnb_items_count})
											</h6>
											<h4>
												{(ticketData.fnb_price_in_cents - ticketData.fnb_taxes_in_cents) / 100} SR
											</h4>
										</div>
										<div className="d-flex justify-content-between align-items-center">
											<h6>
												Taxes <br />
												(8%)
											</h6>
											<h4>
												{(ticketData.ticket_taxes_in_cents + ticketData.fnb_taxes_in_cents) / 100}{' '}
												SR
											</h4>
										</div>
										<div className="d-flex justify-content-between align-items-center bb-1">
											<h6>Discount Applied</h6>
											<h4>-15 SR</h4>
										</div>
										<div className="d-flex justify-content-between align-items-center">
											<h6>Total</h6>
											<h4>{ticketData.transaction_net_price / 100} SR</h4>
										</div>
									</div>
									<div className="linebreak"></div>
								</Col>
								<Col md={4} className="right-content pl-5">
									<div>
										<h6>Booking Date</h6>
										<h4>{moment(ticketData.transaction_date_time).format('DD/MM/YYYY')}</h4>
									</div>
									<div>
										<h6>Booking Time</h6>
										<h4>{moment(ticketData.transaction_date_time).format('HH:MM A')}</h4>
									</div>
									<div>
										<h6>Payment Method</h6>
										<h4>Credit Card</h4>
									</div>
									<div>
										<h6>Ticket Id</h6>
										<h4>{ticketData.bookingId}</h4>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div className="my-5">
					<button className="blue-btn btn-center px-5">PRINT TICKET</button>
				</div>
			</Container> */}
		</div>
	);
}

export default withNamespaces()(KioskCollectTwo);
