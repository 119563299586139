import React from 'react';
import { Spinner as BSpinner } from 'react-bootstrap'

function Spinner({ active=true }) {
    return (
        active 
        ? (<div className="c-spiner-container">
            <BSpinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </BSpinner>
        </div>)
        : null
    )
}

export default Spinner;