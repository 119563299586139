import React, { useState, useEffect, useContext } from 'react';
import { globalConfigContext } from '@context/GlobalConfigContext';
import moment from 'moment';
import QRCode from 'qrcode';
import ip1 from '@assets/imgs/ip1.jpg';

var opts = {
	errorCorrectionLevel: 'H',
	type: 'image/jpeg',
	quality: 0.3,
	margin: 1,
	width: '150',
	color: {
		dark: '#000000',
		light: '#FFFFFF',
	},
};

const Receipt = ({ data }) => {
	const { arabic, currency } = useContext(globalConfigContext);
	const [qrcode, setQrcode] = useState(null);

	useEffect(() => {
		if (data && data.bookingId) {
			QRCode.toDataURL(data.bookingId, opts, function (err, url) {
				if (err) throw err;
				setQrcode(url);
			});
		} else {
			setQrcode(null);
		}
	}, []);

	// const dateString = data.TransacitonRequestDateTime;
	// const day = dateString.substring(6, 8);
	// const month = dateString.substring(4, 6);
	// const year = dateString.substring(0, 4);
	// const hour = dateString.substring(8, 10);
	// const mins = dateString.substring(10, 12);
	// const seconds = dateString.substring(12, 14);

	// const date = `${day}/${month}/${year}`;
	// const time = `${hour}:${mins}:${seconds}`;
	// const momentdatestring = `${year}-${month}-${day} ${hour}:${mins}:${seconds}`;

	return (
		<center>
			<div
				style={{
					height: '100%',
					width: '100%',
					overflowY: 'auto',
					backgroundColor: 'white',
					color: 'black',
				}}
			>
				<table style={{ fontFamily: 'arial', width: '380px' }} align="center">
					<tbody>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'rtl' }}>
											{arabic ? data.cinema_name_ar : data.cinema_name}
										</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<font size="2">
									<span>Transaction Information</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{moment.utc(data.transaction_date_time).locale('en').format('DD/MM/YYYY')}
									</span>
								</font>
							</td>
							<td style={{ textAlign: 'right' }}>
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{moment.utc(data.transaction_date_time).locale('en').format('HH:mm')}
									</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{moment.utc(data.transaction_date_time).locale('ar').format('DD/MM/YYYY')}
									</span>
								</font>
							</td>
							<td style={{ textAlign: 'right' }}>
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{moment.utc(data.transaction_date_time).locale('ar').format('HH:mm')}
									</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<b>
									<font size="2">{data.payment_option}</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="30px">
								<font size="2">{data.card_number}</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="30px">
								<font style={{ float: 'right' }} size="2">
									{data.expiry_date
										? `${data.expiry_date.substring(2)}/${data.expiry_date.substring(0, 2)}`
										: ''}
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<b>
									<font size="2">PURCHASE AMOUNT</font>
								</b>
							</td>
							<td style={{ textAlign: 'right' }}>
								<b>
									<font size="2">
										<span style={{ direction: 'rtl' }}>مبلغ الشراء</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<b>
									<font size="2">
										{currency.currency_short_code} {data.transaction_net_price}
									</font>
								</b>
							</td>
							<td style={{ textAlign: 'right' }}>
								<b>
									<font size="2">{data.transaction_net_price} ريـال</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
								<b>
									<font size="2">TICKET DETAILS</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">SEAT NAME(S)</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>{data.seats_name}</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">BOOKING ID</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>{data.bookingId}</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">CINEMA NAME</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{arabic ? data.cinema_name_ar : data.cinema_name}
									</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">MOVIE NAME</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{arabic ? data.movie_title_ar : data.movie_title}
									</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">SHOW TIME</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{arabic
											? moment.utc(data.Showtime).locale('ar').format('DD/MM/YYYY HH:mm')
											: moment.utc(data.Showtime).locale('en').format('DD/MM/YYYY HH:mm')}
									</span>
								</font>
							</td>
						</tr>

						<tr>
							<td colspan="3" style={{ textAlign: 'center', padding: '1.5rem 0' }}>
								<img src={qrcode} height="100px" width="100px" />
							</td>
						</tr>

						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">
									<span style={{ direction: 'rtl' }}>شكرًا لاستخدامكم مدى</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">THANK YOU FOR USING mada</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">
									<span style={{ direction: 'rtl' }}>يرجي الاحتفاظ بالايصال</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">PLEASE RETAIN RECEIPT</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">
									<b>
										<span style={{ direction: 'rtl' }}>** نسخة العميل **</span>
									</b>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="20px">
								<b>
									<font size="1">** CUSTOMER COPY **</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">{data.EMVTagsIssuer}</font>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</center>
	);
};

export default Receipt;
