import React, { useState, useEffect, useContext } from 'react';
import { globalConfigContext } from '@context/GlobalConfigContext';
import moment from 'moment';
import QRCode from 'qrcode';
import ip1 from '@assets/imgs/ip1.jpg';

var opts = {
	errorCorrectionLevel: 'H',
	type: 'image/jpeg',
	quality: 0.3,
	margin: 1,
	width: '150',
	color: {
		dark: '#000000',
		light: '#FFFFFF',
	},
};

const Receipt = ({ data }) => {
	const { arabic, currency } = useContext(globalConfigContext);
	const [qrcode, setQrcode] = useState(null);

	return (
		<center>
			<div
				style={{
					height: '100vh',
					width: '100%',
					overflowY: 'auto',
					backgroundColor: 'white',
					color: 'black',
				}}
			>
				<table style={{ fontFamily: 'arial', width: '380px' }} align="center">
					<tbody>
						<tr>
							<td colspan="2" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'ltr', padding: '0 15px', paddingTop: '15px' }}>
											Cinepolis Cinemas
										</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'rtl', padding: '0 15px' }}>Cinepolis Ar</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								SCREEN 1
							</td>
							<td colspan="1" style={{ textAlign: 'right', paddingRight: '15px' }}>
								Screen ar
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'center' }}>
								<font size="4">
									<b style={{ padding: '0 15px' }}>Fast &amp; Furious 9</b>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								<span>Show Date/ </span>
								<span>تاريخ العرض:</span>
								<span style={{ float:'right' }}>{moment.utc().locale('en').format('DD/MM/YYYY')}</span>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								<span>Show Time/وقت العرض: </span>
								<span style={{ float:'right' }}>{moment.utc().locale('en').format('hh:mm a')}</span>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								Rating/18: ar +
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								<b>
									<font size="4">Seat/Seat ar: 1-15</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								Seat Type/Seat ar: Premium KSA
							</td>
						</tr>
						<tr>
							<td colspan="1">
								<font size="2">
									<span style={{ direction: 'rtl', paddingLeft: '15px' }}>Price: SAR 55.00</span>
								</font>
							</td>
							<td style={{ textAlign: 'right' }}>
								<font size="2">
									<span style={{ direction: 'rtl', paddingRight: '15px' }}>price ar: ar</span>
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'left' }}>
							<td colspan="2" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									T/N: 80
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<img src={ip1} height="100px" width="100px" />
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									Luxury Entertainment LLC
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									2nd Floor, AL Jameah Plaza
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									Jeddah, KSA
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<b>
									<font size="2" style={{ padding: '0 15px 15px' }}>
										www.cinepolisgulf.com
									</font>
								</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</center>

		// <center>
		// 	<div
		// 		style={{
		// 			height: '100%',
		// 			width: '100%',
		// 			overflowY: 'auto',
		// 			backgroundColor: 'white',
		// 			color: 'black',
		// 		}}
		// 	>
		// 		<table style={{ fontFamily: 'arial', width: '380px' }} align="center">
		// 			<tbody>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<b>
		// 							<font size="4">
		// 								<span style={{ direction: 'rtl' }}>ﻧ٦ﻑ۲ﻑ٤ﻑﻲ٤ﻑ۵ﻑﻟﺉﺿﻑﻣ۵ﻑﻟﻧﻷ۸ﻑ٤ﻑ</span>
		// 							</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						mPOS
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<span style={{ direction: 'rtl' }}>ﻧ٦ﻑ۲ﻑ٤ﻑﻲ٤ﻑ۵ﻑﻟﺉﺿﻑﻣ۵ﻑﻟﻧﻷ۸ﻑ٤ﻑ</span>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						King Saudi Street
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>١٢/١١/٢٠٢٠</span>
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>١٥:١٠:١٣</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">27/11/2020</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">17:29:10</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td>
		// 						<font size="1">HAJH</font>
		// 					</td>
		// 					<td style={{ textAlign: 'left' }}>
		// 						<font size="1">0101010101010101</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<font size="1">882374982730483</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td>
		// 						<font size="1">1040</font>
		// 					</td>
		// 					<td style={{ textAlign: 'left' }}>
		// 						<font size="1">0034772</font>
		// 						<font size="1" style={{ paddingLeft: '16px' }}>
		// 							6.00
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="1">893724920978924</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<b>
		// 							<font size="2">mada</font>
		// 						</b>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<b>
		// 							<font size="2">arabic</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="top" height="30px">
		// 						<font size="2">Purchase</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="30px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>شراء</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="top" height="30px">
		// 						<font size="2">9283467920397583</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="30px">
		// 						<font style={{ float: 'right' }} size="2">
		// 							08/20
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<b>
		// 							<font size="2">
		// 								<span style={{ direction: 'rtl' }}>مبلغ الشراء</span>
		// 							</font>
		// 						</b>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<b>
		// 							<font size="2">١.٠٠ ريـال</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<b>
		// 							<font size="2">PURCHASE AMOUNT</font>
		// 						</b>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<b>
		// 							<font size="2">
		// 								SAR 1.00
		// 							</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="bottom" height="30px">
		// 						<b>
		// 							<font size="2">
		// 								<span style={{ direction: 'rtl' }}>مقبولة</span>
		// 							</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="bottom" height="30px">
		// 						<b>
		// 							<font size="2">
		// 								CARDHOLDER PIN
		// 							</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="30px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>تم التحقق من الرقم السري للعميل</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="30px">
		// 						<font size="2">Verified</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
		// 						<font size="1">
		// 							<span style={{ direction: 'rtl' }}>أوافق على الخصم من حسابي للمبلغ</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
		// 						<font size="1">DEBIT MY ACCOUNT FOR THE AMOUNT</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<font size="1">
		// 							<b>
		// 								<span style={{ direction: 'rtl' }}>رمز الموافقة</span>
		// 							</b>
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<font size="1">
		// 							<b>
		// 								<span style={{ direction: 'rtl' }}>٥٨٩١٠٠</span>
		// 							</b>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<font size="1">
		// 							<b>APPROVAL CODE</b>
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<font size="1">
		// 							<b>arabic</b>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="bottom" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>١٢/١١/٢٠٢٠</span>
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="bottom" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>١٥:١٠:١٣</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">17/10/20</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>17:29:10</span>
		// 						</font>
		// 					</td>
		// 				</tr>

		//                 <tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
		// 						<b>
		//                         <font size="2">TICKET DETAILS</font>
		//                         </b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">SEAT NAME(S)</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>H1, B1</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">BOOKING ID</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>187567465465</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">CINEMA NAME</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>OASIS MALL</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">MOVIE NAME</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>DDLJ</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">SHOW TIME</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>17:29:10, 05/10/20</span>/span>
		// 						</font>
		// 					</td>
		// 				</tr>

		//                 <tr>
		// 					<td colspan="3" style={{ textAlign: 'center', padding: '1.5rem 0' }}>
		// 						<img src={ip1} height="100px" width="100px"/>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">
		// 							<span style={{ direction: 'rtl' }}>شكرًا لاستخدامكم مدى</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">THANK YOU FOR USING mada</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">
		// 							<span style={{ direction: 'rtl' }}>يرجي الاحتفاظ بالايصال</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">PLEASE RETAIN RECEIPT</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">
		// 							<b>
		// 								<span style={{ direction: 'rtl' }}>** نسخة العميل **</span>
		// 							</b>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="20px">
		// 						<b>
		// 							<font size="1">** CUSTOMER COPY **</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">iufiouseourwoieoiwueroiwuoiuoier</font>
		// 					</td>
		// 				</tr>
		// 			</tbody>
		// 		</table>
		// 	</div>
		// </center>
	);
};

export default Receipt;
