import {
    FETCH_NOW_PLAYING_MOVIES, FETCH_NOW_PLAYING_ERROR, FETCH_BANNERS, FETCH_BANNERS_ERROR,
    FETCH_MOVIE_DETAILS, FETCH_MOVIE_DETAILS_ERROR, FETCH_SHOW_TIMES, FETCH_SHOW_TIMES_ERROR, FETCH_CURRENT_MOVIE_SHOW_DATES,
    SET_MOVIE_DETAILS_LOADER,
    SET_SHOW_TIMES_LOADER,
    CLEAR_MOVIE_DETAILS_ERROR, SET_SELECTED_MOVIE
} from "./moviesTypes";
import MovieService from "@apiService/MovieService";

const fetchNowPlayingSuccess = (data) => {
    return {
        type: FETCH_NOW_PLAYING_MOVIES,
        payload: data
    }
}

export const setselectedmovie = (data) => {
    return {
        type: SET_SELECTED_MOVIE,
        payload: data
    }
}

const fetchNowPlayingError = (err) => {
    return {
        type: FETCH_NOW_PLAYING_ERROR,
        payload: err
    }
}

const fetchMovieDetailsSuccess = (data) => {
    return {
        type: FETCH_MOVIE_DETAILS,
        payload: data
    }
}

const fetchMovieDetailsError = (error) => {
    return {
        type: FETCH_MOVIE_DETAILS_ERROR,
        payload: error
    }
}

const fetchShowTimeSuccess = (data) => {
    return {
        type: FETCH_SHOW_TIMES,
        payload: data
    }
}

const fetchShowTimeError = (error) => {
    return {
        type: FETCH_SHOW_TIMES_ERROR,
        payload: error
    }
}

const fetchCurrentMovieShowDates = (payload) => ({
    type: FETCH_CURRENT_MOVIE_SHOW_DATES,
    payload
})

const setShowTimesLoader = (payload) => ({
    type: SET_SHOW_TIMES_LOADER,
    payload
});

const setMovieDetailsLoader = (payload) => ({
    type: SET_MOVIE_DETAILS_LOADER,
    payload
})

export const clearMovieDetailsError = (payload) => ({
    type: CLEAR_MOVIE_DETAILS_ERROR
})

export const fetchNowPlayingMovies = (payload) => {
    return async function (dispatch) {
        try {
            // const response = await MovieService.GetNowShowingMovies();
            // const { data } = response;
            // if ( data.status == true && data.Records.data > 0) {
            //     dispatch(fetchNowPlayingSuccess(data.Records.data))
            // }
            if (payload) {
                // console.log("inside ")
                dispatch(fetchNowPlayingSuccess(payload));
            }
        } catch (err) {
            dispatch(fetchNowPlayingError(err.message))
        }
    }
}
export const fetchNowPlayingMoviesEvent = (payload) => {
    return async function (dispatch) {
        try {
            const response = await MovieService.GetNowPlayingMoviesEvent(payload);
            const { data } = response;
            if (data.status) {
                dispatch(fetchNowPlayingSuccess(data.data));
            }
        } catch (err) {
            dispatch(fetchNowPlayingError(err.message));
        }
    };
};
export const fetchMovieDetails = (payload) => {
    console.log("response>>>>>", payload)
    return async function (dispatch) {
        dispatch(setMovieDetailsLoader(true))
        await MovieService.GetMovieDetails(payload)
            .then((response) => {
                console.log("response>>>>>", response)
                dispatch(fetchMovieDetailsSuccess(response.data.Records))

            }).catch((err) => {
                dispatch(fetchMovieDetailsError(err.message))
            });
    }
}

export const fetchShowTimes = (payload) => {
    return async function (dispatch) {
        try {
            dispatch(setShowTimesLoader(true));
            const response = await MovieService.GetShowTimes(payload);
            const { data } = response;
            if (data.status) {
                dispatch(fetchShowTimeSuccess(data))
            }
        } catch (err) {
            dispatch(fetchShowTimeError(err.message))
        }
    }
}

export const fetchShowDates = (payload) => {
    return function (dispatch) {
        if (!payload.movie_id || !payload.cinema_id) {
            return;
        }
        MovieService.GetShowDates(payload)
            .then(response => {
                const { data } = response;
                if (data.data) {
                    dispatch(fetchCurrentMovieShowDates(data.data))
                }
            }).catch(err => {
                console.log(err.response ? err.response : err);
            });
    }
}
// export const fetchHomePageContent =
//   (nowPlayingMoviesFilters) => async (dispatch) => {

//     console.log("fetchHomePageContent >>>>>>>>>>>>>>>>")

//     try {
//       await Promise.allSettled([
//         // dispatch(fetchBanners()),
//         // dispatch(fetchQuickBookData()),
//         // dispatch(fetchComingSoonMovies({})),
//         dispatch(fetchNowPlayingMovies(nowPlayingMoviesFilters)),
//         // dispatch(fetchPromotionsData()),
//         // dispatch(fetchMovieLanguages()),
//         // dispatch(fetchMovieGenres()),
//       ]);
//     } catch (err) {
//       // console.log(err);
//     }
//   };