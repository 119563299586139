import Api from './Api';

export default {
    GetCities() {
        return Api().get(`movies/cities`);
    },
    GetLanguages() {
        return Api().get(`movies/languages`);
    },
    GetCountries() {
        return Api().get(`movies/countries`);
    },
    GetExperiences() {
        return Api().get(`generic/experiences`);
    },
    GetMovieLanguages() {
        return Api().get(`generic/movie-languages`);
    },
    GetMovieGenres() {
        return Api().get(`generic/movie-genres`);
    },
    GetCountrySpecificData() {
        return Api().get(`generic/country-specific-data?country_id=1`);
    },
    GetCountrySpecificDataJizan() {
        return Api().get(`generic/country-specific-data-jizan`);
    }
}