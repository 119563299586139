import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container, Row, Col, Table, Alert, Modal, Spinner, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import GoBack from '@components/partials/GoBack';
import { withNamespaces } from 'react-i18next';
import MovieHeader from '@components/partials/MovieHeader';
import { globalConfigContext } from '@context/GlobalConfigContext';
import TimeoutContainer from '@components/partials/TimeoutContainer';
import { setFnbReservationDone } from '@store/foodAndBeverages/foodAndBeveragesActions';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import arabicLayout from 'simple-keyboard-layouts/build/layouts/arabic';
import englishLayout from 'simple-keyboard-layouts/build/layouts/english';
import clock from '@assets/imgs/svgs/clock.svg';
import {
	reserveFnbItems,
	clearFoodAndBeveragesState,
	setUserDetails,
} from '@store/booking/bookingActions';
import { getDiscount } from '@store/booking/bookingSelector';
import useCountries from '../customHooks/useCountries';
import { modifiersForSelectedFnbItems } from '@store/foodAndBeverages/foodAndBeveragesSelectors';
import {
	titleCase,
	isImageURLNull,
	formatYuppErrors,
	translateSessionAttribute,
} from '@helper/formatting';
import moment from 'moment';
import { Redirect, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { SOCKET_URL } from '@config';
import screen from '../assets/imgs/svgs/screen.svg';
import snack from '@assets/imgs/svgs/snacks_default.svg';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import ip1 from '../assets/imgs/ip1.jpg';
import order from '../assets/imgs/order.jpg';
const els3 = [1, 2, 3];
const els10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function FnbConfirm(props) {
	const [inputs, setInputs] = useState({});
	const [layoutName, setLayoutName] = useState('default');
	// const [layout, setLayout] = useState(frenchLayout);
	const [inputName, setInputName] = useState('default');
	const keyboard = useRef();
	const { arabic, currency } = useContext(globalConfigContext);

	const { cinema_id, session_id } = props.match.params;
	const t = props.t;
	const dispatch = useDispatch();
	const history = useHistory();

	const reservation_id = useSelector((state) => state.booking.reservation_id);
	const selectedTicketPrice = useSelector((state) => state.booking.selectedTicketPrice);
	const fnbCategories = useSelector((state) => state.foodAndBeverages.fnb_categories);
	const fnbItems = useSelector((state) => state.foodAndBeverages.fnb_items);
	const selectedFnbItems = useSelector((state) => state.foodAndBeverages.selected_fnb_items);
	const fnbPrice = useSelector((state) => state.foodAndBeverages.fnb_price);
	const selectedFnbItemId = useSelector((state) => state.foodAndBeverages.selected_fnb_item_id);
	const modifiersGroup = useSelector((state) => state.foodAndBeverages.fnb_modifiers_group);
	const modifiers = useSelector((state) => state.foodAndBeverages.fnb_modifiers);
	const showModifiersPopup = useSelector((state) => state.foodAndBeverages.show_modifiers_popup);
	const modifierPrice = useSelector((state) => state.foodAndBeverages.modifier_price);
	const currentFnbItem = useSelector((state) => state.foodAndBeverages.current_fnb_item);
	const seats = useSelector((state) => state.booking.seats_selected);
	const fnbReservationDone = useSelector((state) => state.foodAndBeverages.fnbReservationDone);
	const minutes = useSelector((state) => state.booking.timer.minutes);
	const seconds = useSelector((state) => state.booking.timer.seconds);
	const recipeItems = useSelector((state) => state.foodAndBeverages.recipe_items);
	const showRecipeItemsPopup = useSelector(
		(state) => state.foodAndBeverages.show_recipe_items_popup
	);
	const discount = useSelector(getDiscount);
	const modifiersForConcessions = useSelector((state) => modifiersForSelectedFnbItems(state));
	const timer = useSelector((state) => state.booking.timer);
	const seatsSelected = useSelector((state) => state.booking.seats_selected);
	const showDetails = useSelector((state) =>
		state.booking.show_details && state.booking.show_details.length > 0
			? state.booking.show_details[0]
			: null
	);
	const bookingFee = useSelector((state) => state.booking.bookingFee);

	// console.log(bookingFee, "kdsjafdfkadhfadhfjkds")
	const bookingFeeTax = useSelector((state) => state.booking.bookingFeeTax);

	const voucherCodeData = useSelector((state) => state.booking.voucherCodeData);
	const voucherCodeError = useSelector((state) => state.booking.voucherCodeError);

	// state
	const [showProceedSpinner, setShowProceedSpinner] = useState(false);
	const [reserveFnbItemsError, setReserveFnbItemsError] = useState(null);
	const [email, setEmail] = useState('kiosk@binarynumbers.io');
	const [mobile, setMobile] = useState('889869216');
	const [errors, setErrors] = useState(null);
	const [countryCode, setCountryCode] = useState('966');
	const countries = useCountries(setCountryCode);

	const schema = yup.object().shape({
		email: yup.string().email().required(),
		mobile: yup
			.string()
			.matches(/^\d{8,9}$/)
			.required(),
		countryCode: yup.string().required(),
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	// useEffect(() => {
	// 	if (arabic) {
	// 		setLayout(ArabicLayout);
	// 		setLayoutName('arabic');
	// 	} else {
	// 		setLayout(englishLayout);
	// 		setLayoutName('default');
	// 	}
	// }, []);
	// const onProceed = () => {
	// 	props.history.push('/process-payment');
	// };

	// on proceed, we save the email and mobile in the store
	// then after payment is successful, we send it to backend along with payment info

	const onProceed = () => {
		console.log("brfore validate >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
		console.log(email, "email >>>>>>>>>>>>")
		console.log(mobile, "mobile >>>>>>>>>>>>")
		console.log(countryCode, "countryCode >>>>>>>>>>>>")
		schema
			.validate(
				{
					email,
					mobile,
					countryCode,
				},
				{ abortEarly: false }
			)
			.then((valid) => {
				console.log("indside the validate >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
				dispatch(setUserDetails(email, `${countryCode}${mobile}`));
				props.history.push('/process-payment');
			})
			.catch((errs) => {
				console.log("error in validation >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", errs)
				let errors = formatYuppErrors(errs);
				setErrors(errors);
			});
	};

	if (!reservation_id || !showDetails) {
		return <Redirect to={`/seat-layout/${cinema_id}/${session_id}`} />;
	}
	const onChangeAll = (inputs) => {
		/**
		 * Here we spread the inputs into a new object
		 * If we modify the same object, react will not trigger a re-render
		 */
		setInputs({ ...inputs });
		setEmail(inputs.email);
		setMobile(inputs.mobile);
		console.log('Inputs changed', inputs);
	};

	const handleShift = () => {
		// 	const newLayoutName = layoutName === 'default' ? 'shift' : 'default';
		// 	setLayoutName(newLayoutName);
	};

	const onKeyPress = (button) => {
		console.log('Button pressed', button);

		/**
		 * If you want to handle the shift and caps lock buttons
		 */
		if (button === '{shift}' || button === '{lock}') handleShift();
	};

	const onChangeInput = (event) => {
		const inputVal = event.target.value;

		setInputs({
			...inputs,
			[inputName]: inputVal,
		});

		keyboard.current.setInput(inputVal);
	};

	const getInputValue = (inputName) => {
		return inputs[inputName] || '';
	};
	return (
		<>
			<MovieHeader />
			<TimeoutContainer t={t} session_id={session_id} cinema_id={cinema_id} />
			<Container fluid className="fnb-confirm">
				{/* <GoBack to="prev" /> */}
				<Row className="align-items-center py-3 mb-40">
					<Col>
						<GoBack to="prev" />
					</Col>
					<Col className="d-flex justify-content-end">
						<span className="session-timer">
							<span>
								{timer.minutes}:{timer.seconds < 10 ? '0' + timer.seconds : timer.seconds}
							</span>
							<img src={clock} className={''} />
						</span>
					</Col>
				</Row>
				<Row>
					<Col md={8} className="kumbh">
						<div className="fnb-ticket">
							<h3 className="heading">{t('fnbconfirm.TICKET')}</h3>
							<div className="d-flex pt-4 pb-4">
								<img
									src={
										!isImageURLNull(showDetails.movie_image_url_1)
											? showDetails.movie_image_url_1
											: movieDef
									}
									className="mtp"
								/>
								<div className="pl-4">
									<h3 className="fw-bold lh-1">
										{arabic ? showDetails.movie_title_ar : showDetails.movie_title} (
										{showDetails.SessionDimension ? showDetails.SessionDimension : ''})
										{showDetails.movie_lang
											? '(' + showDetails.movie_lang.substring(0, 2).toUpperCase() + ')'
											: ''}
									</h3>
									<div className="flex-between">
										<div>
											<p>{t('fnbconfirm.Date')}</p>
											<h4>{moment.utc(showDetails.Showtime).format('Do MMMM, YYYY')}</h4>
										</div>
										<div>
											<p>{t('fnbconfirm.Seats')}</p>
											<h4>
												{seats.length > 0
													? seats.toString()
													: t('seatlayout_page.seats-not-selected')}
											</h4>
										</div>
										<div>
											<p>{t('fnbconfirm.Show Details')}</p>
											<h4>
												{showDetails.SessionAttribute
													? translateSessionAttribute(arabic, showDetails.SessionAttribute)
													: translateSessionAttribute(arabic, 'premium')}
												,{moment.utc(showDetails.Showtime).format('HH:mmA')}
											</h4>
										</div>
										{showDetails && showDetails.cinema_has_voucher === 'Y' && (
											<div>
												<p>{t('fnbconfirm.Voucher Code')}</p>
												<h4>
													{voucherCodeData
														? voucherCodeData.voucher_code
														: t('fnbconfirm.No Voucher Added')}
												</h4>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="fnb-order py-3">
							{/* <h3 className="heading">{t('fnbconfirm.F&B ORDER')}</h3> */}
							<div className="flex-between">
								{selectedFnbItems && selectedFnbItems.length > 0
									? selectedFnbItems.map((item, i) => (
											<div className="p-3 d-flex flex-column">
												<img
													src={
														!isImageURLNull(item.concession_image_url_1)
															? item.concession_image_url_1
															: snack
													}
													className="fci"
												/>
												<span className="frs-14 fw-reg py-1">{titleCase(item.Description)}</span>
												{/* <span className="blurred-white frs-14 fw-reg">240KCal</span> */}
											</div>
									  ))
									: null}
							</div>
						</div>
					</Col>
					<Col md={4}>
						<div className="fnb-total">
							<div className="item bb-1">
								<h5 className="blurred-white">{t('fnbconfirm.Tickets')}</h5>
								<div className="d-flex justify-content-between w-100">
									<h5 className="pr-2">
										{seatsSelected.join(',')}{' '}
										{showDetails.SessionAttribute
											? translateSessionAttribute(arabic, showDetails.SessionAttribute)
											: translateSessionAttribute(arabic, 'premium')}
									</h5>
									<h4 className="pl-2">
										{(
											(selectedTicketPrice.priceInCents - selectedTicketPrice.taxInCents) /
											currency.divided_by
										).toFixed(2)}{' '}
										{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
									</h4>
								</div>
							</div>
							<div className="item bb-1">
								<h5 className="blurred-white">{t('fnbconfirm.Add-ons')}</h5>
								{selectedFnbItems && selectedFnbItems.length > 0
									? selectedFnbItems.map((item, index) => (
											<div className="w-100 d-flex justify-content-between">
												<h5 className="pr-2">{item.Description}</h5>
												<h4 className="pl-2">
													{(
														(item.priceInCents -
															item.taxInCents +
															(item.modifiersPriceInCents - item.modifiersTaxesInCents)) /
														currency.divided_by
													).toFixed(2)}{' '}
													{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
												</h4>
											</div>
									  ))
									: null}
							</div>
							<div className="item bb-1">
								<div className="d-flex justify-content-between">
									<h5 className="pr-2">{t('fnbconfirm.Taxes')}</h5>
									<h4 className="pl-2">
										{(
											(fnbPrice.taxesInCents +
												modifierPrice.taxesInCents +
												selectedTicketPrice.taxInCents +
												bookingFeeTax) /
											currency.divided_by
										).toFixed(2)}{' '}
										{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
									</h4>
								</div>
							</div>
							<div className="item bb-1">
								<div className="d-flex justify-content-between">
									<h5 className="pr-2">{t('fnbconfirm.Booking Fee')}</h5>
									<h4 className="pl-2">
										{((bookingFee - bookingFeeTax) / currency.divided_by).toFixed(2)}{' '}
										{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
									</h4>
								</div>
							</div>
							{discount > 0 && (
								<div className="item bb-1">
									<div className="d-flex justify-content-between">
										<h5 className="pr-2">{t('checkout.discount-applied')}</h5>
										<h4 className="pl-2">
											{discount / currency.divided_by}{' '}
											{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
										</h4>
									</div>
								</div>
							)}
							<div className="item">
								<div className="d-flex justify-content-between">
									<h5 className="pr-2">{t('fnbconfirm.Total')}</h5>
									<h4 className="pl-2">
										{(
											(fnbPrice.priceInCents +
												modifierPrice.priceInCents +
												selectedTicketPrice.priceInCents +
												bookingFee) /
											currency.divided_by
										).toFixed(2)}{' '}
										{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
									</h4>
								</div>
							</div>
						</div>
					</Col>
				</Row>

				{/* <Row className="align-items-center bgb mt-5 sticky-movie-bar">
				<Col md={12} className="seat-footer">
					<Row>
						<Col md={8}>
							<div className="d-flex br-1 py-4 px-3">
								<div>
									<h6 className="f-grey">Total Payable</h6>
									<h4 className="fw-bold">
										{(fnbPrice.priceInCents +
											modifierPrice.priceInCents +
											selectedTicketPrice.priceInCents) /
											100}{' '}
										SR
									</h4>
								</div>
							</div>
						</Col>
						<Col md={3} className="flex-center">
							<button className="blue-btn btn-lg btn-block flex-center" onClick={onProceed}>
								{showProceedSpinner ? (
									<Spinner animation="border" role="status" className="spinner">
										<span className="sr-only">Loading...</span>
									</Spinner>
								) : (
									t('common.PROCEED')
								)}
							</button>
						</Col>
					</Row>
				</Col>
			</Row> */}

				{/* <Row className="sticky-bar-margin">
					<Col className="bt-1 pt-4 mt-5">
						<div className="w-100 d-flex flex-column align-items-center">
							<p className="frs-36 fw-bold pb-4">{t('fnb_page.Enter your contact details')}</p>
							<input
								className="kiosk-input"
								placeholder={t('collect_login.placeholders.email')}
								value={getInputValue('email')}
								onFocus={() => setInputName('email')}
								type="email"
							/>
							{errors && errors.email && (
								<span className="form-error-text d-block">
									{t('collect_login.errors.Enter a valid email address')}
								</span>
							)}
							<div className="d-flex align-items-end">
								<Dropdown className="code mr-4">
									<Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
										{countryCode ? countryCode : 'Loading'}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdownItems">
										{countries && countries.length > 0
											? countries.map((country, index) => (
													<Dropdown.Item
														onClick={() => {
															setCountryCode(`+${country.country_code}`);
														}}
														key={index}
														className=""
													>
														{' '}
														{country.country_code} ({country.country_short_code})
													</Dropdown.Item>
											  ))
											: null}
									</Dropdown.Menu>
								</Dropdown>
								<input
									className="kiosk-input"
									value={getInputValue('mobile')}
									onFocus={() => setInputName('mobile')}
									placeholder={t('collect_login.placeholders.mobile')}
									type="number"
								/>
							</div>
							{errors && errors.mobile && (
								<span className="form-error-text d-block">
									{t('collect_login.errors.Enter a valid 9 digit mobile number')}
								</span>
							)}
						</div>
						<Keyboard
							className="mt-2"
							keyboardRef={(r) => (keyboard.current = r)}
							inputName={inputName}
							layout={arabic ? englishLayout : englishLayout}
							layoutName={layoutName}
							onChangeAll={onChangeAll}
							onKeyPress={onKeyPress}
						/>
					</Col>
				</Row> */}

				<Row className="align-items-center bgb mt-5 sticky-movie-bar">
					<Col xs={12} className="seat-footer">
						<Row className="py-4">
							<Col md={8}>
								<div className={`d-flex px-3 ${arabic ? 'bl-1' : 'br-1'}`}>
									<div>
										<h4 className="f-grey">{t('fnbconfirm.Total Payable')}</h4>
										<h1 className="fw-bold">
											{(
												(fnbPrice.priceInCents +
													modifierPrice.priceInCents +
													selectedTicketPrice.priceInCents +
													bookingFee) /
												currency.divided_by
											).toFixed(2)}{' '}
											{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
										</h1>
										{/* <h5>Taxes: {selectedTicketPrice.taxInCents / 100} SR</h5> */}
									</div>
									{/* <div className={`flex-center ${arabic ? "pr-5" : "pl-5"}`}>
											<h6 className="custom-label">Total Bill</h6>
										</div> */}
								</div>
							</Col>
							<Col md={4} className="d-flex justify-content-center align-items-center">
								<span className="blue-btn btn-lg px-5" onClick={()=>onProceed()}>
									{t('fnbconfirm.CONFIRM & PAY')}
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default withNamespaces()(FnbConfirm);
