import React, { useState, useEffect, useContext } from 'react';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { getArabicNumber } from '@helper/formatting';
import moment from 'moment';

const Receipt = ({ data }) => {
	const { arabic, currency } = useContext(globalConfigContext);

	const bookingFeeTax =
		data && data.booking_fee && data.tax_percent
			? data.booking_fee - data.booking_fee / (1 + data.tax_percent / 100)
			: 0;

	return (
		<center>
			<div
				style={{
					height: '100vh',
					width: '100%',
					overflowY: 'auto',
					backgroundColor: 'white',
					color: 'black',
				}}
			>
				<table style={{ fontFamily: 'arial', width: '380px' }} align="center">
					<tbody>
						<tr>
							<td colspan="4" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'ltr', padding: '0 15px', paddingTop: '15px' }}>
											Cinépolis Cinemas
										</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="4" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'rtl', padding: '0 15px' }}>سينيبوليس</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="4" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'ltr', padding: '0 15px' }}>Simplified Tax Invoice</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								<b>Qty</b>
							</td>
							<td colspan="1">
								<b>Item</b>
							</td>
							<td colspan="1">
								<b>Unit</b>
							</td>
							<td colspan="1" style={{ paddingRight: '15px' }}>
								<b>Price</b>
							</td>
						</tr>
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								{data.number_of_seats}
							</td>
							<td colspan="1">{data.SessionAttribute ? data.SessionAttribute : 'Premium'}</td>
							<td colspan="1">
								{(data.transaction_net_price / data.number_of_seats / data.divided_by).toFixed(2)}
							</td>
							<td colspan="1" style={{ paddingRight: '15px' }}>
								{(data.transaction_net_price / data.divided_by).toFixed(2)}
							</td>
						</tr>
						<br />
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								Subtotal
							</td>
							<td colspan="1">
								{data.currency_short_code}{' '}
								{(
									(data.ticket_price_in_cents -
										data.ticket_taxes_in_cents +
										data.fnb_price_in_cents -
										data.fnb_taxes_in_cents +
										data.booking_fee -
										bookingFeeTax) /
									data.divided_by
								).toFixed(2)}
							</td>
							<td colspan="1">السعر</td>
							<td colspan="1" style={{ paddingRight: '15px' }}>
								{data.currency_short_code_ar}{' '}
								{getArabicNumber(
									(
										(data.ticket_price_in_cents -
											data.ticket_taxes_in_cents +
											data.fnb_price_in_cents -
											data.fnb_taxes_in_cents +
											data.booking_fee -
											bookingFeeTax) /
										data.divided_by
									).toFixed(2)
								)}
							</td>
						</tr>
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								VAT {data.tax_percent}%
							</td>
							<td colspan="1">
								{data.currency_short_code}{' '}
								{(
									(data.fnb_taxes_in_cents + data.ticket_taxes_in_cents + bookingFeeTax) /
									data.divided_by
								).toFixed(2)}
							</td>
							<td colspan="1">
								{data.currency_short_code_ar}{' '}
								{getArabicNumber(
									(
										(data.fnb_taxes_in_cents + data.ticket_taxes_in_cents + bookingFeeTax) /
										data.divided_by
									).toFixed(2)
								)}
							</td>
							<td colspan="1" style={{ paddingRight: '15px' }}>
								ضريبة
							</td>
						</tr>
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								Total
							</td>
							<td colspan="1">
								{data.currency_short_code}{' '}
								{(data.transaction_net_price / data.divided_by).toFixed(2)}
							</td>
							<td colspan="1">
								{data.currency_short_code_ar}{' '}
								{getArabicNumber((data.transaction_net_price / data.divided_by).toFixed(2))}
							</td>
							<td colspan="1" style={{ paddingRight: '15px' }}>
								مجموع
							</td>
						</tr>
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								CASH
							</td>
							<td colspan="1">
								{data.currency_short_code}{' '}
								{(data.transaction_net_price / data.divided_by).toFixed(2)}
							</td>
							<td colspan="1">
								{data.currency_short_code_ar}{' '}
								{getArabicNumber((data.transaction_net_price / data.divided_by).toFixed(2))}
							</td>
							<td colspan="1" style={{ paddingRight: '15px' }}>
								CASH
							</td>
						</tr>
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								Change
							</td>
							<td colspan="1">{data.currency_short_code} 0.00</td>
							<td colspan="1">
								{data.currency_short_code_ar} {getArabicNumber('0.00')}
							</td>
							<td colspan="1" style={{ paddingRight: '15px' }}>
								التغيير
							</td>
						</tr>
						{/* <tr style={{ textAlign: 'left' }}>
							<td colspan="4" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									T/N: 80
								</font>
							</td>
						</tr> */}
						{/* <tr style={{ textAlign: 'left' }}>
							<td colspan="4" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									Opr: 9999 Vista Support
								</font>
							</td>
						</tr> */}
						<tr style={{ textAlign: 'left' }}>
							<td colspan="4" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									Sold:{' '}
									{moment.utc(data.transaction_date_time).locale('en').format('hh:mm a DD/MM/YYYY')}
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'left' }}>
							<td colspan="4" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									VAT No.: 310423211100003
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="4" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									Luxury Entertainment LLC
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="4" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									{data.cinema_address}
								</font>
							</td>
						</tr>
						{/* <tr style={{ textAlign: 'center' }}>
							<td colspan="4" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									Jeddah, KSA
								</font>
							</td>
						</tr> */}
						<tr style={{ textAlign: 'center' }}>
							<td colspan="4" valign="top" height="25px">
								<b>
									<font size="2" style={{ padding: '0 15px 15px' }}>
										www.cinepolisgulf.com
									</font>
								</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</center>
	);
};

export default Receipt;
