import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMovieDetails, fetchShowTimes, fetchMovieDetailsSuccessnew } from '@store/movies/moviesActions';
import { timeConvert, isImageURLNull, translateSessionAttribute } from '@helper/formatting.js';
import { useHistory } from 'react-router-dom';
import { locale } from 'moment';
// const moment = require('moment-timezone');
import moment from 'moment-timezone';
import MovieHeader from '@components/partials/MovieHeader';
import { globalConfigContext } from '@context/GlobalConfigContext';
import CSpinner from '@components/partials/Spinner';
import { withNamespaces } from 'react-i18next';
import GoBack from '@components/partials/GoBack';
import i18n from '../plugins/i18n';
import { Row, Container, Col } from 'react-bootstrap';
import MovieService from '../ApiServices/MovieService';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import Slider from "react-slick";
// import Showtime from "../components/showtime"

// import React from "react";
// import ReactDOM from "react-dom";



function MovieDetailsContainer(props) {
	const { arabic, setArabic } = useContext(globalConfigContext);
	const { movie_id } = props.match.params;
	console.log('movie_id :>> ', movie_id);

	moment.locale('en');
	return (
		<div className=''>
			<MovieHeader />
			<Container fluid>
				<div>
					<div className="main_container">
						<MovieDetails movie_id={movie_id} t={props.t} />
						<GoBack t={props.t} to="prev" />
					</div>
				</div>
			</Container>
			{/* <GoBack className="gbbottom" t={props.t} to="prev" /> */}
		</div>
	);
}

const MovieDetails = ({ movie_id, t }) => {
	const { arabic, currency } = useContext(globalConfigContext);

	// Set the default timezone to UTC
	// moment.tz.setDefault('UTC');

	// Get the current time in Oman
	// const omanTime = moment.tz('Asia/Muscat').format('YYYY-MM-DD HH:mm:ss');

	// console.log(omanTime, " omanTime  >>>>>>>>>>>>>>>>>");

	const [currentShowDate, setCurrentDate] = useState(
		moment.tz('Asia/Muscat').format('YYYY-MM-DD HH:mm')
	);
	const [modifiedShowTimes, setModifiedShowTimes] = useState([]);
	const [showTimes, setShowTimes] = useState([]);
	const [showSpinner, setShowSpinner] = useState(false);
	const [sel, setsel] = useState(null);

	console.log(sel, "sel >>>>>>>>>>>>>>>>")

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchMovieDetails(movie_id));
	}, [movie_id]);

	// useEffect(() => {
	// 	console.log(modifiedShowTimes,"jnjnjnjnjnj")
	// }, [modifiedShowTimes]);

	useEffect(() => {
		setShowSpinner(true);
		dispatch(fetchShowTimes(movie_id)).finally(() => setShowSpinner(false));

		MovieService.GetShowTimes(movie_id).then((res) => {
			if (res?.data?.Records?.data.length > 0)
				setShowTimes(res?.data?.Records?.data);
			// console.log(res?.data?.Records?.data, "this si show tumesjjanaknb")
			setsel(res?.data?.Records?.data[0]?.day);
		})
		// const fetchShowTimes = async (payload) => {
		// 	 console.log(payload,"payload>>>>>>")
		// 	// return async function (dispatch) {
		// 	  try {
		// 		// dispatch(setShowTimesLoader(true));
		// 		const response = await MovieService.GetShowTimes(payload);
		// 		const { data } = response;
		// 		 console.log("data>>>>>",data)
		// 		if (data.status) {
		// 		//   dispatch(fetchShowTimeSuccess(data.data));
		// 		}
		// 		// return data.data;
		// 	  } catch (err) {
		// 		// dispatch(fetchShowTimeError(err.message));
		// 	  }

		// 	// };
		//   };
		//   fetchShowTimes(movie_id)
	}, [currentShowDate, movie_id]);

	const movieDetails = useSelector((state) => state.movies.movie_details);
	const ShowTimes = useSelector((state) => state.movies.show_times);
	console.log(ShowTimes, 'movieDetails>>>');
	let todayShowTime = [];
	useEffect(() => {
		const name = "Flash";

		console.log(currentShowDate, "currentShowDate >>>>>>>>>>>>>>>>>>>")

		todayShowTime = ShowTimes?.Records?.data?.filter((time) => {


			console.log(time, "time >>>>>>>>>>>>>>>")

			const movietime = `${time.Year}-${time.Month}-${time.day} ${time.ss_start_show_time}`;

			console.log(movietime, "movietime >>>>>>>>>>>>>>>>>>>>")


			let tempTimme

			if (sel) {
				tempTimme = moment.tz('Asia/Muscat').format(`YYYY-MM-${sel}`)
			} else {
				tempTimme = moment.tz('Asia/Muscat').format('YYYY-MM-DD')
			}

			if (
				(tempTimme == `${time.Year}-${time.Month}-${time.day}`)
				// &&
				// (moment(movietime).isAfter(currentShowDate) == true)
			) {
				return true
			} else {
				return false
			}

			// console.log(movietzime, "movietime >>>>>>>>>>>>")
			// return movietime === "2024-02-28";
			// console.log()
			// return movietime === currentShowDate;
		});
		// console.log(todayShowTime, 'ABVC>>>');
		if (todayShowTime?.length > 0) {

			// dispatch(fetchShowTimesNew(todayShowTime));
			console.log('hello me ');
			setModifiedShowTimes(todayShowTime);
			// localStorage.setItem("Showtime", todayShowTime.Showtime);

		}
	}, [ShowTimes, sel]);
	console.log(todayShowTime, 'setModifiedShowTimes');
	console.log(modifiedShowTimes, 'setdfhidshModifiedShowTimes');

	const chooseDateSlider = {
		arrows: false,
		infinite: false,
		speed: 800,
		slidesToShow: 7,
		slidesToScroll: 1,
	};

	useEffect(() => {
		console.log(showTimes, "this is show times")
	}, [showTimes]);
	return (
		<div className="movieDetails_mainContainer">
			<CSpinner active={showSpinner} />



			<Row className="w-100">
				<Col className="px-0">
					<div className="movieDetails_container pt-3">
						<Col xs={4} className={`${arabic ? 'pr-0' : 'pl-0'}`}>
							<div className="thumbnail">
								<img
									className="banner"
									src={
										!isImageURLNull(movieDetails[0]?.altMovieContent[0]?.artwork)
											? movieDetails[0]?.altMovieContent[0]?.artwork
											: movieDef
									}
									alt={movieDetails[0]?.altMovieContent[0]?.mc_title}
								/>
							</div>
						</Col>
						<Col xs={8} className={`${arabic ? 'pl-0' : 'pr-0'}`}>
							<div className="details_container">
								<div className="details_header">
									<div className="header_left">
										<h1>
											{arabic
												? movieDetails.movie_title_ar
												: movieDetails[0]?.altMovieContent[0]?.mc_title}
										</h1>
										<p className="frs-20">{movieDetails[0]?.altMovieContent[0]?.lang_name}</p>
									</div>
									{/* <div className="header_right">
							<div>
								<p>{movieDetails.Rating}</p>
								<p className="time">{timeConvert(movieDetails.run_time)}</p>
							</div>
						</div> */}
									<div className="group px-2">
										<span className={`${arabic ? 'border-left pl-2' : 'border-right pr-2'}`}>
											{movieDetails[0]?.rating}
										</span>
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{timeConvert(movieDetails[0]?.mrrdr_runtime)}
										</span>
									</div>
								</div>

								<div className="details_body mt-auto">
									<p className="title">{t('movie_details.Synopsis')}</p>
									<p className="content text-limit">
										{arabic
											? movieDetails.movie_description_sr
											: movieDetails[0]?.altMovieContent[0]?.mc_plot_short}
									</p>
									<p className="title mt-auto">{t('movie_details.Cast')}</p>
									<p className="content">
										{arabic ? movieDetails.movie_cast_ar : movieDetails[0]?.movie_cast_crew}
									</p>
									{/* <p className="title">Directed By</p>
									<p className="content">Michael Bay</p> */}
								</div>
							</div>
						</Col>
					</div>
				</Col>
			</Row>

			<Row className="w-100">
				<Col className="movieFeatures_container">
					<div className="features_header">
						<div className="featureHeader_left">
							{/* <p>{t('movie_details.Date')}</p> */}
							{/* <h2>{moment.utc(currentShowDate).format('Do MMMM, YYYY')}</h2> */}
						</div>
						{/* <div className="featureHeader_right">
							<p>{t('movie_details.Choose Language')}</p>
							<div className="featureHeader_right_buttons">
								<button className="language_button active">{t('movie_details.ENGLISH')}</button>
								<button className="language_button">{t('movie_details.ARABIC')}</button>
							</div>
						</div> */}
					</div>

					<div className="features_body">
						<div className="container-fluid">
							<div className="row py-2">
								<div className="col-md-3 d-flex align-items-center"></div>
								<div className="col-md-6">
									<div className="">

									</div>
								</div>
								<div className="col-md-3"></div>
							</div>
						</div>


						{/* <p className="pb-2">{t('movie_details.Choose Show Time')+"dbjdbsj"}</p> */}

						<div className="col-lg-8 col-md-7  mx-auto">
							<div>



								{/* <div>HIIIIIIIIIIIIIIIII harshal</div> */}
								{/* {JSON.stringify(showDates)} */}
								<Slider
									{...chooseDateSlider}
									className="arrows-3 slick-track-no-margin movie-slider-arrows px-lg-5 px-md-4 py-2"
								>
									{showTimes
										.filter(
											(item, index, self) =>
												index === self.findIndex((b) => b.day === item.day)
										)
										.filter(cinema => {
											// const startTime = new Date(cinema.ss_start_date_time);
											// return startTime.getHours() >= 6 || startTime.getHours() < 0;
											const hour = moment(cinema.ss_start_date_time).tz('Asia/Muscat').hour();
											return hour >= 6;
										})
										.map((date, index) => {
											// console.log(showTimes, "this is show times")
											return (
												<div
													onClick={() => {
														setsel(date?.day);
													}}
													className=" d-flex justify-content-center"
													key={index}
												>
													{/* <div onClick={() => handleSelectDate(date)} className={`${date._i == currentShowDate ? "show-date-active" : ""} choose-date-card`}> */}
													<div
														className={`${date.day == sel
															? "show-date-active selected-date-card"
															: ""
															} choose-date-card w-100 `}
													>
														<div className="day text-center w-fit">
															{date.dayName.substring(0, 3)}
														</div>
														<div className="date text-center w-fit">
															{date?.day}
														</div>
														<div className="month text-center w-fit">
															{moment(date?.Month, "MM").format("MMM")}
														</div>
													</div>
												</div>
											);
										})}
								</Slider>
							</div>
						</div>

						<div className='d-flex flex-wrap'>
							{
								Array.isArray(modifiedShowTimes) && modifiedShowTimes.length > 0
									? modifiedShowTimes
										.sort((a, b) => new Date(a.ss_start_date_time) - new Date(b.ss_start_date_time))
										.filter(cinema => {
											// const startTime = new Date(cinema.ss_start_date_time);
											// return startTime.getHours() >= 6 || startTime.getHours() < 0;
											// console.log(moment(cinema.ss_start_date_time).tz('Asia/Muscat').format('YYYY-MM-DD HH:mm'), "hthihtihtih")
											const hour = moment(cinema.ss_start_date_time).tz('Asia/Muscat').hour();
											return hour >= 6;
										})
										.map((cinema, cinemaIndex) => {
											// console.log(new Date(cinema.ss_start_date_time), 'modifiejijdnajnsjn>>>>');
											// console.log(showTimes, "modified show times");
											// console.log(sel, "modified show times");
											return (
												<div >
													<ShowTimings
														key={cinema.cinema_id} // You might need to provide a unique key
														cinemaId={cinema?.cinema_id}
														timings={cinema?.ss_start_show_time}
														ss_id={cinema?.ss_id}
														md_id={cinema?.movie_details_id}
														type_seat_show={cinema?.showType}
														screen_id={cinema?.screen_id}
														movie_id={cinema?.movie_id}
														ss_actual_start_date={cinema?.ss_actual_start_date}
													/>
												</div>
											);
										})

									: "No Shows Avilable"
							}
						</div>
						<div className='d-flex flex-wrap'>
							{
								(
									showTimes.
										filter(item => item.day == parseInt(sel) + 1)
										.filter(cinema => {
											// const startTime = new Date(cinema.ss_start_date_time);
											// console.log(startTime.getHours(), "thi sijsijsij")
											// console.log(showTimes, "thi sijsijsij")
											// return startTime.getHours() <= 6;
											const hour = moment(cinema.ss_start_date_time).tz('Asia/Muscat').hour();
											return hour <= 6;
										})
										.map((cinema, index) =>
											<>
												{
													index == 0
														? <p className='d-block w-100 mt-2'>After Midnight Shows</p>
														: <></>
												}
												{
													<div>
														<ShowTimings
															key={cinema.cinema_id} // You might need to provide a unique key
															cinemaId={cinema?.cinema_id}
															timings={cinema?.ss_start_show_time}
															ss_id={cinema?.ss_id}
															md_id={cinema?.movie_details_id}
															type_seat_show={cinema?.showType}
															screen_id={cinema?.screen_id}
															movie_id={cinema?.movie_id}
															ss_actual_start_date={cinema?.ss_actual_start_date}
														/>
													</div>

												}
											</>
										)

								)
							}
						</div>


					</div>
				</Col>
			</Row>
		</div >
	);
};
// const chooseDateSlider = {
//     arrows: true,
//     infinite: false,
//     speed: 800,
//     slidesToShow: 8,
//     slidesToScroll: 1,
//   };
//   let days = [];

// const DateCardSliderUI = () => {
// 	const getPreviousDate = dateString => new Date(new Date(dateString).setDate(new Date(dateString).getDate() - 1)).toISOString().slice(0, 10);

// 	const [currentShowDate, setCurrentDate] = useState(
// 		moment.utc().locale("en").format("YYYY-MM-DD")
// 	  );
// 	  const uniqueDays = {};
// 	  const previousDate  = getPreviousDate(showDates && showDates.length > 0 && showDates[0].ShowTime);
// 	  if(showDates?.length > 0){
// 		uniqueDays[previousDate] = 1
// 	  }
// 	  if (showDates) {
// 		showDates.forEach((showDate) => {
// 		  let date = moment
// 			.utc(showDate.ShowTime)
// 			// .add(-1, 'day')
// 			.locale("en")
// 			.format("YYYY-MM-DD");
// 		  uniqueDays[date] = 1;
// 		});
// 		 console.log(uniqueDays, "uniqueDays >>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>> >>>")


// 		 days = Object.keys(uniqueDays).map((date) => moment.utc(date));

// 		 console.log(days, "uniqueDays >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>")
// 	  }
//     // console.log(days, "days >>>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>> >>>>>>")

//     return(
//       <>
// 	  <h1>hello11 nidhi</h1>
//       {days && days.length > 0 ? (
//         <div className="">
// 			<h1>heloo nidhi</h1>
//           <Slider
//             {...chooseDateSlider}
//             className="arrows-3 slick-track-no-margin movie-slider-arrows"
//           >
//             {days
//               // .slice(movieDetails?.Title.includes(" vs ") ? 0 : 1)
//               .filter((item)=>{
//               if(
//                 moment.utc(item._i).isBefore(moment
//                   .utc()
//                   .format('YYYY-MM-DD')) == true
//               ){
//                 return false
//               }else{
//                 return true
//               }
//             })
//               .map((day, index) => (
//                   <div className="" key={index}>
//                       <div
//                           className={`choose-date-card ${
//                               moment.utc(day).locale("en").format("YYYY-MM-DD") ===
//                               currentShowDate
//                                   ? "selected-date-card"
//                                   : ""
//                           }`}
//                           onClick={() =>
//                               setCurrentDate(day.locale("en").format("YYYY-MM-DD"))
//                           }
//                       >
//                           <div className="day">{day.format("ddd")}</div>
//                           <div className="date">{day.format("D")}</div>
//                           <div className="month">{day.format("MMM")}</div>
//                       </div>
//                   </div>
//               ))}
//           </Slider>
//         </div>
//       ) : (
//         <p className="kumbh fw-reg frxl-20 frl-18 frm-16 frm-14 ls-3 mb-3">
//           {/* {t("movie_page.no-shows-available")} */}
//         </p>
//       )}
//     </>
//     )
//   };

const ShowTimings = ({ cinemaId, timings, ss_id, type_seat_show, screen_id, md_id, movie_id, ss_actual_start_date }) => {

	let timings1 = []
	timings1.push(timings)
	console.log(timings1, "timings???")
	console.log(cinemaId, "timings11111???")
	const { arabic, currency } = useContext(globalConfigContext);
	const history = useHistory();


	useEffect(() => {
		const timeout = setTimeout(() => {
			history.push('/');
		}, 60000); // 60000 milliseconds = 1 minute

		return () => clearTimeout(timeout);
	}, [history]);

	const onClickShowTime = (t, SessionId) => {
		console.log(SessionId, "show tme sjsnd")
		localStorage.setItem("selected_showtime", t);
		localStorage.setItem("ss_actual_start_date", ss_actual_start_date);
		console.log(SessionId, "timings1 >>>>>>>>>>>>")
		// history.push('/seat-layout/' + cinemaId + '/' + SessionId +  "/" + screen_id + "/"+type_seat_show+"/"+md_id +"/"+movie_details_id);
		history.push('/seat-layout/' + screen_id + '/' + SessionId + "/" + md_id + "/" + type_seat_show + "/" + movie_id + "/" + cinemaId);
	};

	useEffect(() => {
		console.log(timings1, "thi sis jaijeijd")
	}, [timings1]);

	return (
		<div className="showTimings">




			{/* <h1>{translateSessionAttribute(arabic, plan)}</h1> */}
			<div className="time_slots">
				{timings1.map((t, index) =>

					moment(t).isBefore(moment()) ? (
						<button key={index} className="chooseTime_button showtime inactive">
							{/* <span className="">{new Date(t).getHours() > 12 ? (t.getHours() - 12) : t}{`${new Date(t).getHours() > 12 ? "PM" : "AM"} `}</span> */}
							{/* <span className="">{new Date(t).getHours() > 12 ? (t.getHours() - 12) : t}</span> */}
							<span>{moment(t, 'HH:mm').format('h:mm A')}</span>

						</button>
					) : (
						<div className='d-flex'>
							<button
								key={index}
								onClick={() => onClickShowTime(t, ss_id)}
								className="chooseTime_button showtime"
							>
								{/* <span className="">{new Date(t).getHours() > 12 ? (t.getHours() - 12) : t}{`${new Date(t).getHours() > 12 ? "PM" : "AM"} `}</span> */}
								{/* <span className="">{new Date(t).getHours() > 12 ? (t.getHours() - 12) : t}</span> */}
								{/* <span>{t}</span> */}
								<span>{moment(t, 'HH:mm').format('h:mm A')}</span>

							</button>
						</div>
					)
				)}
			</div>
		</div>
	);
};
// const DateCardSliderUI = () => {

//     // console.log(days, "days >>>>>>>>>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>> >>>>>>")

//     return(
//       <>
//       {days && days.length > 0 ? (
//         <div className="">
//           <Slider
//             {...chooseDateSlider}
//             className="arrows-3 slick-track-no-margin movie-slider-arrows"
//           >
//             {days
//               // .slice(movieDetails?.Title.includes(" vs ") ? 0 : 1)
//               .filter((item)=>{
//               if(
//                 moment.utc(item._i).isBefore(moment
//                   .utc()
//                   .format('YYYY-MM-DD')) == true
//               ){
//                 return false
//               }else{
//                 return true
//               }
//             })
//               .map((day, index) => (
//                   <div className="" key={index}>
//                       <div
//                           className={`choose-date-card ${
//                               moment.utc(day).locale("en").format("YYYY-MM-DD") ===
//                               currentShowDate
//                                   ? "selected-date-card"
//                                   : ""
//                           }`}
//                           onClick={() =>
//                               setCurrentDate(day.locale("en").format("YYYY-MM-DD"))
//                           }
//                       >
//                           <div className="day">{day.format("ddd")}</div>
//                           <div className="date">{day.format("D")}</div>
//                           <div className="month">{day.format("MMM")}</div>
//                       </div>
//                   </div>
//               ))}
//           </Slider>
//         </div>
//       ) : (
//         <p className="kumbh fw-reg frxl-20 frl-18 frm-16 frm-14 ls-3 mb-3">
//           {t("movie_page.no-shows-available")}
//         </p>
//       )}
//     </>
//     )
//   };
export default withNamespaces()(MovieDetailsContainer);
