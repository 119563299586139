import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Container, Row, Col, Table, Alert, Modal, Spinner } from 'react-bootstrap';
import { stopTimer } from '@store/booking/bookingActions';
import { isImageURLNull } from '@helper/formatting';
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import MovieHeader from '@components/partials/MovieHeader';
import { getDiscount } from '@store/booking/bookingSelector';
import { globalConfigContext } from '@context/GlobalConfigContext';
import moment from 'moment';
import QRCode from 'qrcode';
import { PaymentService } from '@apiService/PaymentService';
import { withNamespaces } from 'react-i18next';
import {getOMAErrorCode} from '../components/partials/PosErrorCodes'
import i18n from '../plugins/i18n';
// import Receipt from '@components/Receipt';
import R1 from '@components/R1.js';
import R2 from '@components/R2.js';
import { useReactToPrint } from 'react-to-print';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import movieDefLand from '@assets/imgs/movie-default-landscape.jpg';
import axios from 'axios';
// import movieDef from '@assets/imgs/svgs/movie_default.svg';
// import { SOCKET_URL } from '@config';
// var wsUrl = SOCKET_URL;
// var socket = new WebSocket(wsUrl);

var opts = {
	errorCorrectionLevel: 'H',
	type: 'image/jpeg',
	quality: 0.3,
	margin: 1,
	width: '150',
	color: {
		dark: '#000000',
		light: '#FFFFFF',
	},
};

function FinalTicketPage(props) {
	const { arabic, currency } = useContext(globalConfigContext);
	const dispatch = useDispatch();
	const history = useHistory();
	const t = props.t;

	const movieDetails = useSelector((state) => state.movies.movie_details[0]);

	console.log(movieDetails, "movieDetails >>>>>>>>>>>>>>>>")

	const bookingFee = useSelector((state) => state.booking.bookingFee);

	console.log(bookingFee, "KDFJDSFLDFJDSF")

	const discount = useSelector(getDiscount);
	const onClickBack = () => {
		history.go(-1);
	};
	const [qrcodeData, setQrcodeData] = useState(null);

	const [isProcessing, setISProcessing] = useState(false)
	const [isProcessingError, setISProcessingError] = useState(false)
	const [posConnectionRes, setPosConnectionRes] = useState(false)
	const [isPosConnected, setisPosConnected] = useState(false)
	const [errorMassage, setErrorMassage] = useState("")


	const ticketReceiptRef = useRef();
	const paymentReceiptRef = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(stopTimer());
		onConnectOMA()
	}, []);


	const onConnectOMA = () => {
		setISProcessing(true)

			axios.get('https://localhost:5005/EFTTransact?ComType=Connect')
			.then((response)=>{
				setPosConnectionRes(response)

				var decodedValue = window.atob(response);
				let responseData = decodedValue ? JSON.parse(decodedValue) : { EFTData: null };
				const { EFTData = null } = responseData;

				if (!EFTData) {
					setISProcessingError(true)
				}else{
					if(EFTData.hasOwnProperty('ErrorCode') && EFTData['ErrorCode'] == "E000"){
						setisPosConnected(true)
					}else{
						setisPosConnected(false)
						let errorMsg = getOMAErrorCode(EFTData['ErrorCode'] || null) || 'Connection Failed!'
						setErrorMassage(errorMassage)
					}
				}
			})
			.catch((err)=>{
				console.log(err)
				setISProcessingError(true)
				// setErrorMassage(err)
			})
	}

	// const initPrintTicketReceipt = useReactToPrint({
	// 	content: () => ticketReceiptRef.current,
	// });

	// const initPrintPaymentReceipt = useReactToPrint({
	// 	content: () => paymentReceiptRef.current,
	// });


	return (
		<div className="">
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 1000,
					right: 0,
				}}
			>
				<MovieHeader />
			</div>

			{/* <div ref={receiptRef} className="receipt">
				<Receipt data={movieDetails} />
			</div> */}

			{/* Ticket Receipt */}
			<div className="receipt">
				<R1 data={movieDetails} ref={ticketReceiptRef} />
			</div>

			{/* Payment Receipt */}
			<div className="receipt">
				<R2 data={movieDetails} ref={paymentReceiptRef} />
			</div>

			<div className="receipt-overlay" />


			<hr></hr>
			<div>
			errorMassage : {errorMassage}
			</div>
			<div>
			isPosConnected : {isPosConnected}
			</div>
			<div>
			posConnectionRes : {posConnectionRes}
			</div>
			<div>
			isProcessingError : {isProcessingError}
			</div>
			<div>
			isProcessing : {isProcessing}
			</div>
			<hr></hr>

			<div className="checkout-page-container">
				<Container fluid className="py-3 mb-4">
					<Row className="d-flex justify-content-center my-5 ">
						<Col xs={6}>
							<h1 className="fw-bold text-center">
								{t('checkout.Your booking is successful, enjoy the show!')}
							</h1>
						</Col>
					</Row>
					<Row className="fnb-checkout">
						<Col xs={8} className="mx-auto">
							<Row className="mxh-70 bg-pb d-flex align-items-center py-3">
								<Col>
									<p className="fw-bold text-center frs-24">{t('checkout.YOUR TICKET')}</p>
								</Col>
							</Row>
							<Row>
								<Col className="px-0">
									<div className="hero">
										<div className="imgOverlay d-flex justify-content-center">
											<img
												className="bg-img"
												src={
													!isImageURLNull(movieDetails.movie_image_url_2)
														? movieDetails.movie_image_url_2
														: movieDefLand
												}
											/>
										</div>
										<div className="hero-text px-3 d-flex flex-column justify-content-end">
											<Container fluid>
												<Row className="pb-4">
													<Col>
														<Row className="pb-3">
															<Col xs={8}>
																<div className="d-flex align-items-center">
																	<img
																		src={
																			!isImageURLNull(movieDetails.movie_image_url_1)
																				? movieDetails.movie_image_url_1
																				: movieDef
																		}
																		alt="title"
																		className="moviePoster"
																	/>
																	<div
																		className={`d-flex flex-column justify-content-end fw-reg frs-12 ${
																			arabic ? 'pr-3' : 'pl-3'
																		}`}
																	>
																		<p className="fw-bold frs-18">
																			{arabic
																				? movieDetails.movie_title_ar
																				: movieDetails.movie_title}
																		</p>
																		<p className="mb-2">{movieDetails.cinema_name}</p>
																		<p className="mb-2">
																			{moment.utc(movieDetails.Showtime).format('DD-MM-YY')}
																		</p>
																		<p className="mb-2">
																			{t('fnbconfirm.Seats')} {movieDetails.seats_name}
																		</p>
																		<p className="">{movieDetails.SessionAttribute}</p>
																	</div>
																</div>
															</Col>
															<Col
																xs={4}
																className="d-flex flex-column justify-content-end align-items-end frs-12 fw-reg text-center"
															>
																<img src={qrcodeData} className="qr" />
																<p className="">{t('checkout.Scan this code and recieve')}</p>
																<p className="">{t('checkout.the ticket on your phone')}</p>
															</Col>
														</Row>
													</Col>
												</Row>
											</Container>
										</div>
									</div>
								</Col>
							</Row>

							<Row className="bgb py-5 mb-4">
								<Col xs={8} className="br-1">
									<div className="px-3 frs-20 fw-bold">
										<p className="ls-2 pb-3">{t('checkout.Order Details')}</p>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="blurred-text">
												{t('checkout.Tickets')} {movieDetails.seats_name}
											</span>
											<span className="frs-24">
												{(movieDetails.ticket_price_in_cents - movieDetails.ticket_taxes_in_cents) /
													currency.divided_by}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="blurred-text">
												{t('checkout.Food and Beverages')} (x{movieDetails.fnb_items_count})
											</span>
											<span className="frs-24">
												{(movieDetails.fnb_price_in_cents - movieDetails.fnb_taxes_in_cents) /
													currency.divided_by}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="blurred-text">
												{t('checkout.Taxes')}
												<br />
												<span className="blurred-text-2">({movieDetails.tax_percent}%)</span>
											</span>
											<span className="frs-24">
												{(
													(movieDetails.fnb_taxes_in_cents +
														movieDetails.ticket_taxes_in_cents +
														(movieDetails.booking_fee -
															movieDetails.booking_fee / (1 + movieDetails.tax_percent / 100))) /
													currency.divided_by
												).toFixed(2)}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
										{discount > 0 && (
											<div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
												<span className="blurred-text">{t('checkout.discount-applied')}</span>
												<span className="fw-bold frs-24">
													{discount / currency.divided_by}{' '}
													{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
												</span>
											</div>
										)}
										{/* <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
											<span className="blurred-text">{t('checkout.Booking Fee')}</span>
											<span className="frs-24">
												{(
													(movieDetails.booking_fee -
														(movieDetails.booking_fee -
															movieDetails.booking_fee / (1 + movieDetails.tax_percent / 100))) /
													currency.divided_by
												).toFixed(2)}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div> */}
										<div className="pb-3 d-flex justify-content-between bb-1">
											<span className="blurred-text">{t('collect_ticket.Total')}</span>
											<span className="frs-24">
												{movieDetails.transaction_net_price / currency.divided_by}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
									</div>
								</Col>
								<Col xs={4}>
									<div className="h-100 w-100 d-flex flex-column justify-content-between frs-28 ml-4 fw-bold">
										<span>
											<p className="frs-16 blurred-text">{t('checkout.Booking Date')}</p>
											<p>{moment.utc(movieDetails.transaction_date_time).format('DD/MM/YYYY')}</p>
										</span>
										<span>
											<p className="frs-16 blurred-text">{t('checkout.Booking Time')}</p>
											<p>{moment.utc(movieDetails.transaction_date_time).format('h:mm A')}</p>
										</span>
										<span>
											<p className="frs-16 blurred-text">{t('checkout.Payment Method')}</p>
											<p>{movieDetails.CardNameEnglish}</p>
										</span>
										<span>
											<p className="frs-16 blurred-text">{t('checkout.Ticket ID')}</p>
											<p>{movieDetails.bookingId}</p>
										</span>
									</div>
								</Col>
							</Row>

							<Row className="mt-5 justify-content-center">
								<Col className="text-center">
									<span
										className="d-inline-block ls-3 frs-24 fw-bold px-5 blue-btn"
										// onClick={() => initPrintTicketReceipt(movieDetails)}
									>
										{t('checkout.PRINT TICKET')}
									</span>
								</Col>
							</Row>
							{/* <Row className="mt-5 justify-content-center">
								<Col className="text-center">
									<span
										className="d-inline-block ls-3 frs-24 fw-bold px-5 blue-btn"
										onClick={() => initPrintPaymentReceipt(movieDetails)}
									>
										{t('checkout.PRINT PAYMENT RECEIPT')}
									</span>
								</Col>
							</Row> */}
							<Row className="mt-5 justify-content-center">
								<Col className="text-center">
									<span
										className="d-inline-block ls-3 frs-24 fw-bold px-5 blue-btn"
										// onClick={goHome}
									>
										{t('checkout.RETURN TO HOMEPAGE')}
									</span>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default withNamespaces()(FinalTicketPage);
