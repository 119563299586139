import React, { useEffect, useState } from 'react';
import GenericService from '@apiService/GenericService';

export const globalConfigContext = React.createContext();


const GlobalConfigContext = (props) => {
	const setArabic = (isArabic) => {
		setState((prev) => ({ ...prev, arabic: isArabic }));
	};

	const [state, setState] = useState({
		arabic: false,
		country_selector_url: null,
		currency: {},
		payment_data: {},
		country_data: {},
		setArabic,
	});




	// useEffect(() => {


	// 	GenericService.GetCountrySpecificData({
	// 		country_id : 1
	// 	}).then((response) => {
	// 		if (response && response.status === 200) {
	// 			const data = response.data.data;
	// 			console.log(data , "data >>>>>>>>>>>>>>>>>>>>>")

	// 			setState((prev) => ({
	// 				...prev,
	// 				country_data: {
	// 					country_code: data.country_code,
	// 					country_id: data.country_id,
	// 					country_name: data.country_name,
	// 					country_short_code: data.country_short_code,
	// 				},
	// 				currency: {
	// 					currency_name: data.currency_name,
	// 					currency_short_code: data.currency_short_code,
	// 					currency_short_code_ar: data.currency_short_code_ar,
	// 					currency_symbol: data.currency_symbol,
	// 					divided_by: data.divided_by,
	// 				},
	// 				payment_data: {
	// 					access_code: data.access_code,
	// 					merchant_identifier: data.merchant_identifier,
	// 					payment_url: data.payment_url,
	// 				},
	// 				country_selector_url: data.country_selector_url,
	// 			}));
	// 		}
	// 	});
	// }, []);

	return (
		<globalConfigContext.Provider value={state}>{props.children}</globalConfigContext.Provider>
	);
};

export default GlobalConfigContext;
