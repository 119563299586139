export const getOMAErrorCode = (errorCode = '') => {
    let errorMsg = ``;
    if(errorCode) {
      switch (errorCode) {
        case "E000":
          errorMsg = `TRANSACTION SUCCESS`;
          break;
        case "E001":
          errorMsg = `Terminal Not Initialized`;
          break;
        case "E002":
          errorMsg = `Batch Full`;
          break;
        case "E003":
          errorMsg = `Card Not removed from Card Reader`;
          break;
        case "E004":
          errorMsg = `Incorrect Amount Received`;
          break;
        case "E005":
          errorMsg = `Invalid Message Type Received`;
          break;
        case "E006":
          errorMsg = `No Paper Roll`;
          break;
        case "E007":
          errorMsg = `XML Format Error`;
          break;
        case "E008":
          errorMsg = `Expired Card`;
          break;
        case "E009":
          errorMsg = `Card Not Supported`;
          break;
        case "E010":
          errorMsg = `Transaction Not Permitted`;
          break;
        case "E011":
          errorMsg = `Low Battery (In IWL Terminal only)`;
          break;
        case "E012":
          errorMsg = `Data Base Error Exception`;
          break;
        case "E013":
          errorMsg = `Invalid Track`;
          break;
        case "E014":
          errorMsg = `Customer Cancellation`;
          break;
        case "E015":
          errorMsg = `Card Reader TimeOut`;
          break;
        case "E016":
          errorMsg = `Pin Time Out`;
          break;
        case "E017":
          errorMsg = `Invalid Expiry`;
          break;
        case "E018":
          errorMsg = `Card not supported (Bin Not found)`;
          break;
        case "E019":
          errorMsg = `AID Not Supported`;
          break;
        case "E020":
          errorMsg = `Wrong Password (Need to check)`;
          break;
        case "E021":
          errorMsg = `Chip Read Error`;
          break;
        case "E022":
          errorMsg = `Service Code Check Error`;
          break;
        case "E023":
          errorMsg = `Connection Error`;
          break;
        case "E024":
          errorMsg = `Send Exception`;
          break;
        case "E025":
          errorMsg = `Receive Exception`;
          break;
        case "E026":
          errorMsg = `Invalid Receipt Number`;
          break;
        case "E027":
          errorMsg = `Transaction already voided`;
          break;
        case "E028":
          errorMsg = `Transaction not found`;
          break;
        case "E029":
          errorMsg = `Wrong N Digits`;
          break;
        case "E030":
          errorMsg = `Maximum Amount Digits Exceeded`;
          break;
        case "E031":
          errorMsg = `ATM Only Exception`;
          break;
        case "E032":
          errorMsg = `Amount Not Matching`;
          break;
        case "E033":
          errorMsg = `Disk Exception (Remote Program Download)`;
          break;
        case "E034":
          errorMsg = `Decompression Exception`;
          break;
        case "E035":
          errorMsg = `Reversal Incomplete`;
          break;
        case "E036":
          errorMsg = `Card Removed during Transaction`;
          break;
        case "E037":
          errorMsg = `Response parse Error`;
          break;
        case "E038":
          errorMsg = `Reversal Send Exception`;
          break;
        case "E039":
          errorMsg = `Reversal Receive Exception`;
          break;
        case "E040":
          errorMsg = `Crypto Error`;
          break;
        case "E041":
          errorMsg = `Batch Empty`;
          break;
        case "E042":
          errorMsg = `Swipe Card Only`;
          break;
        case "E043":
          errorMsg = `Runtime Exception`;
          break;
        case "E044":
          errorMsg = `Contactless Read Exception`;
          break;
        case "E045":
          errorMsg = `Sending Batch In complete`;
          break;
        case "E046":
          errorMsg = `Fall Back Not Supported`;
          break;
        case "E047":
          errorMsg = `Exceed Max Tip Exception`;
          break;
        case "E048":
          errorMsg = `Transaction Time Out Exception`;
          break;
        case "E050":
          errorMsg = `Invalid RRN Exception`;
          break;
        case "E051":
          errorMsg = `Initialize POS Exception`;
          break;
        case "E052":
          errorMsg = `Connection retry exceeded/ Serial Number Mismatch`;
          break;
        case "E053":
          errorMsg = `Batch Delete Exception`;
          break;
        case "E054":
          errorMsg = `Invalid Password`;
          break;
        case "E057":
          errorMsg = `Key Load Exception`;
          break;
        case "E058":
          errorMsg = `CA Key Exception`;
          break;
        case "E059":
          errorMsg = `Mag Stripe Read Exception`;
          break;
        case "E061":
          errorMsg = `GPRS Reboot Required`;
          break;
        case "E062":
          errorMsg = `Param Download Failed`;
          break;
        case "E065":
          errorMsg = `PIN Key KCV Error`;
          break;
        case "E066":
          errorMsg = `PAN Key KCV Error`;
          break;
        default:
          errorMsg = `FAILED`;
          break;
      }
    } 
    return errorMsg;
  }