import React, { useEffect, useState, useContext } from 'react';
import i18n from '../plugins/i18n';
import { useHistory } from 'react-router-dom';
import { globalConfigContext } from '@context/GlobalConfigContext';
import moment from 'moment';
import GoBack from '@components/partials/GoBack';
import { CinemaService } from '@apiService/tokenService';
// import 'moment/locale/ar';
import { withNamespaces } from 'react-i18next';
import { PaymentService } from '@apiService/PaymentService';
import { SOCKET_URL } from '@config';
import { Link } from 'react-router-dom';
// var wsUrl = SOCKET_URL;
// var socket = new WebSocket(wsUrl);

const lang = window.localStorage.getItem('lang');

if (lang && lang === 'ar') {
	import('@assets/css/arabic.css').then((condition) => {});
	// moment.locale('ar');
} else {
	import('@assets/css/App.css').then((condition) => {});
	// moment.locale('en');
}

function ChooseCinema({ t }) {
	const { arabic, setArabic } = useContext(globalConfigContext);

	useEffect(() => {
		if (lang && lang === 'ar') {
			document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
			setArabic(true);
		} else {
			document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
			setArabic(false);
		}
		i18n.changeLanguage(lang ? lang : 'en');
	}, []);

	const history = useHistory();
	const onSelectCinema = (id) => {
		if (id) {
			CinemaService.setCinemaId(id);
			history.push('/book-show');
		}
	};

	return (
		<div className="parent">
			<div id="printableArea"></div>
			<div className="gb">
				<GoBack className="" />
			</div>
			<div className=""></div>
			{/* <div className="go_back">
				<div className="button_wrap">
					<div className="go_back_button"></div>
				</div>
				<h2 className="big_text">GO BACK</h2>
			</div> */}
			<div className="page2_container">
				{/* <Link to="/" className="page2_movie_header">
					<h2 className="small_text">{t('common.WELCOME TO')}</h2>
					<div className="logo2"></div>
					
				</Link> */}
				<div className="page2_body">
					<div className="choose_action">{t('choose_cinema.CHOOSE YOUR CINEMA')}</div>
					<div className="huge_buttons">
						<button onClick={() => onSelectCinema('1332')} className="left_button hb">
							{t('choose_cinema.JEDDAH')}
						</button>
						<button onClick={() => onSelectCinema('1327')} className="right_button hb">
							{t('choose_cinema.DAMMAM')}
						</button>
						<button onClick={() => onSelectCinema('1329')} className="right_button hb">
							{t('choose_cinema.JIZAN')}
						</button>

						{/* <button onClick={() => history.push('/process-payment')} className="right_button hb">
							payment page
						</button> */}
					</div>
				</div>
			</div>
		</div>
	);
}
export default withNamespaces()(ChooseCinema);
