import React, { useState, useEffect, useContext } from 'react';
import { getArabicNumber } from '@helper/formatting';
import { globalConfigContext } from '@context/GlobalConfigContext';
import moment from 'moment';
import QRCode from 'qrcode';

var opts = {
	errorCorrectionLevel: 'H',
	type: 'image/jpeg',
	quality: 0.3,
	margin: 1,
	width: '150',
	color: {
		dark: '#000000',
		light: '#FFFFFF',
	},
};

const Receipt = ({ data }) => {
	const { arabic, currency } = useContext(globalConfigContext);
	const [qrcode, setQrcode] = useState(null);

	useEffect(() => {
		if (data && data.bookingId) {
			QRCode.toDataURL(data.bookingId, opts, function (err, url) {
				if (err) throw err;
				setQrcode(url);
			});
		} else {
			setQrcode(null);
		}
	}, []);

	return (
		<center>
			<div
				style={{
					height: '100vh',
					width: '100%',
					overflowY: 'auto',
					backgroundColor: 'white',
					color: 'black',
				}}
			>
				<table style={{ fontFamily: 'arial', width: '380px' }} align="center">
					<tbody>
						<tr>
							<td colspan="2" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'ltr', padding: '0 15px', paddingTop: '15px' }}>
											Cinépolis Cinemas
										</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'rtl', padding: '0 15px' }}>سينيبوليس</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="1" style={{ textAlign: 'left', paddingLeft: '15px' }}>
								{data.ScreenName}
							</td>
							<td colspan="1" style={{ textAlign: 'right', paddingRight: '15px' }}>
								{data.ScreenNameAlt}
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'center' }}>
								<font size="4">
									<b style={{ padding: '0 15px' }}>{data.movie_title}</b>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								<span>Show Date/تاريخ العرض: </span>
								<span style={{ float:'right' }}>{moment.utc(data.Showtime).locale('en').format('DD/MM/YYYY')}</span>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								<span>Show Time/وقت العرض: </span>
								<span style={{ float:'right' }}>{moment.utc(data.Showtime).locale('en').format('hh:mm a')}</span>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								Rating: {data.rating}
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								<b>
									<font size="4">
										Seat/مقعد:
										{data.seats_name}
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2" style={{ textAlign: 'left', padding: '0 15px' }}>
								Seat Type/نوع المقعد:
								{data.SessionAttribute ? data.SessionAttribute : 'Premium'}
							</td>
						</tr>
						<tr>
							<td colspan="1">
								<font size="2">
									<span style={{ direction: 'rtl', paddingLeft: '15px' }}>
										Price: {data.currency_short_code}{' '}
										{(data.transaction_net_price / data.divided_by).toFixed(2)}
									</span>
								</font>
							</td>
							<td style={{ textAlign: 'right' }}>
								<font size="2">
									<span style={{ direction: 'rtl', paddingRight: '15px' }}>
										السعر: {data.currency_short_code_ar}{' '}
										{getArabicNumber((data.transaction_net_price / data.divided_by).toFixed(2))}
									</span>
								</font>
							</td>
						</tr>
						{/* <tr style={{ textAlign: 'left' }}>
							<td colspan="2" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									T/N: 80
								</font>
							</td>
						</tr> */}
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<img src={qrcode} height="100px" width="100px" />
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									Luxury Entertainment LLC
								</font>
							</td>
						</tr>
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									{data.cinema_address}
								</font>
							</td>
						</tr>
						{/* <tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<font size="2" style={{ padding: '0 15px' }}>
									Jeddah, KSA
								</font>
							</td>
						</tr> */}
						<tr style={{ textAlign: 'center' }}>
							<td colspan="2" valign="top" height="25px">
								<b>
									<font size="2" style={{ padding: '0 15px 15px' }}>
										www.cinepolisgulf.com
									</font>
								</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</center>
	);
};

export default Receipt;
