import Api from './Api';
import { createQueryString } from './ApiHelper';

export default {
	GetNowPlayingMovies(payload = {}) {
		const query = createQueryString(payload);
		console.log(query, 'DJK:SJFDKLASFJK');
		return Api().get(`movies/now-playing/${query}`);
	},
	// GetMovieDetails(payload) {
	// 	return Api().get(`movies/movie-details/${payload.movie_id}`);
	// },
	// GetShowTimes(payload) {
	// 	let query = createQueryString(payload);
	// 	return Api().get(`movies/show-times/${payload.movie_id}/${query}`);
	// },
	GetShowDates(payload) {
		let query = createQueryString(payload);
		return Api().get(`movies/show-dates/${query}`);
	},
	GetCinemas(payload) {
		return Api().post(`external/cinemas`, payload);
	},
	GetNowPlayingMoviesEvent(payload) {
		let query = createQueryString(payload);
		return Api().get(`movies/now-playing-bookevent/${query}`);
	},
	//bhrij api 
	GetNowShowingMovies() {
		// let query = createQueryString(payload);
		return Api().get(
			`cinema/admin/now-showing-confirmed-list?limit=1000&currentPage=1&cinema_id=1&city_id=31264&rtk=true`
		);
	},
	GetMovieDetails(payload) {
		// let query = createQueryString(payload);
		// return Api().get(`movies/movie-details/${payload.movie_id}`);
		return Api().get(`cinema/movie-detail/${payload}/1/?cinema_id=1,`);
	  },
	  GetShowTimes(payload) {
		let query = createQueryString(payload);
		return Api().get(`cinema/admin/movie-confirmed-list/${payload}?cinema_id=1&city_id=31264`);
	  },
	

	  GetAllCinemas(payload) {
		// let query = createQueryString(payload);
		return Api().post(`external/cinemas`);
	  },
	

};
