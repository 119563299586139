import React, { useState, useEffect, useContext } from 'react';
import { globalConfigContext } from '@context/GlobalConfigContext';
import moment from 'moment';
import QRCode from 'qrcode';
import ip1 from '@assets/imgs/ip1.jpg';

var opts = {
	errorCorrectionLevel: 'H',
	type: 'image/jpeg',
	quality: 0.3,
	margin: 1,
	width: '150',
	color: {
		dark: '#000000',
		light: '#FFFFFF',
	},
};

const Receipt = ({ data }) => {
	const { arabic, currency } = useContext(globalConfigContext);
	const [qrcode, setQrcode] = useState(null);

	useEffect(() => {
		if (data && data.bookingId) {
			QRCode.toDataURL(data.bookingId, opts, function (err, url) {
				if (err) throw err;
				setQrcode(url);
			});
		} else {
			setQrcode(null);
		}
	}, []);

	const dateString = data.TransacitonRequestDateTime;
	const day = dateString.substring(6, 8);
	const month = dateString.substring(4, 6);
	const year = dateString.substring(0, 4);
	const hour = dateString.substring(8, 10);
	const mins = dateString.substring(10, 12);
	const seconds = dateString.substring(12, 14);

	const date = `${day}/${month}/${year}`;
	const time = `${hour}:${mins}:${seconds}`;
	const momentdatestring = `${year}-${month}-${day} ${hour}:${mins}:${seconds}`;

	return (
		<center>
			<div
				style={{
					height: '100%',
					width: '100%',
					overflowY: 'auto',
					backgroundColor: 'white',
					color: 'black',
				}}
			>
				<table style={{ fontFamily: 'arial', width: '380px' }} align="center">
					<tbody>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<b>
									<font size="4">
										<span style={{ direction: 'rtl' }}>{data.MerchantNameArabic}</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								{data.MerchantNameEnglish}
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<span style={{ direction: 'rtl' }}>{data.MerchantAddress1Arabic}</span>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								{data.MerchantInfo ? data.MerchantInfo.split('|')[3] : ''}
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{moment.utc(momentdatestring).locale('ar').format('DD/MM/YYYY')}
									</span>
								</font>
							</td>
							<td style={{ textAlign: 'right' }}>
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{moment.utc(momentdatestring).locale('ar').format('HH:mm:ss')}
									</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">{date}</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">{time}</font>
							</td>
						</tr>
						<tr>
							<td>
								<font size="1">{data.BankId}</font>
							</td>
							<td style={{ textAlign: 'left' }}>
								<font size="1">{data.CardAcceptorIdCode}</font>
							</td>
							<td style={{ textAlign: 'right' }}>
								<font size="1">{data.CardAcceptorTerminalId}</font>
							</td>
						</tr>
						<tr>
							<td>
								<font size="1">{data.CardAcceptorBusinessCode}</font>
							</td>
							<td style={{ textAlign: 'left' }}>
								<font size="1">{data.TransactionSTAN}</font>
								<font size="1" style={{ paddingLeft: '16px' }}>
									{data.TerminalVersion}
								</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="1">{data.RetrievalReferenceNumber}</font>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<b>
									<font size="2">{data.CardNameEnglish}</font>
								</b>
							</td>
							<td style={{ textAlign: 'right' }}>
								<b>
									<font size="2">{data.CardNameArabic}</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="30px">
								<font size="2">{data.TransactionTypeAsReadable}</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="30px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>شراء</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="30px">
								<font size="2">{data.PrimaryAccountNumber}</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="30px">
								<font style={{ float: 'right' }} size="2">
									{data.CardExpiryDate}
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<b>
									<font size="2">PURCHASE AMOUNT</font>
								</b>
							</td>
							<td style={{ textAlign: 'right' }}>
								<b>
									<font size="2">
										<span style={{ direction: 'rtl' }}>مبلغ الشراء</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<b>
									<font size="2">
										{currency.currency_short_code} {data.TransactionAmount}
									</font>
								</b>
							</td>
							<td style={{ textAlign: 'right' }}>
								<b>
									<font size="2">{data.TransactionAmount} ريـال</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="bottom" height="30px">
								<b>
									<font size="2">
										<span style={{ direction: 'rtl' }}>{data.TransactionResultReasonArabic}</span>
									</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="bottom" height="30px">
								<b>
									<font size="2">{data.TransactionResultReasonEnglish}</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="30px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>تم التحقق من الرقم السري للعميل</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="30px">
								<font size="2">{data.CardHolderVerificationMethodAsReadable}</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
								<font size="1">
									<span style={{ direction: 'rtl' }}>أوافق على الخصم من حسابي للمبلغ</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
								<font size="1">DEBIT MY ACCOUNT FOR THE AMOUNT</font>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<font size="1">
									<b>
										<span style={{ direction: 'rtl' }}>رمز الموافقة</span>
									</b>
								</font>
							</td>
							<td style={{ textAlign: 'right' }}>
								<font size="1">
									<b>
										<span style={{ direction: 'rtl' }}>٥٨٩١٠٠</span>
									</b>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<font size="1">
									<b>APPROVAL CODE</b>
								</font>
							</td>
							<td style={{ textAlign: 'right' }}>
								<font size="1">
									<b>{data.TransactionAuthCode}</b>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="bottom" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>١٢/١١/٢٠٢٠</span>
								</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="bottom" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>١٥:١٠:١٣</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">
									{moment.utc(momentdatestring).locale('ar').format('DD/MM/YYYY')}
								</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{moment.utc(momentdatestring).locale('ar').format('HH:mm')}
									</span>
								</font>
							</td>
						</tr>

						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
								<b>
									<font size="2">TICKET DETAILS</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">SEAT NAME(S)</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>{data.seats_name}</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">BOOKING ID</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>{data.bookingId}</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">CINEMA NAME</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{arabic ? data.cinema_name_ar : data.cinema_name}
									</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">MOVIE NAME</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{arabic ? data.movie_title_ar : data.movie_title}
									</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="2" valign="top" height="25px">
								<font size="2">SHOW TIME</font>
							</td>
							<td style={{ textAlign: 'right' }} valign="top" height="25px">
								<font size="2">
									<span style={{ direction: 'rtl' }}>
										{arabic
											? moment.utc(data.Showtime).locale('ar').format('DD/MM/YYYY HH:mm:ss')
											: moment.utc(data.Showtime).locale('en').format('DD/MM/YYYY HH:mm:ss')}
									</span>
								</font>
							</td>
						</tr>

						<tr>
							<td colspan="3" style={{ textAlign: 'center', padding: '1.5rem 0' }}>
								<img src={qrcode} height="100px" width="100px" />
							</td>
						</tr>

						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">
									<span style={{ direction: 'rtl' }}>شكرًا لاستخدامكم مدى</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">THANK YOU FOR USING mada</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">
									<span style={{ direction: 'rtl' }}>يرجي الاحتفاظ بالايصال</span>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">PLEASE RETAIN RECEIPT</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">
									<b>
										<span style={{ direction: 'rtl' }}>** نسخة العميل **</span>
									</b>
								</font>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="20px">
								<b>
									<font size="1">** CUSTOMER COPY **</font>
								</b>
							</td>
						</tr>
						<tr>
							<td colspan="3" style={{ textAlign: 'center' }}>
								<font size="1">{data.EMVTagsIssuer}</font>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</center>

		// <center>
		// 	<div
		// 		style={{
		// 			height: '100%',
		// 			width: '100%',
		// 			overflowY: 'auto',
		// 			backgroundColor: 'white',
		// 			color: 'black',
		// 		}}
		// 	>
		// 		<table style={{ fontFamily: 'arial', width: '380px' }} align="center">
		// 			<tbody>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<b>
		// 							<font size="4">
		// 								<span style={{ direction: 'rtl' }}>ﻧ٦ﻑ۲ﻑ٤ﻑﻲ٤ﻑ۵ﻑﻟﺉﺿﻑﻣ۵ﻑﻟﻧﻷ۸ﻑ٤ﻑ</span>
		// 							</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						mPOS
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<span style={{ direction: 'rtl' }}>ﻧ٦ﻑ۲ﻑ٤ﻑﻲ٤ﻑ۵ﻑﻟﺉﺿﻑﻣ۵ﻑﻟﻧﻷ۸ﻑ٤ﻑ</span>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						King Saudi Street
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>١٢/١١/٢٠٢٠</span>
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>١٥:١٠:١٣</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">27/11/2020</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">17:29:10</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td>
		// 						<font size="1">HAJH</font>
		// 					</td>
		// 					<td style={{ textAlign: 'left' }}>
		// 						<font size="1">0101010101010101</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<font size="1">882374982730483</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td>
		// 						<font size="1">1040</font>
		// 					</td>
		// 					<td style={{ textAlign: 'left' }}>
		// 						<font size="1">0034772</font>
		// 						<font size="1" style={{ paddingLeft: '16px' }}>
		// 							6.00
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="1">893724920978924</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<b>
		// 							<font size="2">mada</font>
		// 						</b>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<b>
		// 							<font size="2">arabic</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="top" height="30px">
		// 						<font size="2">Purchase</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="30px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>شراء</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="top" height="30px">
		// 						<font size="2">9283467920397583</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="30px">
		// 						<font style={{ float: 'right' }} size="2">
		// 							08/20
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<b>
		// 							<font size="2">
		// 								<span style={{ direction: 'rtl' }}>مبلغ الشراء</span>
		// 							</font>
		// 						</b>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<b>
		// 							<font size="2">١.٠٠ ريـال</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<b>
		// 							<font size="2">PURCHASE AMOUNT</font>
		// 						</b>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<b>
		// 							<font size="2">
		// 								SAR 1.00
		// 							</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="bottom" height="30px">
		// 						<b>
		// 							<font size="2">
		// 								<span style={{ direction: 'rtl' }}>مقبولة</span>
		// 							</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="bottom" height="30px">
		// 						<b>
		// 							<font size="2">
		// 								CARDHOLDER PIN
		// 							</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="30px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>تم التحقق من الرقم السري للعميل</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="30px">
		// 						<font size="2">Verified</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
		// 						<font size="1">
		// 							<span style={{ direction: 'rtl' }}>أوافق على الخصم من حسابي للمبلغ</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
		// 						<font size="1">DEBIT MY ACCOUNT FOR THE AMOUNT</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<font size="1">
		// 							<b>
		// 								<span style={{ direction: 'rtl' }}>رمز الموافقة</span>
		// 							</b>
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<font size="1">
		// 							<b>
		// 								<span style={{ direction: 'rtl' }}>٥٨٩١٠٠</span>
		// 							</b>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2">
		// 						<font size="1">
		// 							<b>APPROVAL CODE</b>
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }}>
		// 						<font size="1">
		// 							<b>arabic</b>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="bottom" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>١٢/١١/٢٠٢٠</span>
		// 						</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="bottom" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>١٥:١٠:١٣</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">17/10/20</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>17:29:10</span>
		// 						</font>
		// 					</td>
		// 				</tr>

		//                 <tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="22px">
		// 						<b>
		//                         <font size="2">TICKET DETAILS</font>
		//                         </b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">SEAT NAME(S)</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>H1, B1</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">BOOKING ID</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>187567465465</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">CINEMA NAME</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>OASIS MALL</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">MOVIE NAME</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>DDLJ</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		//                 <tr>
		// 					<td colspan="2" valign="top" height="25px">
		// 						<font size="2">SHOW TIME</font>
		// 					</td>
		// 					<td style={{ textAlign: 'right' }} valign="top" height="25px">
		// 						<font size="2">
		// 							<span style={{ direction: 'rtl' }}>17:29:10, 05/10/20</span>/span>
		// 						</font>
		// 					</td>
		// 				</tr>

		//                 <tr>
		// 					<td colspan="3" style={{ textAlign: 'center', padding: '1.5rem 0' }}>
		// 						<img src={ip1} height="100px" width="100px"/>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">
		// 							<span style={{ direction: 'rtl' }}>شكرًا لاستخدامكم مدى</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">THANK YOU FOR USING mada</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">
		// 							<span style={{ direction: 'rtl' }}>يرجي الاحتفاظ بالايصال</span>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">PLEASE RETAIN RECEIPT</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">
		// 							<b>
		// 								<span style={{ direction: 'rtl' }}>** نسخة العميل **</span>
		// 							</b>
		// 						</font>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }} valign="top" height="20px">
		// 						<b>
		// 							<font size="1">** CUSTOMER COPY **</font>
		// 						</b>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td colspan="3" style={{ textAlign: 'center' }}>
		// 						<font size="1">iufiouseourwoieoiwueroiwuoiuoier</font>
		// 					</td>
		// 				</tr>
		// 			</tbody>
		// 		</table>
		// 	</div>
		// </center>
	);
};

export default Receipt;
