import { combineReducers } from 'redux';
//imports multiple reducer here
import userReducer from './users/userReducer';
import moviesReducer from './movies/moviesReducer';
import bookingReducer from './booking/bookingReducer';
import foodAndBeveragesReducer from './foodAndBeverages/foodAndBeveragesReducer';

const rootReducer = combineReducers({
    //and use it here
    user: userReducer,
    movies: moviesReducer,
    booking: bookingReducer,
    foodAndBeverages: foodAndBeveragesReducer
})

export default rootReducer;