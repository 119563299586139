import React, { useEffect, useState, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { setFinalTicketDetails } from '@store/booking/bookingActions';
import MovieHeader from '@components/partials/MovieHeader';
import creditcard from '../assets/imgs/svgs/credit-card.svg';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { PaymentService } from '@apiService/PaymentService';
import BookingService from '@apiService/BookingService';
import axios from 'axios';
import { getOMAErrorCode } from '../components/partials/PosErrorCodes'
import CSpinner from '@components/partials/Spinner';
import {
	Container,
	Row,
	Col,
	Table,
	Alert,
	Modal,
	Spinner,
	Dropdown,
	Accordion,
	Card,
	Form,
} from 'react-bootstrap';
import GoBack from './partials/GoBack';


const tempResp = {
	EventName: 'TERMINAL_RESPONSE',
	JsonResult: {
		TransactionResponseEnglish: 'APPROVED',
		PrimaryAccountNumber: '476173******0010',
		TransactionAmount: '100.00',
		TransactionSTAN: '000127',
		TransactionDateTime: '191210212444',
		CardExpiryDate: '12/18',
		RetrievalReferenceNumber: '934421240035',
		TransactionAuthCode: '010000',
		TransactionResponse: '000',
		CardAcceptorTerminalId: '1234567812345678',
		CardAcceptorName: '5031|P1–SPAN|mada| ﻣﺪى',
		CardAcceptorIdCode: '650999000911000',
		AdditionalAmount: '0.00',
		BankId: 'RAJB',
		CardAcceptorBusinessCode: '3010',
		DateTime: '20191210212444|20191210212447',
		EMVTagsIssuer:
			'Visa Debit SPAN|DIPPED|A0000002282010|08C824E000|E800|40|420300|5431C3B830C48C06|2',
		TransactionType: '1',
		MerchantInfo: '7.23|mPOS||ﻧﻗطﺔاﻟﺑﯾﻊاﻟﻣﺣﻣوﻟ gniKﺔ Saud Street| ﺸﺎرعاﻟﻣﻟكﺳﻌود',
		ResponseCodeDescription: 'ﻣﻗﺑوﺔﻟ|APPROVED',
		TerminalModel: 'Spectra T1000',
		ECRReferenceNumber: '0123456789012345',
		TransactionResponseArabic: 'ﻣﻗﺑوﺔﻟ',
		TransactionTypeAsReadable: 'PURCHASE',
		TerminalVersion: '7.23',
		MerchantNameEnglish: 'mPOS',
		MerchantNameArabic: '',
		'ﻧﻗطﺔاﻟﺑﯾﻊاﻟﻣﺣﻣوﻟ gniK': 'hsilgnE1sserddAtnahcreMﺔ SaudStreet',
		MerchantAddress1Arabic: 'ﺸﺎرعاﻟﻣﻟكﺳﻌود',
		MerchantAddress2English: '',
		MerchantAddress2Arabic: '',
		TransacitonRequestDateTime: '20191210212444',
		TransactionResponseDateTime: '20191210212447',
		CardScheme: 'Visa Debit SPAN',
		POSEntryMode: 'DIPPED',
		AID: 'A0000002282010',
		TVR: '08C824E000',
		TSI: 'E800',
		CryptResult: '40',
		CVMResult: '420300',
		ApplicationCryptogram: '5431C3B830C48C06',
		CardHolderVerificationMethod: '2',
		CardHolderVerificationMethodAsReadable: 'Card Holder Verified by PIN.',
		CardCode: 'P1–SPAN',
		CardNameEnglish: 'mada',
		CardNameArabic: '',
		TransactionResultReasonArabic: 'Arabic approved',
		TransactionResultReasonEnglish: 'APPROVED',
		Event: 'OnDataReceive',
	},
};




function KioskCollectTwo({ t }) {
	const dispatch = useDispatch();
	const history = useHistory();

	// const history = useHistory();

	const onClickBack = () => {
		// history.push("/");
		window.location.href = "https://amwaj-kiosk-cinepolis-brij.binarynumbers.io";
	};

	const { arabic } = useContext(globalConfigContext);

	const [message, setMessage] = useState('');
	const [terminalAction, setTerminalAction] = useState(null);
	const [isBookingInProcess, setIsBookingInProcess] = useState(false);
	const [isBookingSuccessful, setIsBookingSuccessful] = useState(null);
	const [isPaymentDone, setIsPaymentDone] = useState(false);
	const [isConnected, setIsConnected] = useState(false);
	const [doDisconnect, setDoDisconnect] = useState(false);

	const [isProcessing, setISProcessing] = useState(false)
	const [isProcessingError, setISProcessingError] = useState(false)
	const [posConnectionRes, setPosConnectionRes] = useState(false)
	const [isPosConnected, setisPosConnected] = useState(false)
	const [errorMassage, setErrorMassage] = useState("")


	const [reservationDetais, setReservationDetails] = useState([])

	const [showSpinner, setShowSpinner] = useState(false);



	console.log(reservationDetais, "reservationDetais>>>>>>>>>>>>>>>>>>>>>>>>>>")



	console.log(terminalAction, "terminalAction >>>>>>>>>>>>>>>>>>>")
	console.log("is conect >>>>>>>>>>", isConnected)
	console.log("is payment done >>>>>>>>>>", isPaymentDone)

	const selectedTicketPrice = useSelector((state) => state.booking.selectedTicketPrice);
	const fnbPrice = useSelector((state) => state.foodAndBeverages.fnb_price);
	const modifierPrice = useSelector((state) => state.foodAndBeverages.modifier_price);
	const bookingFee = useSelector((state) => state.booking.bookingFee);
	const movie_id = useSelector((state) => state.movies.movie_details[0].altMovieContent[0].movie_id);

	console.log(movie_id, "movie_id >>>>>>>>>>>>>>")

	const reservation_id = useSelector((state) => state.booking.reservation_id);
	const email = useSelector((state) => state.booking.userDetails.email);
	const mobile = useSelector((state) => state.booking.userDetails.mobile);
	const amount =
		(selectedTicketPrice.priceInCents +
			fnbPrice.priceInCents +
			modifierPrice.priceInCents +
			bookingFee) /
		100;
	const totalFnbPrice = fnbPrice.priceInCents + modifierPrice.priceInCents;

	// const redirectToFinalTicket = () => {
	// 	history.push(`/checkout`);
	// };

	// this function is called after the payment is successful


	const onPaymentSuccessful = async (response) => {


		// OMAPaymentSuccessfull()

	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			//   history.push('/');
			window.location.href = "https://amwaj-kiosk-cinepolis-brij.binarynumbers.io/"
		}, 120000); // 60000 milliseconds = 1 minute

		return () => clearTimeout(timeout);
	}, [history]);

	useEffect(async () => {


		BookingService.GetReservationDetails(reservation_id).then((res) => {
			setReservationDetails(res.data.Records[0])

		})






		// var decodedValue = window.atob("eyJFRlREYXRhIjp7IkVycm9yQ29kZSI6IkUwMDAifX0=");

		// console.log(decodedValue, "decodedValue >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
		axios.get('https://localhost:5005/EFTTransact?ComType=Connect')
			.then((response) => {

				console.log(response, "response >>>>>>>>>>")
				setPosConnectionRes(response)

				var decodedValue = window.atob(response.data);
				let responseData = decodedValue ? JSON.parse(decodedValue) : { EFTData: null };
				const { EFTData = null } = responseData;

				if (!EFTData) {
					setISProcessingError(true)
				} else {
					if (EFTData.hasOwnProperty('ErrorCode') && EFTData['ErrorCode'] == "E000") {
						setisPosConnected(true)
						setIsConnected(true)
					} else {
						setisPosConnected(false)
						let errorMsg = getOMAErrorCode(EFTData['ErrorCode'] || null) || 'Connection Failed!'
						setErrorMassage(errorMassage)
					}
				}
			})
			.catch((err) => {
				// console.log(response, "response >>>>>>>>>>")
				console.log(err)
				setISProcessingError(true)
				// setErrorMassage(err)
			})
	}, []);

	const OMAPaymentSuccessfull = () => {
		console.log("payment is successfully done")

		console.log(reservationDetais, "reservationDetais")

		let data = JSON.stringify({
			"reservation_id": reservation_id,
			"amount": reservationDetais.amount + reservationDetais.internet_handling_fee,
			"currency": "OMR"
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: 'https://api-oman.cinepolisgulf.com/api/cinema/thirdparty-booking',
			headers: {
				'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjIxODMwYzhkLTNjOTUtNDE1Ni04YjNmLWU0ZmU2ZGQ0NWIzMSIsImlhdCI6MTcwODI3OTQ2NH0.T2_ovJUaNhv251gRqUI_KfggtaRBVrdnlcTyEsmVkeo',
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Cookie': '__cf_bm=H4ZUY4CkySvMwkmw1fTj.0XCdFCOPdmRNsmkZdm6smc-1708336543-1.0-AdzRIrEFKTPwIQ9vMqLh0EJHsJa1lNalnK+MzxHPCfbqU7Yz95FWC+wEnhh9iLHmG6zzOybtcaK8dm/1ps3I6to=; connect.sid=s%3AKV7VKxsjV5sefJuBztmwL-XQn_x6Glgb.BQ3QGrwY2P59qJq4QSzIZdqS1hf2FckL9%2Fl61taf%2BFE'
			},
			data: data
		};

		axios.request(config)
			.then((response) => {
				console.log(JSON.stringify(response.data));
				if (response.data.status == true) {
					// history.push(`/final-ticket/${response.data.booking_code}`);
					window.location.href = `https://amwaj-kiosk-cinepolis-brij.binarynumbers.io/final-ticket/${response.data.booking_code}`
				} else {
					setShowSpinner(false)
				}
			})
			.catch((error) => {
				console.log(error);

			});
		// send dynamic id here
	}

	const OMAPaymentFailed = () => {

	}

	const onPurchaseOMA = async () => {

		// OMAPaymentSuccessfull()

		setShowSpinner(true)

		setTimeout(() => {
			setShowSpinner(false)
		}, 45000)

		// let eftUrl = `https://localhost:5005/EFTTransact?ComType=Purchase&Amt=${(reservationDetais?.amount + reservationDetais?.internet_handling_fee) * 1000}&mRefValue=${reservation_id}`;
		let eftUrl = `https://localhost:5005/EFTTransact?ComType=Purchase&Amt=${(parseFloat(reservationDetais?.amount) + parseFloat(reservationDetais?.internet_handling_fee)).toFixed(2) * 1000}&mRefValue=${reservation_id}`;
		axios.get(eftUrl)
			.then((response) => {

				console.log(response, "eftUrl response >>>>>>>>>>>>>>>>>>>>>>>>>>")
				var decodedValue = window.atob(response.data);
				let responseData = decodedValue ? JSON.parse(decodedValue) : { EFTData: null };
				const { EFTData = null } = responseData;
				if (!EFTData) {
					// self.machineDisplay.processing = false;
					console.log('Transaction Failed!')
					window.location.href = "https://amwaj-kiosk-cinepolis-brij.binarynumbers.io";
				} else {
					if (EFTData && EFTData.hasOwnProperty('ErrorCode') && EFTData['ErrorCode'] == "E000" && EFTData.hasOwnProperty('ResponseCode') && EFTData['ResponseCode'] == 'APPROVED') {
						OMAPaymentSuccessfull(EFTData);
					} else {
						if (EFTData && EFTData.hasOwnProperty('ErrorCode') && EFTData['ErrorCode'] == "E000" && EFTData.hasOwnProperty('ResponseCode') && EFTData['ResponseCode'] == 'APPROVED') {
							OMAPaymentSuccessfull(EFTData);
						} else if (EFTData.hasOwnProperty('ResponseCode') && EFTData['ResponseCode'] != 'APPROVED') {
							if (EFTData.hasOwnProperty('ResponseDesc') && EFTData['ResponseDesc']) {
								let errorMsg = EFTData['ResponseDesc'] || 'Transaction Failed!'
								OMAPaymentFailed(null, errorMsg);
								window.location.href = "https://amwaj-kiosk-cinepolis-brij.binarynumbers.io";
								setShowSpinner(false)
							} else {
								let errorMsg = EFTData['ResponseCode'] || 'Transaction Failed!'
								OMAPaymentFailed(null, errorMsg);
								setShowSpinner(false)
								window.location.href = "https://amwaj-kiosk-cinepolis-brij.binarynumbers.io";
							}
						} else {
							let errorMsg = getOMAErrorCode(EFTData['ErrorCode'] || null) || 'Transaction Failed!'
							OMAPaymentFailed(null, errorMsg);
							setShowSpinner(false)
						}
					}
				}
			})

	}

	return (
		<>
			{/* {terminalAction === 'CARD_INSERTED' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'PIN_ENTRY' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'CONNECTING' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'REMOVE_CARD' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'IDLE_SCREEN' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'WAITING_RESPONSE' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : null} */}
			{/* <span>{message}</span> */}

			<MovieHeader />

			{/* {!isPaymentDone && (
				<div className="kumbh">
					<div className="middle-section">
						<h1 className="fw-bold pb-5 text-center">{t('process_payment.Processing Payment')}</h1>
						<img src={creditcard} className="img-center" />
					</div>
				</div>
			)} */}

			<hr></hr>
			{/* <div>
				errorMassage : {errorMassage ? "true" : "false"}
			</div>
			<div>
				isPosConnected : {isPosConnected ? "true" : "false"}
			</div>
			<div>
				posConnectionRes : {posConnectionRes ? "true" : "false"}
			</div>
			<div>
				isProcessingError : {isProcessingError ? "true" : "false"}
			</div>
			<div>
				isProcessing : {isProcessing ? "true" : "false"}
			</div> */}
			<hr></hr>

			{!isConnected && (
				<div className="kumbh">
					<div className="middle-section">
						<h1 className="fw-bold pb-5 text-center">
							{t('process_payment.Not Connected To Payment Terminal')}
						</h1>
					</div>
				</div>
			)}


			{/* {isConnected && (
				<div className="kumbh">
					<div className="middle-section">
						<h1 className="fw-bold pb-5 text-center">
							Terminal is connected
						</h1>
					</div>
				</div>
			)} */}

			{isConnected && (
				<>
					<div className="kumbh">
						<div className="middle-section">
							<h1 className="fw-bold pb-5 text-center">
								Terminal is connected
							</h1>


							{!isPaymentDone && (
								// <div className="kumbh">
								// 	<div className="middle-section">
								<h1 className="fw-bold pb-5 text-center">
									{t('process_payment.Please Complete the Payment With Your Card')}
								</h1>
								// 	</div>
								// </div>
							)}


							{isPaymentDone && !isBookingInProcess && isBookingSuccessful === null && (
								// <div className="kumbh">
								// 	<div className="middle-section">
								<h1 className="fw-bold pb-5 text-center">
									{/* {t('process_payment.Please Complete the Payment With Your Card')} */}
									Plseae Make Your Payment with your card
								</h1>
								// </div>
								// </div>
							)}

							{isBookingInProcess && isBookingSuccessful === null && isPaymentDone && (
								// <div className="kumbh">
								// 	<div className="middle-section">
								<>
									<h1 className="fw-bold pb-5 text-center">
										{t('process_payment.Payment Sucessful')}
									</h1>
									<h1 className="fw-bold pb-5 text-center">
										{t('process_payment.Booking In Process')}
									</h1>
								</>
								// 	</div>
								// </div>
							)}
							{isBookingSuccessful === true && (
								// <div className="kumbh">
								// 	<div className="middle-section">
								<>
									<h1 className="fw-bold pb-5 text-center">
										{t('process_payment.Payment Sucessful')}
									</h1>
									<h1 className="fw-bold pb-5 text-center">
										{t('process_payment.Booking Sucessful')}
									</h1>
								</>
								// 				 <button className="blue-btn btn-lg px-5">
								// 	{t('checkout.PRINT TICKET')}
								// </button>
								// 	</div>
								// </div>
							)}
							{isBookingSuccessful === false && (
								// <div className="kumbh">
								// 	<div className="middle-section">
								<>
									<h1 className="fw-bold pb-5 text-center">
										{t('process_payment.Payment Sucessful')}
									</h1>
									<h1 className="fw-bold pb-5 text-center">
										{t('process_payment.Booking Not Successful. Your money will be refunded')}
									</h1>
								</>
								// 	</div>
								// </div>
							)}



						</div>
					</div >



				</>
			)
			}

			<Container className='m-0 p-0'>
				{/* <div className="mx-3"><GoBack to={`/movie-details/${movie_id}`} className="" /></div> */}
				<Row className="align-items-center bgb sticky-movie-bar mb-4">
					<Col xs={12} className="seat-footer w-100 ml-3">
						<Row className="py-4">
							<Col className="d-flex justify-content-center align-items-center" md={4}>
								<div onClick={onClickBack} className="go_back">
									<div className="button_wrap">
										<div className="go_back_button"></div>
									</div>
									<h2 className="big_text">{t('GO BACK')}</h2>
								</div>
							</Col>
							<Col md={4}>
								<div className={`d-flex justify-content-center align-items-center px-3 ${arabic ? 'bl-1' : 'br-1'}`}>
									<div>
										<h3 className="f-grey">{t('seatlayout_page.Total Payable')}</h3>
										<h1 className="fw-bold">
											{/* {totalamount.toFixed(2)}{' '}OMR */}
											{
												reservationDetais?.amount
													? <>{parseFloat(reservationDetais?.amount + reservationDetais?.internet_handling_fee).toFixed(2)} OMR</>
													: <>0 OMR</>


											}
											{/* {arabic
														? currency.currency_short_code_ar + 'heyyyy'
														: currency.currency_short_code} */}
										</h1>
										{/* {selectedTicketPrice.priceInCents === 0 ? (
													<h1 className="fw-bold">
														{totalamount}{' '}
														{arabic
															? currency.currency_short_code_ar + 'heyyyy'
															: currency.currency_short_code}
													</h1>
												) : (
													<h1 className="fw-bold">
														{(
															(selectedTicketPrice.priceInCents + bookingFee) /
															currency.divided_by
														).toFixed(2)}{' '}
														{arabic
															? currency.currency_short_code_ar
															: currency.currency_short_code}
													</h1>
												)} */}
										<h5>
											{/* {t('fnb_page.taxes')}:{' '}{taxAmount.toFixed(2)} OMR */}
											{/* {t('fnb_page.taxes')}:{' '}2 OMR */}
											{/* {(
														(selectedTicketPrice.taxInCents +
															(selectedTicketPrice.priceInCents === 0 ? 0 : bookingFeeTax)) /
														currency.divided_by
													).toFixed(2)}{' '} */}
											{/* {arabic ? currency.currency_short_code_ar : currency.currency_short_code} */}
										</h5>
									</div>
									{/* <div className={`flex-center ${arabic ? 'pr-4' : 'pl-4'}`}>
										
										<h6 className="custom-label">Total Bill</h6>
									</div> */}
								</div>
							</Col>

							{/* <div onClick={() => onPurchaseOMA()}>HIII</div> */}
							<Col md={4} className="d-flex w-100 m-0 p-0 justify-content-center align-items-center">
								<span
									className={`blue-btn btn-lg px-5
									 ${!isConnected
											? 'inactive-btn'
											: ''
										}`}

									onClick={() =>
										isConnected
											? onPurchaseOMA()
											: null
									}


								// onClick={() => { onPurchaseOMA() }}

								>
									{/* {showProceedSpinner ? (
										<Spinner animation="border" role="status" className="spinner">
											<span className="sr-only">Loading...</span>
										</Spinner>
									) : (
										t('common.PROCEED')
									)} */}
									{/* {t('common.PROCEED')} */}
									PAY
								</span>
							</Col>
						</Row>
					</Col>
				</Row >
			</Container >

			<CSpinner active={showSpinner} />



			{/* <div onClick={() => onPurchaseOMA()} className="kumbh">
				<div className="middle-section">
					<h1 className="fw-bold pb-5 text-center">
						Proceed
					</h1>

				</div>
			</div> */}
		</>
	);
}

export default withNamespaces()(KioskCollectTwo);
