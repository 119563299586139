import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';

const selectedFnbItemsSelector = (state) => state.foodAndBeverages.selected_fnb_items;

export const modifiersForSelectedFnbItems = createSelector(
	selectedFnbItemsSelector,
	(selectedFnbItems) => {
		let concessionModifiersMapping = {};
		for (let item of selectedFnbItems) {
			concessionModifiersMapping[item.Id] = [];
			for (let modifierArr of item.Modifiers) {
				for (let modifier of modifierArr) {
					let modifierIndex = concessionModifiersMapping[item.Id].findIndex(
						(x) => x.Id === modifier.Id
					);
					if (modifierIndex !== -1) {
						concessionModifiersMapping[item.Id][modifierIndex].Quantity += modifier.Quantity;
						concessionModifiersMapping[item.Id][modifierIndex].priceInCents +=
							modifier.priceInCents;
						concessionModifiersMapping[item.Id][modifierIndex].taxesInCents += modifier.taxInCents;
					} else {
						let modCopy = cloneDeep(modifier);
						concessionModifiersMapping[item.Id].push(modCopy);
					}
				}
			}
		}

		return concessionModifiersMapping;
	}
);
