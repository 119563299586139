import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNowPlayingMovies } from '@store/movies/moviesActions';
import { useHistory } from 'react-router-dom';
import { timeConvert, isImageURLNull } from '@helper/formatting.js';
import MovieHeader from '@components/partials/MovieHeader';
import GoBack from '@components/partials/GoBack';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import { Row, Container, Col, Modal } from 'react-bootstrap';
import CSpinner from '@components/partials/Spinner';
import MovieService from "../ApiServices/MovieService"
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import { setselectedmovie } from '../store/movies/moviesActions';

function Movies({ t }) {

	return (
		<div className="movie_listing_container">

			<MovieHeader t={t} />
			<MovieListings t={t} />
		</div>
	);
}

const MovieListings = ({ t }) => {
	const { arabic, setArabic } = useContext(globalConfigContext);
	const history = useHistory();
	const dispatch = useDispatch();
	const [showSpinner, setShowSpinner] = useState(false);
	// const [movie,setmovie]= useState([])

	const currentLocation = window.location.href
	// console.log(currentLocation, "currentLocationcurrentLocationcurrentLocationcurrentLocationcurrentLocationcurrentLocation")

	useEffect(() => {
		setShowSpinner(true);
		console.log("Current Location:", currentLocation);
		const fetchData = async () => {
			console.log("hey1")
			try {
				const response = await MovieService.GetNowShowingMovies();
				const { data } = response;
				dispatch(fetchNowPlayingMovies(data.Records.data))
				// setmovie(data.Records.data);
				setShowSpinner(false);
			} catch (err) {
				console.error("Error fetching now playing movies:", err);
				setShowSpinner(false);
			}
		};
		fetchData();

	}, []);

	const movie = useSelector((state) => state.movies.now_playing_movies);
	console.log(movie.length, "lll>>")
	return (
		<div>
			<CSpinner active={showSpinner} />
			{/* <div className="mx-3"><GoBack to="prev" className="" /></div> */}
			{/* <GoBack to="prev" /> */}
			<Container fluid>
				<div>
					<div className="movie_listings">
						{/* <GoBack to="prev" /> */}
						<Row>
							{movie.length > 0 ? (
								movie
									// .filter((item)=>{
									// 	let splitedItem = item?.SalesChannels.split(";") 
									// 	// console.log(splitedItem.includes('KIOSK'), "PPPPPPPPPPPPPPPPPPPPPPPPPPPPP")

									// 	if(splitedItem.includes('KIOSK') == true){
									// 	return true
									// 	}
									// })
									.map((m) => <MovieCard m={m} t={t} />)
							) : (
								<div className="go_back">
									<h2 className="big_text">{t('movie_listing.No Movies Found') + ""}</h2>
								</div>
							)}
						</Row>

					</div>
				</div>
				<div className="gbbottom position-sticky"><GoBack to="prev" className="" /></div>
			</Container>
		</div>
	);
};
const MovieCard = ({ m, t }) => {
	const { arabic, currency } = useContext(globalConfigContext);
	const history = useHistory();
	const dispatch = useDispatch()

	useEffect(() => {
		const timeout = setTimeout(() => {
			history.push('/');
		}, 60000); // 60000 milliseconds = 1 minute

		return () => clearTimeout(timeout);
	}, [history]);


	const onClickBookTickets = (m, Id) => {
		dispatch(setselectedmovie(m))
		history.push('/movie-details/' + Id);
	};




	return (
		// <>nidhi</>
		<>
			{/* <div className="gb"><GoBack className="" /></div> */}



			<Col xs={4} className="mt-4 mb-3 ml-col">
				<div onClick={() => onClickBookTickets(m, m?.movie_id)} className="card_container">
					<div className="movie_pic">
						<img src={!isImageURLNull(m?.altMovieContent[0]?.artwork) ? m?.altMovieContent[0]?.artwork : movieDef} />
					</div>
					<div className="movie_info">
						{/* <div className="movie_name">{ m?.altMovieContent[0]?.mc_title}</div>
					<div className="right_side">
						<div className="right_top">
							{m?.rating} | {Math.floor(movie?.mrrdr_runtime / 60)}h{" "}{movie?.mrrdr_runtime % 60}m
						</div>
						<div className="right_bot">English, Arabic</div> 
					</div> */}
						<Col xs={6} className="px-2">
							<span className="text-limit v3 fw-bold frs-16">
								{arabic ? m.movie_title_ar : m?.altMovieContent[0]?.mc_title}
							</span>
							{/* For Language? */}
							{/* <span className="kumbh fw-bold frxl-14 frl-12 frm-10 frs-8 text-truncate">{m?.lang_name}</span> */}
						</Col>
						<Col xs={6} className="px-2 d-flex justify-content-end align-items-end">
							<div className="group px-2">
								<span className={`${arabic ? 'border-left pl-1' : 'border-right pr-1'}`}>
									{m.rating}{' '}
								</span>
								<span className={`${arabic ? 'pr-1' : 'pl-1'}`}> {Math.floor(m?.mrrdr_runtime / 60)}h{" "}{m?.mrrdr_runtime % 60}m</span>
							</div>
						</Col>
					</div>
					<p onClick={() => onClickBookTickets(m, m?.movie_id)} className="blue-btn w-100 frs-16">
						{t('movie_listing.BOOK TICKETS')}
					</p>
				</div>
			</Col>
		</>
	);
};
export default withNamespaces()(Movies);
