import {
	FETCH_SEAT_LAYOUT_LOADING,
	FETCH_SEAT_LAYOUT_SUCCESS,
	FETCH_SEAT_LAYOUT_ERROR,
	FETCH_SHOW_DETAILS_SUCCESS,
	SET_SEAT_SELECTED,
	SET_OFFER_SEAT_SELECTED,
	SET_RESERVATION_ID,
	SET_SEAT_SELECT_ERROR,
	SET_TRANSACTION_ID,
	SET_TRANSACTION_ERROR,
	CLEAR_BOOKING_STATE,
	SET_TICKET_PRICE,
	RESET_RESERVATION_ID,
	SET_GUEST_ID,
	START_TIMER,
	STOP_TIMER,
	SET_BOOKING_FEE_DATA,
	SET_ADDITIONAL_DATA,
	CLEAR_ERRORS,
	CLEAR_SELECTED_SEATS,
	CLEAR_TIMEOUT_POPUP,
	SET_VOUCHER_CODE_SUCCESS,
	SET_VOUCHER_CODE_ERROR,
	SET_SESSION_DETAILS,
	SET_TICKET_DATA,
	SET_USER_DETAILS,
	SET_FINAL_TICKET_DETAILS,
	SET_RESERVATION_DATA
} from './bookingTypes';
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';

const initialState = {
	seat_layout_loading: false,
	original_seat_layout: [],
	seat_layout: [],
	seat_layout_error: null,
	show_details: [],
	seats_selected: [],
	reservation_data: [],
	vista_seats_selected: [],
	reservation_id: null,
	guest_id: null,
	seat_select_error: null,
	transaction_id: null,
	transaction_error: null,
	ticketPrice: null,
	selectedTicketPrice: {
		priceInCents: 0,
		taxInCents: 0,
		totalWithFnb: 0,
		originalPriceInCents: 0,
		originalTaxInCents: 0,
		originalTotalWithFnb: 0,
	},
	bookingFee: 0,
	bookingFeeTax: 0,
	timer: {
		minutes: 8,
		seconds: 59,
	},
	showTimeoutPopup: false,
	additionalData: {},
	voucherCodeData: null,
	voucherCodeError: null,
	session_id: null,
	cinema_id: null,
	ticketDate: null,
	userDetails: {
		email: '',
		mobile: '',
	},
	taxRate: 0,
	finalTicketDetails: null,
};

const bookingReducer = (state = initialState, action) => {
	switch (action.type) {
		case RESET_RESERVATION_ID:
			return {
				...state,
				reservation_id: null,
			};
		case FETCH_SEAT_LAYOUT_LOADING:
			return {
				...state,
				seat_layout_loading: true,
			};
		case SET_RESERVATION_DATA:
			return {
				...state,
				reservation_data: action.payload,
			};
		case FETCH_SEAT_LAYOUT_SUCCESS:
			return {
				...state,
				seat_layout_loading: false,
				seat_layout: action.payload,
				original_seat_layout: cloneDeep(action.payload),
				seat_layout_error: null,
				selectedTicketPrice: {
					priceInCents: 0,
					taxInCents: 0,
					totalWithFnb: 0,
					originalPriceInCents: 0,
					originalTaxInCents: 0,
					originalTotalWithFnb: 0,
				},
			};
		case FETCH_SEAT_LAYOUT_ERROR:
			return {
				...state,
				seat_layout_loading: false,
				seat_layout: [],
				original_seat_layout: [],
				seat_layout_error: action.payload,
				selectedTicketPrice: {
					priceInCents: 0,
					taxInCents: 0,
					totalWithFnb: 0,
					originalPriceInCents: 0,
					originalTaxInCents: 0,
					originalTotalWithFnb: 0,
				},
			};
		case FETCH_SHOW_DETAILS_SUCCESS:
			return {
				...state,
				show_details: action.payload,
				taxRate:
					action.payload[0] &&
						(action.payload[0].tax_percent !== null || action.payload[0].tax_percent !== undefined)
						? action.payload[0].tax_percent / 100
						: 0,
			};
		case SET_SEAT_SELECTED:
			let seatTypeIndex = action.payload.seatTypeIndex;
			let rowIndex = action.payload.rowIndex;
			let seatIndex = action.payload.seatIndex;
			let Position = action.payload.Position;
			let singleSeat = action.payload.singleSeat;
			let adjacentSeatPosition = action.payload.adjacentSeatPosition;

			let isCoupleSeat =
				state.seat_layout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].coupleSeat;

			Position.TicketTypeCode = state.seat_layout[seatTypeIndex].TicketTypeCode;
			Position.isVoucher = false;
			Position.isBogo = false;
			Position.priceInCents = 0;
			Position.coupleSeat = isCoupleSeat;

			if (adjacentSeatPosition) {
				adjacentSeatPosition.TicketTypeCode = state.seat_layout[seatTypeIndex].TicketTypeCode;
				adjacentSeatPosition.isVoucher = false;
				adjacentSeatPosition.isBogo = false;
				adjacentSeatPosition.priceInCents = 0;
				adjacentSeatPosition.coupleSeat = isCoupleSeat;
			}

			const newSeatLayout = [...state.seat_layout];
			let selectedTicketPrice = Object.assign({}, state.selectedTicketPrice);

			let ticketTypeCodeIndex = null;

			if (state.voucherCodeData) {
				ticketTypeCodeIndex = state.voucherCodeData.ticketTypes.findIndex((ticketType) => {
					return newSeatLayout[seatTypeIndex].TicketTypeCodeBarcode.includes(
						ticketType.ticketTypeCode
					);
				});
			}

			const priceWithTax = newSeatLayout[seatTypeIndex].PriceInCents;
			const priceWithoutTax = parseFloat((priceWithTax / (1 + state.taxRate)).toFixed(2));
			const tax = parseFloat((priceWithTax - priceWithoutTax).toFixed(2));

			let voucherPriceWithTax;
			let voucherPriceWithoutTax;
			let voucherTax;

			if (ticketTypeCodeIndex !== null && ticketTypeCodeIndex !== -1) {
				const targetTicketTypeCode =
					state.voucherCodeData.ticketTypes[ticketTypeCodeIndex].ticketTypeCode;
				voucherPriceWithTax =
					newSeatLayout[seatTypeIndex].voucherCodePrices[targetTicketTypeCode].PriceInCents;
				voucherPriceWithoutTax = parseFloat((voucherPriceWithTax / (1 + state.taxRate)).toFixed(2));
				voucherTax = parseFloat((voucherPriceWithTax - voucherPriceWithoutTax).toFixed(2));
				console.log(voucherTax);
			}

			if (
				newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].hasOwnProperty(
					'SeatSelected'
				) &&
				newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].SeatSelected
			) {
				newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex]['SeatSelected'] = false;

				if (
					state.voucherCodeData &&
					ticketTypeCodeIndex !== null &&
					ticketTypeCodeIndex !== -1 &&
					((state.voucherCodeData.isBogo &&
						state.seats_selected.length <=
						state.voucherCodeData.numberOfRedemptionsRemaining * 2) ||
						(!state.voucherCodeData.isBogo &&
							state.seats_selected.length <= state.voucherCodeData.numberOfRedemptionsRemaining))
				) {
					if (
						!state.voucherCodeData.isBogo ||
						(state.voucherCodeData.isBogo && state.seats_selected.length % 2 === 1)
					) {
						if (selectedTicketPrice.priceInCents !== 0) {
							selectedTicketPrice.priceInCents -= voucherPriceWithTax;
						}

						if (selectedTicketPrice.totalWithFnb !== 0) {
							selectedTicketPrice.totalWithFnb -= voucherPriceWithTax;
						}

						if (selectedTicketPrice.taxInCents !== 0) {
							selectedTicketPrice.taxInCents -= voucherTax;
						}
					}
				} else {
					if (selectedTicketPrice.priceInCents !== 0) {
						selectedTicketPrice.priceInCents -= priceWithTax;
					}

					if (selectedTicketPrice.totalWithFnb !== 0) {
						selectedTicketPrice.totalWithFnb -= priceWithTax;
					}

					if (selectedTicketPrice.taxInCents !== 0) {
						selectedTicketPrice.taxInCents -= tax;
					}
				}

				if (selectedTicketPrice.originalPriceInCents !== 0) {
					selectedTicketPrice.originalPriceInCents -= priceWithTax;
				}

				if (selectedTicketPrice.originalTotalWithFnb !== 0) {
					selectedTicketPrice.originalTotalWithFnb -= priceWithTax;
				}

				if (selectedTicketPrice.originalTaxInCents !== 0) {
					selectedTicketPrice.originalTaxInCents = round(
						selectedTicketPrice.originalTaxInCents - tax,
						2
					);
				}
			} else {
				if (state.seats_selected.length === 6) {
					return state;
				}

				// if (state.voucherCodeData) {
				// 	if (
				// 		(state.voucherCodeData.isBogo &&
				// 			state.seats_selected.length ===
				// 				state.voucherCodeData.numberOfRedemptionsRemaining * 2) ||
				// 		(!state.voucherCodeData.isBogo &&
				// 			state.seats_selected.length === state.voucherCodeData.numberOfRedemptionsRemaining)
				// 	) {
				// 		return state;
				// 	}
				// }

				if (ticketTypeCodeIndex !== null && ticketTypeCodeIndex !== -1) {
					if (state.voucherCodeData.isBogo) {
						if (
							state.seats_selected.length <
							state.voucherCodeData.numberOfRedemptionsRemaining * 2
						) {
							Position.isVoucher = true;
							Position.isBogo = true;
						}
					} else {
						if (state.seats_selected.length < state.voucherCodeData.numberOfRedemptionsRemaining) {
							Position.isVoucher = true;
						}
					}
				}

				newSeatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex]['SeatSelected'] = true;

				if (
					ticketTypeCodeIndex !== null &&
					ticketTypeCodeIndex !== -1 &&
					((state.voucherCodeData.isBogo &&
						state.seats_selected.length < state.voucherCodeData.numberOfRedemptionsRemaining * 2) ||
						(!state.voucherCodeData.isBogo &&
							state.seats_selected.length < state.voucherCodeData.numberOfRedemptionsRemaining))
				) {
					if (
						!state.voucherCodeData.isBogo ||
						(state.voucherCodeData.isBogo && state.seats_selected.length % 2 === 0)
					) {
						selectedTicketPrice.totalWithFnb += voucherPriceWithTax;
						selectedTicketPrice.priceInCents += voucherPriceWithTax;
						selectedTicketPrice.taxInCents += voucherTax;
						Position.priceInCents = voucherPriceWithTax;
					}
				} else {
					selectedTicketPrice.totalWithFnb += priceWithTax;
					selectedTicketPrice.priceInCents += priceWithTax;
					selectedTicketPrice.taxInCents += tax;
					Position.priceInCents = priceWithTax;
				}

				selectedTicketPrice.originalTotalWithFnb += priceWithTax;
				selectedTicketPrice.originalPriceInCents += priceWithTax;
				selectedTicketPrice.originalTaxInCents = round(
					selectedTicketPrice.originalTaxInCents + tax,
					2
				);

				selectedTicketPrice.TicketTypeCode = newSeatLayout[seatTypeIndex].TicketTypeCode;

				// newSeatLayout[seatTypeIndex].PriceInCents
				// newSeatLayout[seatTypeIndex].TaxInCents
			}
			let seatName2 = '';
			let seatName =
				state.seat_layout[seatTypeIndex].Rows[rowIndex].PhysicalName +
				'-' +
				state.seat_layout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].Id;
			if (singleSeat.coupleSeat) {
				seatName2 =
					state.seat_layout[seatTypeIndex].Rows[rowIndex].PhysicalName +
					'-' +
					state.seat_layout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex + 1].Id;
			}
			const newSeats = [...state.seats_selected];
			const newVistaSeats = [...state.vista_seats_selected];
			if (newSeats.indexOf(seatName) > -1) {
				newSeats.splice(newSeats.indexOf(seatName), 1);
				newVistaSeats.splice(newVistaSeats.indexOf(Position), 1);
				if (singleSeat.coupleSeat) {
					newSeats.splice(newSeats.indexOf(seatName2), 1);
					newVistaSeats.splice(newVistaSeats.indexOf(adjacentSeatPosition), 1);
				}
			} else {
				newSeats.push(seatName);
				newVistaSeats.push(Position);
				if (singleSeat.coupleSeat) {
					newSeats.push(seatName2);
					newVistaSeats.push(adjacentSeatPosition);
				}
			}
			return {
				...state,
				selectedTicketPrice: selectedTicketPrice,
				seat_layout: newSeatLayout,
				seats_selected: newSeats,
				vista_seats_selected: newVistaSeats,
				seat_select_error: null,
			};


		case SET_OFFER_SEAT_SELECTED:
			if (state.selectedBankOfferTicket || state.voucherCodeData) {
				return false;
			}

			let userSelectedOffer = action.payload.selectedCategoryOffer;
			let bogofree = 0;
			let bogoprice = 0;

			console.log(
				state.selectedTicketPrice.priceInCents,
				"state.selectedTicketPrice.priceInCents"
			);
			userSelectedOffer.map((item, index) => {
				bogofree = 0;
				state.vista_seats_selected.map((item1, index1) => {
					if (
						item1.AreaCategoryCode == item.AreaCategoryCode &&
						item.PackageContent &&
						item.PackageContent.Tickets &&
						item.PackageContent.Tickets.length > 1
					) {
						if (index1 % 2 === 0) {
							item1.TicketTypeCode = item.TicketTypeCode;
							state.vista_seats_selected[index1].priceInCents =
								item.PriceInCents;
							item1.isBogo = true;
						} else {
							item1.TicketTypeCode = item.TicketTypeCode;
							state.vista_seats_selected[index1].priceInCents = 0;
							item1.isBogo = true;
						}
					}

					if (
						item1.AreaCategoryCode == item.AreaCategoryCode &&
						item.PackageContent &&
						item.PackageContent.Tickets &&
						item.PackageContent.Tickets.length <= 1
					) {
						if (item1.coupleSeat == true) {
							if (item1.coupleSeat == true && item1.priceInCents == 0) {
								// console.log(
								//   (item[index + 1].TicketTypeCode = type.TicketTypeCode),
								//   "couple"
								// );
								item1.priceInCents = 0;
								item1.TicketTypeCode = item.TicketTypeCode;
							} else {
								item1.TicketTypeCode = item.TicketTypeCode;
								item1.priceInCents = item.PriceInCents;
							}
						} else {
							item1.TicketTypeCode = item.TicketTypeCode;
							item1.priceInCents = item.PriceInCents;
						}
						item1.isBogo = false;
						//alert();
						// state.selectedTicketPrice.priceInCents -= item1.priceInCents;
						// state.selectedTicketPrice.originalPriceInCents -=
						//   item1.priceInCents;
						// state.selectedTicketPrice.totalWithFnb -= item1.priceInCents;
						// state.selectedTicketPrice.originalTotalWithFnb -=
						//   item1.priceInCents;
						// state.selectedTicketPrice.taxInCents -= parseFloat(
						//   (
						//     item1.priceInCents -
						//     parseFloat(
						//       (item1.priceInCents / (1 + state.taxRate)).toFixed(2)
						//     )
						//   ).toFixed(2)
						// );
					}
					// else {
					//             console.log("not match");
					//             item1.TicketTypeCode = item1.TicketTypeCode;
					//             item1.priceInCents = item1.priceInCents;
					//             state.selectedTicketPrice.priceInCents += item1.priceInCents;
					//             state.selectedTicketPrice.originalPriceInCents +=
					//               item1.priceInCents;
					//             state.selectedTicketPrice.totalWithFnb += item1.priceInCents;
					//             state.selectedTicketPrice.originalTotalWithFnb +=
					//               item1.priceInCents;
					//           }
					// if (item1.isBogo == true && item1.priceInCents == 0) {
					//   bogofree += 1;
					// }
					// if (item1.isBogo == true && item1.priceInCents != 0) {
					//   bogoprice += 1;
					// }
				});
			});

			state.selectedTicketPrice.priceInCents = 0;
			state.selectedTicketPrice.originalPriceInCents = 0;
			state.selectedTicketPrice.totalWithFnb = 0;
			state.selectedTicketPrice.originalTotalWithFnb = 0;
			state.selectedTicketPrice.taxInCents = 0;
			state.vista_seats_selected.map((item, index) => {
				if (item.isBogo == true) {
					let bogoNotMatch = state.vista_seats_selected.filter((z) => {
						return z.TicketTypeCode == item.TicketTypeCode;
					});

					if (
						bogoNotMatch.length > 0 &&
						bogoNotMatch.length != 2 &&
						bogoNotMatch.length != 4 &&
						bogoNotMatch.length != 6 &&
						bogoNotMatch.length != 8 &&
						bogoNotMatch.length != 10 &&
						bogoNotMatch.length != 12 &&
						bogoNotMatch.length != 14
					) {
						bogofree += 1;
					}
				}
				state.selectedTicketPrice.priceInCents += item.priceInCents;
				state.selectedTicketPrice.originalPriceInCents += item.priceInCents;
				state.selectedTicketPrice.totalWithFnb += item.priceInCents;
				state.selectedTicketPrice.originalTotalWithFnb += item.priceInCents;
				state.selectedTicketPrice.taxInCents += parseFloat(
					(
						item.priceInCents -
						parseFloat((item.priceInCents / (1 + state.taxRate)).toFixed(2))
					).toFixed(2)
				);
			});

			state.selectedTicketPrice.originalTaxInCents =
				state.selectedTicketPrice.taxInCents;
			if (bogofree > 0) {
				state.bogoOfferSeatNotMatch = true;
			} else {
				state.bogoOfferSeatNotMatch = false;
			}
			console.log(
				bogoprice,
				"bbb jitu",
				bogofree,
				"oo",
				state.selectedTicketPrice
			);
			return {
				...state,
				cinepolisOffers: userSelectedOffer,
			};

		case SET_RESERVATION_ID:
			return {
				...state,
				reservation_id: action.payload,
			};
		case SET_SEAT_SELECT_ERROR:
			return {
				...state,
				seat_select_error: action.payload,
			};
		case SET_TRANSACTION_ID:
			return {
				...state,
				transaction_id: action.payload,
				transaction_error: null,
			};
		case SET_TRANSACTION_ERROR:
			return {
				...state,
				transaction_id: null,
				transaction_error: action.payload,
			};
		case CLEAR_BOOKING_STATE:
			return {
				...state,
				seat_layout_loading: false,
				seat_layout: [],
				original_seat_layout: [],
				seat_layout_error: null,
				vista_seats_selected: [],
				reservation_id: null,
				seat_select_error: null,
				seats_selected: [],
				transaction_error: null,
				selectedTicketPrice: {
					priceInCents: 0,
					taxInCents: 0,
					totalWithFnb: 0,
					originalPriceInCents: 0,
					originalTaxInCents: 0,
					originalTotalWithFnb: 0,
				},
				userDetails: {
					email: '',
					mobile: '',
				},
			};
		case SET_TICKET_PRICE:
			return {
				...state,
				ticketPrice: action.payload,
			};
		case SET_GUEST_ID:
			return {
				...state,
				guest_id: action.payload,
			};
		case START_TIMER:
			let seconds = state.timer.seconds - 1;
			let minutes = state.timer.minutes;
			let showPopup = false;
			if (seconds == 0) {
				if (minutes == 0) {
					seconds = 0;
					minutes = 0;
					showPopup = true;
				}
				seconds = 59;
				minutes = minutes - 1;
			}
			var timer = Object.assign({}, state.timer);
			timer = {
				minutes: minutes,
				seconds: seconds,
			};
			return {
				...state,
				timer: timer,
				showTimeoutPopup: showPopup,
			};
		case STOP_TIMER:
			var _timer = Object.assign({}, state.timer);
			_timer = {
				minutes: 8,
				seconds: 59,
			};
			return {
				...state,
				timer: _timer,
			};
		case SET_BOOKING_FEE_DATA:
			const bookingFee = action.payload;
			console.log(action.payload, "PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP")
			const bookingFeeTax = round(bookingFee - bookingFee / (1 + state.taxRate), 2);
			return {
				...state,
				bookingFee,
				bookingFeeTax,
			};
		case SET_ADDITIONAL_DATA:
			return {
				...state,
				additionalData: {
					...state.additionalData,
					...action.payload,
				},
			};
		case SET_SESSION_DETAILS:
			return {
				...state,
				session_id: action.payload.session_id,
				cinema_id: action.payload.cinema_id,
			};
		case CLEAR_ERRORS:
			return {
				...state,
				seat_layout_error: null,
			};
		case CLEAR_SELECTED_SEATS:
			return {
				...state,
				seats_selected: [],
				vista_seats_selected: [],
				voucherCodeData: null,
				voucherCodeError: null,
				session_id: null,
				cinema_id: null,
				finalTicketDetails: null,
			};
		case CLEAR_TIMEOUT_POPUP:
			return {
				...state,
				showTimeoutPopup: false,
			};
		case SET_VOUCHER_CODE_SUCCESS:
			const voucher_data = action.payload;
			let isBogo = false;
			if (voucher_data.ticketTypes.length > 0) {
				voucher_data.ticketTypes.forEach((type) => {
					let ticketPriceObj = state.ticketPrice
						? state.ticketPrice.Tickets && state.ticketPrice.Tickets.length > 0
							? state.ticketPrice.Tickets.find((t) => t.TicketTypeCode === type.ticketTypeCode)
							: null
						: null;

					if (
						ticketPriceObj &&
						ticketPriceObj.PackageContent &&
						ticketPriceObj.PackageContent.Tickets &&
						ticketPriceObj.PackageContent.Tickets.length === 2
					) {
						isBogo = true;
					}
				});
			}

			voucher_data.isBogo = isBogo;

			return {
				...state,
				voucherCodeData: voucher_data,
				voucherCodeError: null,
				seats_selected: [],
				vista_seats_selected: [],
				seat_layout: cloneDeep(state.original_seat_layout),
				selectedTicketPrice: {
					priceInCents: 0,
					taxInCents: 0,
					totalWithFnb: 0,
					originalPriceInCents: 0,
					originalTaxInCents: 0,
					originalTotalWithFnb: 0,
				},
			};
		case SET_VOUCHER_CODE_ERROR:
			return {
				...state,
				voucherCodeData: null,
				voucherCodeError: action.payload,
			};
		case SET_TICKET_DATA:
			return {
				...state,
				ticketData: action.payload,
			};
		case SET_USER_DETAILS:
			return {
				...state,
				userDetails: {
					email: action.payload.email,
					mobile: action.payload.mobile,
				},
			};
		case SET_FINAL_TICKET_DETAILS:
			return {
				...state,
				finalTicketDetails: action.payload,
			};
		default:
			return state;
	}
};

export default bookingReducer;
