import React from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { Row, Container, Col} from 'react-bootstrap';

const GoBack = ({ t, to }) => {
	const history = useHistory();

	const onClickBack = () => {
		if (to === 'prev') {
			history.go(-1);
			// window.location.reload()
		} else {
			history.push(to ? to : '/');
			// window.location.reload()
		}
	};

	return (
		<div className="w-100 ">
			<div onClick={onClickBack} className="go_back py-4">
			<div className="button_wrap">
				<div className="go_back_button"></div>
			</div>
			<h2 className="big_text">{t('GO BACK')}</h2>
		</div>
		</div>
	);
};

export default withNamespaces()(GoBack);
