import Api from './Api';
import { createQueryString } from './ApiHelper';

export default {
	// GetSeatLayout(payload) {
	// 	return Api().get(`booking/seat-layout/${payload.session_id}/${payload.cinema_id}`);
	// },
	GetSeatLayout(payload) {
		return Api().post(`external/seat-layout`, payload);
	},

	GetReservationDetails(reservation_id) {
		return Api().get(`external/reservation-detail/${reservation_id}`);
	},

	releseSeats(payload) {
		return Api().post(`external/release-seat`, payload);
	},

	SelectSeats(payload) {
		return Api().post(`booking/select-seats-kiosk`, payload);
	},
	BookSeats(payload) {
		return Api().post(`booking/book-seats-kiosk`, payload);
	},
	GetBookingDetails(payload) {
		return Api().get(`booking/booking-details/${payload.reservation_id}`);
	},
	GetTicketPrice(payload) {
		return Api().get(`booking/ticket-price/${payload.session_id}/${payload.cinema_id}`);
	},
	CancelTransaction(payload) {
		return Api().get(`booking/cancel-transaction/${payload.reservation_id}`);
	},
	SelectFnbItems(payload) {
		return Api().post(`booking/select-fnb`, payload);
	},
	ValidateVoucher(payload) {
		return Api().post(`booking/validate-voucher`, payload);
	},
	GetTicketDetails(payload) {
		let query = createQueryString(payload);
		return Api().get(`booking/kiosk-ticket-details/${query}`);
	},
	CheckingTest(payload) {
		return Api().post(`cinema/reserve-seat`, payload);
	},
	CheckingTest1(payload) {
		return Api().get(`external/reservation-detail/${payload}`);
	},
	FinalTicket(payload) {
		return Api().get(`cinema/booking-details/${payload}`);
	},
	SelectVoucherAndReservation(payload) {
		return Api().post(`external/save-voucher?action=ResetVoucherAndOfferInReservation`, { "reservation_id": payload });
	},
};
