import React, { Component, useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Alert, Spinner, Dropdown } from 'react-bootstrap';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { formatYuppErrors } from '@helper/formatting';
import MovieHeader from '@components/partials/MovieHeader';
import BookingService from '@apiService/BookingService';
import { withNamespaces } from 'react-i18next';
import { setTicketData } from '@store/booking/bookingActions';
import GoBack from '@components/partials/GoBack';
import useCountries from '../customHooks/useCountries';

function KioskCollectLoginPage({ t }) {
	return (
		<div className="">
			<MovieHeader />
			<Container fluid>
				<GoBack to="/book-show" />
				<LoginForm t={t} />
			</Container>
		</div>
	);
}

const LoginForm = ({ t }) => {
	const { arabic, currency } = useContext(globalConfigContext);

	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [countryCode, setCountryCode] = useState(null);
	const countries = useCountries(setCountryCode);
	const [errors, setErrors] = useState('null');
	const [showNoTicketError, setShowNoTicketError] = useState(false);
	const [showProceedSpinner, setShowProceedSpinner] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const schema = yup.object().shape({
		email: yup.string().email().required(),
		mobile: yup
			.string()
			.matches(/^\d{9,9}$/)
			.required(),
		countryCode: yup.string().required(),
	});

	const onSubmit = () => {
		setErrors(null);
		schema
			.validate(
				{
					email,
					mobile,
					countryCode,
				},
				{ abortEarly: false }
			)
			.then(async (valid) => {
				setShowProceedSpinner(true);
				try {
					const response = await BookingService.GetTicketDetails({
						email,
						mobile: `${countryCode}${mobile}`,
					});

					if (response && response.status) {
						dispatch(setTicketData(response.data.data));
						history.push('/kiosk-collect-2');
					}
				} catch (err) {
					setShowNoTicketError(true);
				}
				setShowProceedSpinner(false);
			})
			.catch((errs) => {
				console.log('yup errors', errs);
				setErrors(formatYuppErrors(errs));
			});
	};

	return (
		<div className="login_form">
			{showNoTicketError && (
				<Row className="mb-3 d-none d-md-flex sticky-alert-top">
					<Col>
						<Alert variant="danger" onClose={() => setShowNoTicketError(false)} dismissible>
							<Alert.Heading>{t('common.imp_msg')}</Alert.Heading>
							<p>{t('collect_login.No Ticket Details Found!')}</p>
						</Alert>
					</Col>
				</Row>
			)}
			<Row className="bb-1">
				<Col xs={6} className="mx-auto">
					<p className="msg">{t('collect_login.Enter your details to collect the ticket')}</p>
				</Col>
			</Row>
			<Row className="input-wrapper">
				<Col className="d-flex flex-column align-items-center">
					<input
						className={`kiosk-input ${errors && errors.email ? 'error' : ''}`}
						type="email"
						placeholder={t('collect_login.placeholders.email')}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<p className="error-p">
						{errors && errors.email && (
							<span className="form-error-text d-block">
								{t('collect_login.errors.Enter a valid email address')}
							</span>
						)}
					</p>
				</Col>
			</Row>
			<Row className="input-wrapper">
				{/* <Col className="d-flex flex-column align-items-center">
					<input
						className={`kiosk-input ${errors && errors.mobile ? 'error' : ''}`}
						type="text"
						placeholder={t('collect_login.placeholders.mobile')}
						onChange={(e) => setMobile(e.target.value)}
					/>
					<p className="error-p">
						{errors && errors.mobile && (
							<span className="form-error-text d-block">
								{t('collect_login.errors.Enter a valid 9 digit mobile number')}
							</span>
						)}
					</p>
				</Col> */}
				<Col className="d-flex flex-column align-items-center">
					<div className="d-flex align-items-end">
							<Dropdown className="mr-4 code">
								<Dropdown.Toggle
									className="dropdownClassic"
									id="dropdown-basic"
								>
									{countryCode ? countryCode : 'Loading'}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdownItems">
									{countries && countries.length > 0
										? countries.map((country, index) => (
												<Dropdown.Item
													onClick={() => {
														setCountryCode(`+${country.country_code}`);
													}}
													key={index}
													className=""
												>
													{' '}
													{country.country_code} ({country.country_short_code})
												</Dropdown.Item>
										  ))
										: null}
								</Dropdown.Menu>
							</Dropdown>
						<div className={`w-67 ${arabic ? 'pr-1' : 'pl-1'}`}>
							<input
								className={`kiosk-input ${errors && errors.mobile ? 'error' : ''}`}
								onChange={(e) => setMobile(e.target.value)}
								type="text"
								placeholder={t('collect_login.placeholders.mobile')}
								value={mobile}
							/>
						</div>
					</div>
					{errors && errors.mobile && (
						<span className="form-error-text d-block">
							{t('collect_login.errors.Enter a valid 9 digit mobile number')}
						</span>
					)}
				</Col>
			</Row>
			<Row>
				<Col className="">
					<button className="blue-btn mx-auto" onClick={onSubmit}>
						{showProceedSpinner ? (
							<Spinner animation="border" role="status" className="spinner">
								<span className="sr-only">Loading...</span>
							</Spinner>
						) : (
							t('common.PROCEED')
						)}
					</button>
				</Col>
			</Row>
		</div>
	);
};

export default withNamespaces()(KioskCollectLoginPage);
