import React from 'react';
import ReactDOM from 'react-dom';
import GlobalConfigContext from '@context/GlobalConfigContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { CinemaService } from '@apiService/tokenService';
import queryString from 'query-string';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const current_url = window.location.host;

console.log(current_url, "current_url >>>>>>>>>>>>>>>>>>>>>>>>>")

console.log(current_url, 'current_url');
if (current_url === 'dammam-kiosk-cinepolis.binarynumbers.io') {
	console.log('1327');
	CinemaService.setCinemaId('1327');
} else if (current_url === 'kiosk-cinepolis.binarynumbers.io') {
	//1337 jijan
	console.log('1329');
	CinemaService.setCinemaId('1329');
	//CinemaService.setCinemaId('1332');
}
else if (current_url === 'jeddah-kiosk-cinepolis.binarynumbers.io') {
	CinemaService.setCinemaId('1332');
	console.log('1332');
} 

else if (current_url === 'jizan-kiosk-cinepolis.binarynumbers.io') {
	CinemaService.setCinemaId('1329');
	console.log('1329');
} 

else if (current_url === 'massarah-kiosk-cinepolis.binarynumbers.io') {
	CinemaService.setCinemaId('1337');
	console.log('1337');
} 

else if (current_url === 'amwaj-kiosk-cinepolis.binarynumbers.io') {
	CinemaService.setCinemaId('1344');
	console.log('1344');
} 
// // if (window.location.search) {
// // 	const search = window.location.search;
// // 	const params = queryString.parse(search);
// // 	if (params.cinema_id) {
// // 		CinemaService.setCinemaId(params.cinema_id);
// // 	}
// // }

ReactDOM.render(
	<Router>
		<GlobalConfigContext>
			<App />
		</GlobalConfigContext>
	</Router>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
