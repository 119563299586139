import { reactLocalStorage } from 'reactjs-localstorage';

const TOKEN_KEY = 'access_token';
const COUNTRY = 'country';
const LANGUAGE = 'language';
const CITY = 'city';
const CINEMA = 'CINEMA';
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
	getToken() {
		return reactLocalStorage.get(TOKEN_KEY);
	},

	saveToken(accessToken) {
		reactLocalStorage.set(TOKEN_KEY, accessToken);
	},

	removeToken() {
		reactLocalStorage.remove(TOKEN_KEY);
	},
};

const LanguageService = {
	getLanguage() {
		return reactLocalStorage.get(LANGUAGE);
	},

	saveLanguage(language) {
		reactLocalStorage.set(LANGUAGE, language);
	},

	removeLanguage() {
		reactLocalStorage.remove(LANGUAGE);
	},
};

const CountryService = {
	getCountry() {
		return JSON.parse(reactLocalStorage.get(COUNTRY));
	},

	saveCountry(country) {
		reactLocalStorage.set(COUNTRY, JSON.stringify(country));
	},

	removeCountry() {
		reactLocalStorage.remove(COUNTRY);
	},
};

const CityService = {
	getCity() {
		return JSON.parse(reactLocalStorage.get(CITY) || null);
	},

	saveCity(city) {
		reactLocalStorage.set(CITY, JSON.stringify(city));
	},

	removeCity() {
		reactLocalStorage.remove(CITY);
	},
};

const CinemaService = {
	getCinemaId() {
		return reactLocalStorage.get(CINEMA);
	},
	setCinemaId(cinema_id) {
		return reactLocalStorage.set(CINEMA, cinema_id);
	},
	removeCinemaId() {
		return reactLocalStorage.remove(CINEMA);
	},
};

export { TokenService, LanguageService, CountryService, CityService, CinemaService };
