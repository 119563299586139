import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AnimatedSwitch, spring } from 'react-router-transition';
//import compoents
import MoviesListing from './components/MoviesListingContainer';
import CollectOrBookShowPage from './components/CollectOrBookShowPage';
import KioskCollectLoginPage from './components/KioskCollectLoginPage';
import ChooseLanguage from './components/ChooseLanguage';
import ChooseCinema from './components/ChooseCinema';
import MovieDetails from './components/MovieDetails';
import SeatLayout from './components/SeatLayout';
import FnB from './components/FnB';
import FnBConfirm from './components/FnBConfirm';
import FinalTicket from './components/FinalTicket';
import KioskCollectTwo from './components/KioskCollectTwo';
import InsertCard from './components/InsertCard';
import ProcessPayment from './components/ProcessPayment';
import SeatAndVoucher from './components/SeatsAndVoucher';
import Checkout from './components/Checkout.js';
import Receipt from './components/Receipt.js';
import R1 from './components/R1.js';
import R2 from './components/R2.js';
import TempR1 from './components/TempR1.js';

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
	return {
		opacity: styles.opacity,
		transform: `scale(${styles.scale})`,
	};
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
	return spring(val, {
		stiffness: 330,
		damping: 22,
	});
}

// child matches will...
const bounceTransition = {
	// start in a transparent, upscaled state
	atEnter: {
		opacity: 0,
		scale: 1.2,
	},
	// leave in a transparent, downscaled state
	atLeave: {
		opacity: bounce(0),
		scale: bounce(0.8),
	},
	// and rest at an opaque, normally-scaled state
	atActive: {
		opacity: bounce(1),
		scale: bounce(1),
	},
};

const Router = () => (
	//define path and use component
	<Switch>
		{/* <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"> */}
		<Route exact path="/" component={ChooseLanguage} />
		<Route exact path="/choose-cinema" component={ChooseCinema} />
		<Route exact path="/book-show" component={CollectOrBookShowPage} />
		<Route exact path="/movies" component={MoviesListing} />
		<Route exact path="/collect-login" component={KioskCollectLoginPage} />
		<Route exact path="/movie-details/:movie_id" component={MovieDetails} />
		<Route exact path="/seat-layout/:screen_id/:session_id/:md_id/:type_seat_show/:movie_id/:cinemaId" component={SeatLayout} />
		<Route exact path="/fnb/:cinema_id/:session_id" component={FnB} />
		<Route exact path="/fnb-confirm/:cinema_id/:session_id" component={FnBConfirm} />
		<Route exact path="/checkout" component={Checkout} />
		<Route exact path="/final-ticket/:booking_id" component={FinalTicket} />
		<Route exact path="/kiosk-collect-2" component={KioskCollectTwo} />
		<Route exact path="/insert-card" component={InsertCard} />
		<Route exact path="/process-payment" component={ProcessPayment} />
		<Route exact path="/seat-voucher" component={SeatAndVoucher} />
		<Route exact path="/receipt" component={Receipt} />
		<Route exact path="/r1" component={R1} />
		<Route exact path="/r2" component={R2} />
		<Route exact path="/tempr1" component={TempR1} />
		{/* </AnimatedSwitch> */}
	</Switch>
);

export default Router;
