import React, { useEffect, useState, useContext } from 'react';
import i18n from '../plugins/i18n';
import { useHistory } from 'react-router-dom';
import { globalConfigContext } from '@context/GlobalConfigContext';
import moment from 'moment';
import BookingService from '../ApiServices/BookingService';

// import 'moment/locale/ar';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import { PaymentService } from '@apiService/PaymentService';
import { SOCKET_URL } from '@config';
import { Link } from 'react-router-dom';
// var wsUrl = SOCKET_URL;
// var socket = new WebSocket(wsUrl);
const lang = window.localStorage.getItem('lang');

if (lang && lang === 'ar') {
	import('@assets/css/arabic.css').then((condition) => {});
	// moment.locale('ar');
} else {
	import('@assets/css/App.css').then((condition) => {});
	// moment.locale('en');
}

function ChooseLanguage({ t }) {
	const { arabic, setArabic } = useContext(globalConfigContext);


	useEffect(()=>{

		let res_id = localStorage.getItem('reservation_id')

		BookingService.releseSeats({
			reservation_id : res_id,
			guest_user_id : res_id + "123"
		})
	}, [])

	useEffect(() => {
		if (lang && lang === 'ar') {
			document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
			setArabic(true);
		} else {
			document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
			setArabic(false);
		}
		i18n.changeLanguage(lang ? lang : 'en');
	}, []);

	const history = useHistory();

	const onSelectLanguage = (lng) => {
		if (lng === 'print') {
			let showData = {
				ScreenName: 'SCREEN 2',
				ScreenNameAlt: '',
				movie_title: 'Antim: The Final Truth',
				Showtime: '2021-12-29T21:05:00.000Z',
				rating: 'R15',
				seats_name: 'A-2',
				SessionAttribute: 'PREMIUM',
				currency_short_code: 'SAR',
				transaction_net_price: 4900,
				divided_by: 100,
				currency_short_code_ar: 'ر.س',
				cinema_address: 'Al Hokair Time, Jizan',
				isReceipt: 'N',
				booking_fee: 500,
				isReceipt: 'N',
				ticketAmount: '200',
				subTotal: '200',
				tax_percent: '5',
				total: '200',
				fnbAmount: '150',
				sold: '52',
				booking_fee: 500,
				ticketAmount: '55',
			};
			//window.location = 'http://localhost:3001/print?jitu=test';
			// axios
			// 	.get('http://localhost:3001/print?data=' + JSON.stringify(showData))
			// 	.then((response) => ({}));
			return false;
		}
		if (lng === 'printR') {
			let showData = {
				ScreenName: 'SCREEN 2',
				ScreenNameAlt: '',
				movie_title: 'Antim: The Final Truth',
				Showtime: '2021-12-29T21:05:00.000Z',
				rating: 'R15',
				seats_name: 'A-2',
				SessionAttribute: 'PREMIUM',
				currency_short_code: 'SAR',
				transaction_net_price: 4900,
				divided_by: 100,
				currency_short_code_ar: 'ر.س',
				cinema_address: 'Al Hokair Time, Jizan',
				isReceipt: 'Y',
				ticketAmount: '200',
				subTotal: '200',
				tax_percent: '5',
				total: '200',
				fnbAmount: '150',
				sold: '52',
				booking_fee: 500,
				ticketAmount: '55',
			};
			//window.location = 'http://localhost:3001/print?jitu=test';
			// axios
			// 	.get('http://localhost:3001/print?data=' + JSON.stringify(showData))
			// 	.then((response) => ({}));
			return false;
		}
		if (lng === 'ar') {
			window.localStorage.setItem('lang', 'ar');
			document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
		} else {
			// lastStyleSheet.disabled = true;
			window.localStorage.setItem('lang', 'en');
			document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
		}

		i18n.changeLanguage(lng);
		if (lang && lang != lng) {
			window.location.reload();
		} else {
			history.push('/book-show');
		}
	};

	// const onSelectPrint = (lng) => {
	// 	let obj = {
	// 		name: 'ajit',
	// 	};
	// 	//window.location = 'http://localhost:3001/print';
	// 	axios.get('http://localhost:3001/print', obj).then((response) => ({}));
	// };
	return (
		<div className="parent">
			<div id="printableArea"></div>
			{/* <div className="go_back">
				<div className="button_wrap">
					<div className="go_back_button"></div>
				</div>
				<h2 className="big_text">GO BACK</h2>
			</div> */}
			<div className="page2_container">
				<Link to="/" className="page2_movie_header">
					<h2 className="small_text">{t('common.WELCOME TO')}</h2>
					<div className="logo2"></div>
					{/* <h2 className="bigg_text">{t('common.OASIS MALL')}</h2> */}
				</Link>
				<div className="page2_body">
					<div className="choose_action">{t('choose_lang.CHOOSE YOUR LANGUAGE')}</div>
					<div className="huge_buttons">
						<button onClick={() => onSelectLanguage('en')} className="left_button hb">
							{t('choose_lang.ENGLISH')}
						</button>
						<button onClick={() => onSelectLanguage('ar')} className="right_button hb">
							{t('choose_lang.ARABIC')}
						</button>

						{/* <button onClick={() => onSelectLanguage('print')} className="right_button hb">
							Print
						</button>

						<button onClick={() => onSelectLanguage('printR')} className="right_button hb">
							Print R
						</button> */}

						{/* <button onClick={() => history.push('/process-payment')} className="right_button hb">
							payment page
						</button> */}
					</div>
				</div>
			</div>
		</div>
	);
}
export default withNamespaces()(ChooseLanguage);
