import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	fetchSeatLayoutData,
	fetchTicketPrice,
	selectSeats,
	vistaSetSeats,
	clearBookingState,
	clearFoodAndBeveragesState,
	cancelTransaction,
	stopTimer,
	selectOfferSeats,
} from '@store/booking/bookingActions';
import { validateVoucher } from '@store/booking/bookingActions';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { timeConvert, isImageURLNull, translateSessionAttribute } from '@helper/formatting.js';
import {
	Container,
	Row,
	Col,
	Table,
	Alert,
	Modal,
	Spinner,
	Dropdown,
	Accordion,
	Card,
	Form,
} from 'react-bootstrap';
import BookingService from '../ApiServices/BookingService';
import MovieHeader from '@components/partials/MovieHeader';
import CSpinner from '@components/partials/Spinner';
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import whiteSofa from '../assets/imgs/svgs/sofa.svg';
import wheelChair from '../assets/imgs/svgs/wheel-chair.svg';
import selectedSeat from '../assets/imgs/svgs/seat-sl-selected.svg';
import occupiedSeat from '../assets/imgs/svgs/seat-sl-occupied.svg';
import whiteSeat from '../assets/imgs/svgs/seat-sl.svg';

/* Ratings Images */
import GRatingImg from '../assets/imgs/ratings/G.png';
import PGRatingImg from '../assets/imgs/ratings/PG.png';
import PG12RatingImg from '../assets/imgs/ratings/PG12.png';
import R12RatingImg from '../assets/imgs/ratings/R12.png';
import R15RatingImg from '../assets/imgs/ratings/R15.png';
import R18RatingImg from '../assets/imgs/ratings/R18.png';

import GenericService from '@apiService/GenericService';

import {
	titleCase,
	// translateSessionAttribute,
	// formatYuppErrors,
} from '@helper/formatting';
import { setReservationData, setReservationID } from '../store/booking/bookingActions';

let ratingToImageMapping = {
	G: GRatingImg,
	PG: PGRatingImg,
	PG12: PG12RatingImg,
	R12: R12RatingImg,
	R15: R15RatingImg,
	R18: R18RatingImg,
};

const els3 = [1, 2, 3];
const els10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const el16 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
// const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

let showPno = (e) => {
	const pno = document.querySelector('#pno');
	const bankOffers = document.querySelector('#bankOffers');
	const pnoBtn = document.querySelector('#pnoBtn');
	const bankOffersBtn = document.querySelector('#bankOffersBtn');

	if (pno.classList.contains('d-none')) {
		pno.classList.add('d-flex');
		bankOffers.classList.remove('d-none');
		bankOffers.classList.add('d-none');
		bankOffers.classList.remove('d-flex');
	}
	if (!e.target.classList.contains('active')) {
		e.target.classList.add('active');
		bankOffersBtn.classList.remove('active');
	}
};

let showBankOffers = (e) => {
	const pno = document.querySelector('#pno');
	const bankOffers = document.querySelector('#bankOffers');
	const pnoBtn = document.querySelector('#pnoBtn');
	const bankOffersBtn = document.querySelector('#bankOffersBtn');

	if (bankOffers.classList.contains('d-none')) {
		pno.classList.add('d-none');
		bankOffers.classList.add('d-flex');
		pno.classList.remove('d-flex');
		bankOffers.classList.remove('d-none');
	}
	if (!e.target.classList.contains('active')) {
		e.target.classList.add('active');
		pnoBtn.classList.remove('active');
	}
};

const getSeatClass = (status, singleSeat) => {
	// if (singleSeat.coupleSeat) {
	// 	return 'sofa';
	// } else if (status === 3) {
	// 	return 'wheel-chair';
	// } else return 'cell';
};

function SeatLayout(props) {
	console.log(props, 'props');
	const { arabic, currency, country_data } = useContext(globalConfigContext);
	const { session_id } = props.match.params;
	const { screen_id } = props.match.params;
	const { md_id } = props.match.params;
	const { type_seat_show } = props.match.params;
	const { cinemaId } = props.match.params;
	// console.log(props)
	let cinema_id = '';
	const ss_id = session_id;
	const t = props.t;

	const history = useHistory();

	const [showSpinner, setShowSpinner] = useState(false);
	let offers = [];

	const reservation_id = useSelector((state) => state.booking.reservation_id);
	const selected_movie = useSelector((state) => state.movies.selected_movie);

	const showTimes = useSelector((state) => state.movies.show_times);
	// const reservation_id = useSelector((state) => state.booking.reservation_id);

	const dispatch = useDispatch();

	useEffect(() => {

		let res_id = localStorage.getItem('reservation_id')

		BookingService.releseSeats({
			reservation_id: res_id,
			guest_user_id: res_id + "123"
		})
	}, [])

	useEffect(() => {
		const timeout = setTimeout(() => {
			history.push('/');
		}, 60000); // 60000 milliseconds = 1 minute

		return () => clearTimeout(timeout);
	}, [history]);


	useEffect(() => {
		window.scrollTo(0, 0);
		setShowSpinner(true);

		if (reservation_id) {
			dispatch(cancelTransaction({ reservation_id }));
		}

		dispatch(clearBookingState());
		dispatch(clearFoodAndBeveragesState());
		dispatch(stopTimer());

		// Promise.allSettled([
		// dispatch(fetchSeatLayoutData({ session_id, cinema_id })),
		// dispatch(fetchTicketPrice({ session_id, cinema_id })),
		// ]).finally(() => setShowSpinner(false));
	}, [session_id, , dispatch]);

	const [allSeats, setAllseats] = useState([]);
	const [screen_seat_type, setScreen_seat_type] = useState([]);
	const [ticketTypeTranslations, setTicketTypeTranslations] = useState([]);
	const [selectSeats, setselectSeats] = useState([]);
	const [selectLayout, setselectLayout] = useState([]);
	const [seatId, setseatId] = useState([]);
	const [seatName, setseatName] = useState([]);
	const [totalamount, settotalamount] = useState(0);
	const [taxAmount, settaxAmount] = useState(0);
	const [alphabet, setalphabet] = useState(0);
	const [breaklength, setbreaklength] = useState(0);
	// const [allSeats, setAllseats] = useState([]);
	const seats = useSelector((state) => state.booking.seats_selected);
	const seatLayout = useSelector((state) => state.booking.seat_layout);
	const selectedTicketPrice = useSelector((state) => state.booking.selectedTicketPrice);
	const movieDetails = useSelector((state) => state.movies.movie_details);
	const showDetails = useSelector((state) =>
		state.booking.show_details && state.booking.show_details.length > 0
			? state.booking.show_details[0]
			: {}
	);

	// const showDetailsOne = useSelector((state) =>
	// 	state.booking.show_details && state.booking.show_details.length > 0
	// 		? state.booking.show_details
	// 		: {}
	// );


	// console.log(showDetailsOne, "showDetailsOne >>>>>>>>>>>>>>")
	const confirmedBankOfferTicketObj = useSelector((state) => state.booking.selectedBankOfferTicket);
	const selected_seats = useSelector((state) => state.booking.vista_seats_selected);
	const seatSelectError = useSelector((state) => state.booking.seat_select_error);

	const [showSeatLimitError, setShowSeatLimitError] = useState(false);
	const [showProceedSpinner, setShowProceedSpinner] = useState(false);
	const [showVoucherPopup, setShowVoucherPopup] = useState(false);
	const [voucherCode, setVoucherCode] = useState(null);
	const [voucherCodeLoading, setVoucherCodeLoading] = useState(false);
	const [voucherSeatsExceededError, setVoucherSeatsExceededError] = useState(false);
	const [cities, setCities] = useState(null);

	const [selectedCategoryOffer, setSelectedCategoryOffer] = useState([]);
	const [updatePrice, setUpdatePrice] = useState(false);

	const [showCovidDisclaimerModal, setShowCovidDisclaimerModal] = useState(false);
	const [weelchair, setweelchair] = useState(false);
	const [startpopup, setstartpopup] = useState(true);

	const [isProceedEnabled, setIsProceedEnabled] = useState(false);

	console.log(updatePrice, 'LLLLLLLLLLLL');

	const [selectedBankOfferTicketObj, setSelectedBankOfferTicketObj] = useState(false);

	const [toShow, setToShow] = useState(false);

	const voucherCodeData = useSelector((state) => state.booking.voucherCodeData);
	const voucherCodeError = useSelector((state) => state.booking.voucherCodeError);
	const bookingFee = useSelector((state) => state.booking.bookingFee);
	const bookingFeeTax = useSelector((state) => state.booking.bookingFeeTax);

	const [orderOpen, setOrderOpen] = useState([0, 0, 0]);
	const toggleHandle = (key, value = 'closeCollapse') => {
		setOrderOpen((oldState) => {
			let openCopy = Array(3).fill(0);
			if (value == 'openCollapse') {
				openCopy[key] = 1;
			} else {
				openCopy[key] = !oldState[key];
			}
			return openCopy;
		});
	};

	const onCheckout = () => {
		history.push('/checkout');
	};

	const [beanbag, setbeanbag] = useState(true);

	useEffect(() => {
		let a = 0;
		let b = 0;

		// console.log(selectLayout, "selectLayout >>>>>>>>>>>>>>>>>>")

		selectLayout.map((ele) => {
			a = a + ele.rc_amount;
			b = b + ele.internet_handling_fee;
		});
		// console.log(selectLayout, "selectLayout");
		settotalamount(a);
		settaxAmount(b);
	}, [selectLayout]);

	let show_time = localStorage.getItem('selected_showtime');
	let show_date = localStorage.getItem('ss_actual_start_date');
	console.log(show_time, 'show_time>>>');
	useEffect(() => {
		// GenericService.GetCities().then((response) => {
		// 	if (response && response.data) {
		// 		setCities(response.data.data);
		// 	}
		// });

		setShowCovidDisclaimerModal(false);
		const payload = { ss_id, md_id, screen_id, type_seat_show };
		setShowSpinner(true);
		const fetchSeatLayoutData = (payload) => {
			console.log(payload, 'Hi mk');

			BookingService.GetSeatLayout(payload)
				.then((response) => {
					const seats = response.data;
					setAllseats(seats.Records);
					setScreen_seat_type(seats.screen_seat_type);
					setTicketTypeTranslations(seats.ticketTypeTranslations);
					// console.log(seats, 'from>>>');
					setShowSpinner(false);
					//   dispatch(fetchShowDetailsSuccess(seats));

					if (response.status) {
						// dispatch(
						//   fetchSeatLayoutSuccess(
						//     data.data.seatPlan.SeatLayoutData.Areas,
						//     data.data.isVoucherAvailable
						//   )
						// );
						// dispatch(
						//   setBookingFeeData(data.data.showDetails[0].booking_fee_in_cents)
						// );
						// if (
						//   (data.data.showDetails[0].fnb_tax_percent !== null) |
						//   (data.data.showDetails[0].fnb_tax_percent !== undefined)
						// ) {
						//   dispatch(
						//     setTaxPercentInFnb(data.data.showDetails[0].fnb_tax_percent)
						//   );
						// }
						// if (data.data.covidMessage) {
						//   dispatch(
						//     setAdditionalData({ covidMessage: data.data.covidMessage })
						//   );
						// }
					}
				})
				.catch((err) => {
					//   dispatch(fetchSeatLayoutError(err.message));
				});
			// };
		};
		fetchSeatLayoutData(payload);
	}, []);

	if (arabic) {
		var span = (
			<ul>
				<li className="mb-3">
					أؤكد أنني أبلغ من العمر 12 عامًا أو أكثر ، وقد حدثت جرعة التطعيم الثانية في فترة في قبل 14
					يومًا على الأقل من تاريخ اليوم أو تعافيت ، وهذا شهادة التطعيم ستكون موجودة من أجل التفتيش
					عليها دخولي إلى السينما.
				</li>
				<li className="mb-3">
					أؤكد بموجب هذا أنني لن أتلقى استردادًا نقديًا أو رصيدًا أو تذكرة معدلة لتذكرة السينما التي
					اشتريتها إذا لم تكن شهادة التطعيم الخاصة بي موجودة عند دخولي إلى السينما.
				</li>
			</ul>
		);
	}
	if (!arabic) {
		var span = (
			<ul>
				{/* <li className="mb-3">
          I confirm that I am 12 years or older, my second vaccination dose
          occurred at a period of at least 14 days prior to today's date or I
          have recovered, and that my vaccination certificate will be present in
          order for inspection upon my entry to the cinema.
        </li> */}
				<li className="mb-3">
					I hereby confirm that I will not receive a cash refund, credit or amended ticket for my
					purchased cinema ticket if my vaccination certificate is not present when I enter the
					cinema
				</li>
			</ul>
		);
	}
	if (arabic) {
		var spanKSA = (
			<ul>
				{/* <li className="mb-3">
          - أؤكد انني ومرافقيني تلقينا الجرعتين و تظهر حالتنا في تطبيق توكلنا
          على اننا محصنين وأوافق على تقديم الحالة الصحية عند الدخول إلى السينما.
        </li> */}
				<li className="mb-3">
					- أؤكد بموجب هذا أنني لن اتلقى استرداداً نقدياً أو رصيداً أو تذكرة مؤجلة إذا لم تكن حالتي
					الصحية في تطبيق توكلنا "محصن" عند دخولي إلى السينما
				</li>
			</ul>
		);
	}
	if (!arabic) {
		var spanKSA = (
			<ul>
				<li className="mb-3">
					I confirm i have had two vaccination doses and agree to provide TAWAKLNA app upon entry to
					cinema, if required.
				</li>
				<li className="mb-3">
					I hereby confirm that i will not receive a cash refund, credit, or amended ticket for my
					purchase if TAWAKLNA status is not immuned, and I didn't present my TAWAKLNA when i enter
					the cinema.
				</li>
			</ul>
		);
	}

	if (arabic) {
		var spanOM = (
			<ul>
				<li className="mb-3">
					- أؤكد انني ومرافقيني تلقينا جرعة تطعيم واحدة على الأقل و تظهر حالتنا في تطبيق توكلنا على
					اننا محصنين وأوافق على تقديم الحالة الصحية عند الدخول إلى السينما
				</li>
				<li className="mb-3">
					- أؤكد بموجب هذا أنني لن اتلقى استرداداً نقدياً أو رصيداً أو تذكرة مؤجلة إذا لم تكن حالتي
					الصحية في تطبيق توكلنا "محصن" عند دخولي إلى السينما{' '}
				</li>
			</ul>
		);
	}
	if (!arabic) {
		var spanOM = (
			<ul>
				<li className="mb-3">
					I confirm I have had at least one vaccination dose and agree provide my vaccination
					certificate upon entry to the cinema, if required.
				</li>
				<li className="mb-3">
					I hereby confirm that I will not receive a cash refund, credit, or amended ticket for my
					purchase if my vaccination certificate is not present when I enter the cinema.
				</li>
			</ul>
		);
	}

	const onProcess = async () => {
		if (
			country_data.country_short_code == 'BH' ||
			country_data.country_short_code == 'SA' ||
			country_data.country_short_code == 'OM'
		) {
			setToShow(true);
		} else {
			onProceed();
		}
	};

	const onSelectTicketTypes = (type, index, areaCode) => {
		console.log(type, 'PPPPPPPPPPPPPPPPPPP');
		if (seatLayout && seatLayout.length > 0) {
			seatLayout[index]['selectedCatOffer'] =
				type.DisplayName != 'None' ? type.DisplayName : type.Description;
		}
		let existingTypes = selectedCategoryOffer.filter((p) => {
			return p.AreaCategoryCode == type.AreaCategoryCode;
		});

		if (existingTypes.length > 0 && selectedCategoryOffer.length > 0) {
			selectedCategoryOffer.splice(0, 1);
		}

		setSelectedCategoryOffer([...selectedCategoryOffer, type]);

		// let finalPriceInCents = 0;
		// let finalTaxInCents = 0;
		// const priceWithTax = type.PriceInCents;
		// const priceWithoutTax = parseFloat(
		//   (priceWithTax / (1 + taxRate)).toFixed(2)
		// );
		// const tax = parseFloat((priceWithTax - priceWithoutTax).toFixed(2));
		// console.log(type, "type");
		// // if (areaCode) {
		// //   let alreadySelectedArea = selected_seats.filter((m) => {
		// //     return (
		// //       m.AreaCategoryCode == areaCode.AreaCategoryCode &&
		// //       m.TicketTypeCode != areaCode.TicketTypeCode
		// //     );
		// //   });
		// //   console.log(alreadySelectedArea, "kjhgj");
		// //   if (alreadySelectedArea.length > 0) {
		// //     selected_seats.map((item) => {
		// //       console.log(item.AreaCategoryCode, item.TicketTypeCode);
		// //       if (
		// //         item.AreaCategoryCode == areaCode.AreaCategoryCode &&
		// //         item.TicketTypeCode == areaCode.TicketTypeCode
		// //       ) {
		// //       }
		// //       // AreaCategoryCode: "0000000001"
		// //       // AreaNumber: 1
		// //       // ColumnIndex: 3
		// //       // RowIndex: 7
		// //       // TicketTypeCode: "0001"
		// //       // coupleSeat: false
		// //       // dummyTaxInCents: 573.91
		// //       // isBankOffer: false
		// //       // isBogo: false
		// //       // isVoucher: false
		// //       // priceInCents: 4400
		// //       // seatDescription: "PREMIUM"
		// //       // seatNameDescription: "C-11"
		// //       // ticketTypeCodeDescription: "PREMIUM"
		// //     });
		// //   }
		// //   // console.log(type, "hhh");
		// // }
		// console.log(selected_seats, "dispatch(clearSelectedSeats());");
		// if (type) {
		//   let filterSeatTypes = selected_seats.filter((p) => {
		//     return p.AreaCategoryCode == type.AreaCategoryCode;
		//   });
		//   if (filterSeatTypes.length > 0) {
		//     filterSeatTypes.map((item, indexSeat) => {
		//       if (item.coupleSeat == true && item.priceInCents == 0) {
		//         // console.log(
		//         //   (item[index + 1].TicketTypeCode = type.TicketTypeCode),
		//         //   "couple"
		//         // );
		//         item.priceInCents = 0;
		//       } else {
		//         item.priceInCents = type.PriceInCents;
		//       }
		//       item.TicketTypeCode = type.TicketTypeCode;
		//       if (item.dummyTaxInCents) {
		//         item.dummyTaxInCents = tax;
		//       }
		//       item.ticketTypeCodeDescription = type.Description;
		//     });
		//   }
		//   console.log(filterSeatTypes, "filterSeatTypes");
		// }
		// for (let i = 0; i < selected_seats.length; i++) {
		//   finalPriceInCents += selected_seats[i].priceInCents;
		//   if (selected_seats[i].dummyTaxInCents) {
		//     finalTaxInCents += selected_seats[i].dummyTaxInCents;
		//   }
		// }
		// console.log(finalTaxInCents);
		// console.log(finalPriceInCents, "==", finalTaxInCents);
		// selectedTicketPrice.priceInCents = finalPriceInCents;
		// selectedTicketPrice.originalPriceInCents = finalPriceInCents;
		// selectedTicketPrice.taxInCents = finalTaxInCents;
		// selectedTicketPrice.originalTaxInCents = finalTaxInCents;
		// selectedTicketPrice.totalWithFnb = finalPriceInCents;
		// selectedTicketPrice.originalTotalWithFnb = finalPriceInCents;
		// console.log(selectedTicketPrice, type, selected_seats, "hutyutuy");
		// if (updatePrice == false) {
		//   setUpdatePrice(true);
		// } else {
		//   setUpdatePrice(false);
		// }
	};

	const checkSeatValid = (seatStatus, seatIndex, rowIndex, seatTypes, singleSeat) => {
		if (seatStatus == 1 || seatStatus == 4 || seatStatus == 5) {
			return false;
		} else if (voucherCodeData) {
			let ticketTypeExists = voucherCodeData.ticketTypes.findIndex((obj) => {
				return (
					seatTypes.TicketTypeCodeBarcode &&
					seatTypes.TicketTypeCodeBarcode.includes(obj.ticketTypeCode)
				);
			});
			if (ticketTypeExists === -1) {
				return false;
			} else {
				return true;
			}
		}
		return true;
	};

	const voucherHandler = async () => {
		if (voucherCodeLoading) {
			return;
		}
		if (voucherCode) {
			setVoucherCodeLoading(true);
			dispatch(
				validateVoucher({
					voucher_code: voucherCode,
					cinema_id,
					session_id,
				})
			)
				.then((data) => (data !== 'INVALID' ? setShowVoucherPopup(false) : null))
				.finally(() => setVoucherCodeLoading(false));
		}
	};
	const addseat = (a, b, c, d) => {
		console.log('slected');
		if (selectSeats.includes(a)) {
			setselectSeats((current) =>
				current.filter((ele) => {
					return ele !== a;
				})
			);
			setselectLayout((current) =>
				current.filter((ele) => {
					return ele !== b;
				})
			);
			setseatId((current) =>
				current.filter((ele) => {
					return ele !== c;
				})
			);
			setseatName((current) =>
				current.sort().filter((ele) => {
					return ele !== d;
				})
			);
		} else {
			if (selectSeats.length == 6) {
				return null;
			}
			setselectSeats((prevState) => [...prevState, a]);
			b.seat_type_id = c;
			setselectLayout((prevState) => [...prevState, b]);
			setseatId((prevState) => [...prevState, c]);
			setseatName((prevState) => [...prevState, d]);
		}

		// console.log(selectSeats, "Slect seat")
		// console.log(selectLayout, "select layout")
		// console.log(seatId, "seat id")
	};
	const changeAlphabet = (index) => {
		let a = parseInt(alphabet) + 1;
		setalphabet(parseInt(a));
		setbreaklength(index);
		// console.log(index)
		// return a;
	};
	useEffect(() => {
		if (selectedCategoryOffer.length >= 0) {
			console.log(selectedCategoryOffer, 'UUUUUUUUUUUUUUUUUUUUUUUUUUUUU');
			dispatch(
				selectOfferSeats({
					selectedCategoryOffer,
				})
			);
			if (updatePrice == false) {
				setUpdatePrice(true);
			} else {
				setUpdatePrice(false);
			}
		}

		// if(voucherCodeData) {
		//   if((voucherCodeData.isBogo && seats.length < voucherCodeData.numberOfRedemptionsRemaining * 2)
		//   || (!voucherCodeData.isBogo && seats.length < voucherCodeData.numberOfRedemptionsRemaining))
		//     setVoucherSeatsExceededError(false);
		// }
	}, [selectedCategoryOffer]);

	useEffect(() => {
		if (seats && seats.length < 6) {
			setShowSeatLimitError(false);
		}
	}, [seats, voucherCodeData]);

	const onSelectSeats = (
		seatTypeIndex,
		rowIndex,
		seatIndex,
		seatTypes,
		singleSeat,
		adjacentSeatPosition
	) => {
		console.log(singleSeat);
		const { Position, Status } = singleSeat;
		if (Status == 1 || !checkSeatValid(Status, seatIndex, rowIndex, seatTypes, singleSeat)) return;

		let seatName =
			seatLayout[seatTypeIndex].Rows[rowIndex].PhysicalName +
			'-' +
			seatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].Id;

		if (seats.length === 6 && !seats.includes(seatName)) {
			setShowSeatLimitError(true);
		}

		dispatch(
			selectSeats({
				seatTypeIndex,
				rowIndex,
				seatIndex,
				Position,
				singleSeat,
				adjacentSeatPosition,
			})
		);

		if (selectedBankOfferTicketObj == false && !voucherCodeData) {
			let initailOption = selectedCategoryOffer.filter((x) => {
				return x.AreaCategoryCode == Position.AreaCategoryCode;
			});
			//  let existingTypes = selectedCategoryOffer.filter((p) => {
			//       return p.AreaCategoryCode == type.AreaCategoryCode;
			//     });

			//     if (existingTypes.length > 0 && selectedCategoryOffer.length > 0) {
			//       selectedCategoryOffer.splice(0, 1);
			//     }
			if (initailOption.length <= 0 && selectedBankOfferTicketObj == false) {
				if (seatLayout && seatLayout.length > 0) {
					let obj = {
						AreaCategoryCode: seatLayout[seatTypeIndex].AreaCategoryCode,
						Description: seatLayout[seatTypeIndex].Description,
						PackageContent: { Concessions: [], Tickets: [] },
						PriceInCents: seatLayout[seatTypeIndex].PriceInCents,
						TaxInCents: seatLayout[seatTypeIndex].TaxInCents,
						TicketTypeCode: seatLayout[seatTypeIndex].TicketTypeCode,
					};
					setSelectedCategoryOffer([...selectedCategoryOffer, obj]);
					seatLayout[seatTypeIndex].cinepolisOffersPrices.push(obj);
				}
			}

			dispatch(
				selectOfferSeats({
					selectedCategoryOffer,
				})
			);
		}
	};

	const redirectToFnb = () => {
		history.push(`/fnb-confirm/${cinema_id}/${session_id}`);

		// if (showDetails.cinema_is_fnb === 'N') {
		// 	history.push(`/fnb-confirm/${cinema_id}/${session_id}`);
		// } else {
		// 	props.history.push(`/fnb/${cinema_id}/${session_id}`);
		// }
	};

	const onProceed = async () => {
		// if (
		// 	selected_seats.length === 0 ||
		// 	showProceedSpinner ||
		// 	(voucherCodeData &&
		// 		voucherCodeData.isBogo &&
		// 		selected_seats.length < voucherCodeData.numberOfRedemptionsRemaining * 2)
		// ) {
		// 	return;
		// }

		// let payload = {
		// 	cinema_id,
		// 	seats_name: seats.toString(),
		// 	user_selected_seats: selected_seats,
		// 	session_id,
		// 	selectedTicketPrice,
		// 	voucher_data: voucherCodeData,
		// };
		// setShowProceedSpinner(true);
		// await dispatch(vistaSetSeats(payload, redirectToFnb));
		// setShowProceedSpinner(false);

		setShowSpinner(true);

		let reservation_id = Math.floor(100000000 + Math.random() * 90000000);

		localStorage.setItem('reservation_id', reservation_id)

		// console.log(cinemaId, "show deyals")
		// console.log(showTimes, "show times")

		// seatId array problem

		let temp_seat_id = [];
		for (let i = 0; i < selectLayout.length; i++) {
			temp_seat_id.push(selectLayout[i].seat_type_id);
		}

		let payload = {
			cinema_id: cinemaId,
			gogo_booking_type_id: showTimes?.Records?.data[0]?.gogo_booking_type_id,
			gogo_ss_id: showTimes.Records.data[0].gogo_ss_id,
			groupRateCard: true,
			isVipOrBlockSeats: false,
			is_website: true,
			seat_layout_id: selectSeats,
			newSeatLayout: selectLayout,
			seat_type_id: temp_seat_id,
			specialRequest: '',
			schedule_show_id: session_id,
			reservation_id,
		};

		dispatch(setReservationID(reservation_id));
		const response = await BookingService.CheckingTest(payload);

		const r1 = await BookingService.CheckingTest1(reservation_id);
		dispatch(setReservationData(r1.data.Records));

		let aa = await BookingService.SelectVoucherAndReservation(reservation_id);

		// dispatch(BookingService.CheckingTest(payload))

		if (response.data.status) {
			setShowSpinner(false);
			history.push('/process-payment');
		}
	};

	const onClickBack = () => {
		history.go(-1);
	};

	return (
		<div className="">

			<Modal
				show={startpopup}
				centered
				aria-labelledby="contained-modal-title-vcenter"
				className="disclaimer-popup"
				// style={{ backgroundColor: 'black' }}
				size="md"
				backdrop="static"
			// onHide={() => setweelchair(false)}
			>
				<Modal.Header className="position-relative text-center">
					<h2 className="text-center w-100">
						IMPORTANT INFORMATION
					</h2>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex w-100 justify-content-center flex-column align-items-center">
						{/* <img height="45" width="45" className="mb-4" /> */}
						{/* <h4>Notice</h4> */}
					</div>
					<p className='text-center'>Refreshments purchased off the premises are not allowed inside the auditorium.</p>

					<p className='text-center'>{selected_movie?.rating ? selected_movie.rating : "12+"}: Suitable for {selected_movie?.rating}.</p>

					<button
						className={`btn blue-btn px-5 mt-3 btn-center`}
						onClick={() => setstartpopup(false)}
					>
						{'OK'}
					</button>
				</Modal.Body>
			</Modal>


			<Modal
				show={toShow}
				centered
				aria-labelledby="contained-modal-title-vcenter"
				className="disclaimer-popup"
				onHide={() => setToShow(false)}
			>
				<Modal.Header
					className="justify-content-center align-items-center mt-4 mb-3"
					closeButton={true}
				>
					<p className="font-weight-bold align-center fz">
						{t('seatlayout_page.covid-disclaimer-popup.title')}
					</p>
				</Modal.Header>
				<Modal.Body className="text-left pl-0">
					{/* {country_data.country_short_code == "BH"
            ? span
            : country_data.country_short_code == "SA"
            ? spanKSA
            : country_data.country_short_code == "OM"
            ? spanOM
            : ""} */}
				</Modal.Body>
				<Container fluid className="bg-light-black brds-8 my-4">
					<Row className="">
						<Col xs={12} className="">
							<div className="d-flex justify-content-between align-items-center">
								<div className={`d-flex text-light frs-16`}>
									<div className="mod-form">
										<Form.Check
											className="mod-form"
											onChange={(e) => setIsProceedEnabled(e.target.checked)}
											checked={isProceedEnabled}
											type="checkbox"
											id="tnc1-checkbox"
											label={
												<>
													{t('checkout_page.generic-tnc')}{' '}
													<a href={window.location.origin + '/tnc'} target="_blank">
														{t('checkout_page.terms and conditions')}
													</a>
												</>
											}
										/>
									</div>
								</div>
								<div className="ml-3">
									<a
										href="#"
										className={`small blue-btn text-nowrap px-3 ${!isProceedEnabled ? 'inactive-btn' : ''
											}`}
										onClick={() => {
											if (isProceedEnabled) {
												setToShow(false);
												onProceed();
											}
										}}
									>
										AGREE
									</a>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</Modal>
			<CSpinner active={showSpinner} />
			<MovieHeader />
			<Modal
				show={showCovidDisclaimerModal}
				centered
				aria-labelledby="contained-modal-title-vcenter"
				className="disclaimer-popup"
				size="md"
				backdrop="static"
				onHide={() => setShowCovidDisclaimerModal(false)}
			>
				<Modal.Header className="position-relative" closeButto>
					{' '}
					{/* temporarily removed closeButton */}
					{/* <p className="back cursor-pointer fw-reg" onClick={() => history.go(-1)} >
              <img src={arabic ? rightArrow : leftArrow} alt="arrow" className={`icon-18 ${arabic ? "ml-2" : "mr-2"}`} />
              {t('common.Back')}
            </p> */}
					<h2 className="title">
						{t('seatlayout_page.covid-disclaimer-popup.important-information')}
					</h2>
				</Modal.Header>
				<Modal.Body>
					{/* <div className="message border-grey-bottom">
              <p className="=">
                {t("seatlayout_page.covid-disclaimer-popup.subtitle")}
              </p>
            </div> */}

					{/* {country_data && country_data.country_short_code === "BH" && (
              <div className="message border-grey-bottom">
                <p className="">
                  {t("seatlayout_page.covid-disclaimer-popup.bh-green-sheild")}
                </p>
              </div>
            )} */}

					{showDetails &&
						showDetails.SessionAttribute &&
						showDetails.SessionAttribute.toLowerCase() === 'junior' && (
							<div className="message border-grey-bottom">
								<p className="">{t('seatlayout_page.covid-disclaimer-popup.junior-message')}</p>
							</div>
						)}

					<div className="message border-grey-bottom py-1">
						<p className="">
							{t('seatlayout_page.covid-disclaimer-popup.fnb-from-outside-not-allowed')}
						</p>
					</div>

					{showDetails && showDetails.rating_tnc && showDetails.rating_tnc[0] && (
						<div className="message border-grey-bottom py-3">
							{/* <h3 className="title mt-0 mb-1">
                    {t("seatlayout_page.covid-disclaimer-popup.rules-of-entry")}
                  </h3> */}
							<div className="d-flex align-items-center justify-content-center">
								{ratingToImageMapping[showDetails.rating] && (
									<img
										src={ratingToImageMapping[showDetails.rating]}
										height="70"
										width="70"
										className="mr-3"
									/>
								)}
								<label for="tnc3" className="mt-n1 mb-0 text-center">
									<strong className="frs-18">{showDetails && showDetails.rating}</strong> :{' '}
									{arabic
										? showDetails && showDetails.rating_tnc[0].tnc_ar
										: showDetails && showDetails.rating_tnc[0].tnc}
								</label>
							</div>
						</div>
					)}

					{cities && showDetails && (
						<div className="message my-1">
							<p className={`showtime-popup-msg ${arabic ? 'text-right' : 'text-left'}`}>
								<strong>{t('common.cinema')}</strong>:{' '}
								{arabic ? showDetails?.cinema_name_ar : showDetails?.cinema_name} <br />
								<strong>{t('common.city')}</strong>:{' '}
								{arabic
									? cities?.find((city) => city.city_id === showDetails?.city_id)?.city_name_ar
									: cities?.find((city) => city.city_id === showDetails?.city_id)?.city_name}{' '}
								<br />
								<strong>{t('common.showdate')}</strong>:{' '}
								{moment.utc(showDetails?.Showtime).format('DD-MM-YYYY')} <br />
								<strong>{t('common.showtime')}</strong>:{' '}
								{moment.utc(showDetails?.Showtime).format('hh:mm A')}
								<br />
								<p className={`mb-0 pb-0 text-center ${arabic ? '' : ''}`}>
									{t('movie_page.seats-cannot-be-swapped-later')}
								</p>
							</p>
						</div>
					)}

					<div className="check-bx">
						{/* <div className="d-flex pb-3">
                <input id="tnc2" type="checkbox" className="mr-3" checked={covidTnc1} onChange={e => setCovidTnc1(e.target.checked)} />
                <label for="tnc2" className="mt-n1">{t('seatlayout_page.covid-disclaimer-popup.tnc1')}</label>
              </div>
              <div className="d-flex pb-3">
                <input id="tnc2" type="checkbox" className="mr-3" checked={covidTnc2} onChange={e => setCovidTnc2(e.target.checked)} />
                <label for="tnc2" className="mt-n1">{t('seatlayout_page.covid-disclaimer-popup.tnc2')}</label>
              </div> */}
						{/* {(showDetails && showDetails.rating_tnc && showDetails.rating_tnc[0]) && <div className="d-flex pb-3">
                <input id="tnc3" type="checkbox" className="mr-3" checked={covidTnc3} onChange={e => setCovidTnc3(e.target.checked)} />
                <label for="tnc3" className="mt-n1">{showDetails && showDetails.rating} - {arabic ? (showDetails && showDetails.rating_tnc[0].tnc_ar) : (showDetails && showDetails.rating_tnc[0].tnc)}</label>
              </div>} */}
						{/* {(covidDisclaimerErrors && (covidDisclaimerErrors.covidTnc1 || covidDisclaimerErrors.covidTnc2 || covidDisclaimerErrors.covidTnc3)) &&
                <span className="form-error-text d-block text-align-center">{t('seatlayout_page.errors.accept-tnc')}</span>
              } */}
					</div>

					<button
						className={`btn blue-btn px-5 mt-3 btn-center`}
						onClick={() => setShowCovidDisclaimerModal(false)}
					>
						{t('common.CONFIRM')}
					</button>
				</Modal.Body>
			</Modal>

			<Modal
				show={weelchair}
				centered
				aria-labelledby="contained-modal-title-vcenter"
				className="disclaimer-popup"
				// style={{ backgroundColor: 'black' }}
				size="md"
				backdrop="static"
				onHide={() => setweelchair(false)}
			>
				<Modal.Header className="position-relative" closeButto>
					<h2 className="">
						{`Wheel Chair`}
					</h2>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex flex-column align-items-center">
						<img src={wheelChair} height="45" width="45" className="mb-4" />
						<h4>Notice</h4>
					</div>
					<p>You have selected a wheelChair space which is for wheelChair user only</p>

					<button
						className={`btn blue-btn px-5 mt-3 btn-center`}
						onClick={() => setweelchair(false)}
					>
						{'ok'}
					</button>
				</Modal.Body>
			</Modal>

			<div className="bg-light-black">
				<Container fluid>
					<div className=''>


						<Row>
							{/* <Col>
							<div className="d-flex align-items-center">
								<div>
									<img
										src={
											!isImageURLNull(showDetails.movie_image_url_1)
												? showDetails.movie_image_url_1
												: movieDef
										}
										alt="poster"
										height="300"
										className=""
									/>
								</div>
								<div className="d-flex flex-column kumbh px-4">
									<p className="fw-bold mb-2">
										{showDetails.movie_title}{' '}
										{showDetails.SessionDimension ? showDetails.SessionDimension : ''}{' '}
										{showDetails.movie_lang
											? showDetails.movie_lang.substring(0, 2).toUpperCase()
											: ''}
									</p>
									<p className="fw-reg mb-2">{t('Date')}</p>
									<p className="fw-bold mb-2">
										{moment(showDetails.Showtime).format('DD MMM YYYY')}
									</p>
									<p className="fw-reg mb-2">{t('Show Details')}</p>
									<p className="fw-bold">{moment(showDetails.Showtime).format('HH:mm')}</p>
								</div>
								<div className="d-flex flex-column kumbh px-4">
									<div className="group frl-12 frm-10 frs-8 fw-reg mb-2">
										<span className="border-right pr-2">{showDetails.rating}</span>
										<span className="pl-2">{timeConvert(showDetails.run_time)}</span>
									</div>
									<p className="fw-reg mb-2">{t('Seats')}</p>
									<p className="fw-bold mb-2">
										{seats.length > 0 ? seats.toString() : t('seatlayout_page.seats-not-selected')}
									</p>
								</div>
							</div>
						</Col> */}

							<Col xs={12} className=''>
								<div className="ticket-header bb-1 pb-3">
									<Row className="">
										<Col xs={12}>
											<div className="d-flex">
												{/* <img
													src={
														!isImageURLNull(showDetails.movie_image_url_1)
															? showDetails.movie_image_url_1
															: movieDef
													}
													className=""
												/> */}
												<div className="ml-1 d-flex flex-column align-items-center w-100">
													<Row className="mx-0 w-100 pb-2 pt-1">
														<Col md={6}>
															<span className="fw-bold frs-28 text-limit v3 lh-1">
																{arabic ? showDetails.movie_title_ar : showDetails.movie_title}
																{showDetails.SessionDimension
																	? '(' + showDetails.SessionDimension + ')'
																	: ''}
																{showDetails.movie_lang
																	? '(' + showDetails.movie_lang.substring(0, 2).toUpperCase() + ')'
																	: ''}
															</span>
														</Col>
														{/* NaNhNaNm  removed*/}
														{/* <Col md={4}>
															<div className="group px-2">
																<span
																	className={`${arabic ? 'border-left pl-2' : 'border-right pr-2'}`}
																>
																	{showDetails.rating}
																</span>
																<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
																	{timeConvert(showDetails.run_time)}
																</span>
															</div>
														</Col> */}
													</Row>
													<Row className="mx-0 w-100">
														<Col xs={6}>
															<span>
																<p>{t('seatlayout_page.Date')}</p>
																<h4>{moment.utc(show_date).format('Do MMMM, YYYY')}</h4>
															</span>
														</Col>
														<Col xs={6}>
															<span>
																<p>{t('seatlayout_page.Seats')}</p>
																<h4>
																	{seatName.length > 0
																		? seatName.sort().join(', ')
																		: t('seatlayout_page.seats-not-selected')}
																</h4>
															</span>
														</Col>
													</Row>
													<Row className="mx-0 w-100">

														<Col xs={6}>
															<span>
																{/* <p>{t('seatlayout_page.voucher-code')}</p> */}
																<p>Movie</p>
																<h4>
																	{movieDetails[0]?.original_title_of_movie}
																</h4>
															</span>
														</Col>

														<Col xs={6}>
															<span>
																<p>{t('seatlayout_page.Show Details')}</p>
																<h4>
																	{showDetails.SessionAttribute
																		? translateSessionAttribute(
																			arabic,
																			showDetails.SessionAttribute
																		)
																		: translateSessionAttribute(arabic, 'premium')}
																	, {` ${show_time}`}
																</h4>
															</span>
														</Col>

														{/* <Col xs={6}>
															<span>
																<p>{t('seatlayout_page.voucher-code')}</p>
																<h4>
																	{voucherCodeData
																		? voucherCode
																		: t('seatlayout_page.No Voucher Added')}
																</h4>
															</span>
														</Col> */}

														{/* {showDetails && showDetails.cinema_has_voucher === 'Y' && (
															<Col xs={3} className="d-flex align-items-center">
																<span
																	className="blue-btn px-4"
																	onClick={() => setShowVoucherPopup(true)}
																>
																	{voucherCodeData
																		? t('seatlayout_page.CHANGE')
																		: t('seatlayout_page.ADD')}{' '}
																	{t('seatlayout_page.VOUCHER')}
																</span>
															</Col>
														)} */}
													</Row>
													{/* <div className="d-flex flex-column justify-content-between h-100 w-50">
														<div className="label-ticket">
														<h6 className="right">{showDetails.rating}</h6>
														<h6 className="left">{timeConvert(showDetails.run_time)}</h6>
													</div>
													</div>
													<div className="pl-4 d-flex flex-column align-items-start justify-content-between h-100 w-50">
													</div> */}
												</div>
											</div>
										</Col>
									</Row>
								</div>
							</Col>

						</Row>
						{voucherCodeData && voucherCodeData.isBogo && (
							<Row className="mb-3 d-none d-md-flex sticky-alert-top">
								<Col>
									<Alert variant="success">
										<Alert.Heading>{t('seatlayout_page.errors.imp-msg')}</Alert.Heading>
										<p>{t('seatlayout_page.bogo-message')}</p>
									</Alert>
								</Col>
							</Row>
						)}
						{voucherSeatsExceededError && (
							<Row className="mb-3 d-none d-md-flex sticky-alert-top">
								<Col>
									<Alert
										variant="danger"
										onClose={() => setVoucherSeatsExceededError(false)}
										dismissible
									>
										<Alert.Heading>{t('seatlayout_page.errors.imp-msg')}</Alert.Heading>
										<p>
											{t('seatlayout_page.You can select only')}{' '}
											{voucherCodeData.isBogo
												? voucherCodeData.numberOfRedemptionsRemaining * 2
												: voucherCodeData.numberOfRedemptionsRemaining}{' '}
											{t('seatlayout_page.Seats')}
										</p>
									</Alert>
								</Col>
							</Row>
						)}
						{showSeatLimitError && (
							<Row className="my-3 d-none d-md-flex sticky-alert-top">
								<Col>
									<Alert variant="danger" onClose={() => setShowSeatLimitError(false)} dismissible>
										<Alert.Heading>{t('seatlayout_page.errors.imp-msg')}</Alert.Heading>
										<p>{t('seatlayout_page.errors.seat-limit-err')}</p>
									</Alert>
								</Col>
							</Row>
						)}
						{showSeatLimitError && (
							<Row className="my-3 d-none d-md-flex sticky-alert-top">
								<Col>
									<Alert variant="danger" onClose={() => setShowSeatLimitError(false)} dismissible>
										<Alert.Heading>{t('seatlayout_page.errors.imp-msg')}</Alert.Heading>
										<p>{t('seatlayout_page.errors.seat-limit-err')}</p>
									</Alert>
								</Col>
							</Row>
						)}
						<Row className="">
							<Col>
								<div className="position-relative">
									<div className="stw">
										<img className="img-fluid" src={screen} alt="Screen" />
									</div>
									<div className="stw-text">{showDetails.ScreenName}</div>
								</div>
							</Col>
						</Row>
						<div className='text-center '>
							{allSeats && allSeats.length > 0 && allSeats.map((seatTypes, seatTypeIndex) =>
								allSeats[seatTypeIndex - 1]?.sl_row_num === seatTypes.sl_row_num
									? (
										<>
											<span className="text-center bg-blue-200 block text-nowrap">
												{seatTypes.srt_name != 'No Seat' ? (
													seatTypes.srt_name === 'Normal' ? (
														<>
															<img
																onClick={
																	seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																		? null
																		: offers.length > 0
																			? offers[0]?.available_seat_types.length == 0
																				? () =>
																					addseat(
																						seatTypes.sl_id,
																						seatTypes.rate_card_group[0],
																						seatTypes.sst_id,
																						seatTypes.sl_seat_name
																					)
																				: offers[0].available_seat_types.includes(seatTypes.sst_id)
																					? () =>
																						addseat(
																							seatTypes.sl_id,
																							seatTypes.rate_card_group[0],
																							seatTypes.sst_id,
																							seatTypes.sl_seat_name
																						)
																					: null
																			: () =>
																				addseat(
																					seatTypes.sl_id,
																					seatTypes.rate_card_group[0],
																					seatTypes.sst_id,
																					seatTypes.sl_seat_name
																				)
																}
																// onClick={() => addseat(seatTypes.sl_id, seatTypes.rate_card_group[0], seatTypes.sst_id, seatTypes.sl_seat_name)}

																src={
																	seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																		? occupiedSeat
																		: selectSeats.includes(seatTypes.sl_id)
																			? selectedSeat
																			: offers.length > 0
																				? offers[0]?.available_seat_types.length == 0
																					? whiteSeat
																					: offers[0]?.available_seat_types.includes(seatTypes?.sst_id)
																						? whiteSeat
																						: occupiedSeat
																				: whiteSeat
																}
																className="inline bg-blue-500"
																height="25"
																width="25"
															/>
														</>
													) : seatTypes.srt_name === 'JR Dbb'
														? (
															seatTypeIndex % 2 == 0
																?
																<>
																	<img
																		onClick={
																			seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																				? null
																				: () => {
																					addseat(
																						seatTypes.sl_id,
																						seatTypes.rate_card_group[0],
																						seatTypes.sst_id,
																						seatTypes.sl_seat_name
																					)
																					addseat(
																						allSeats[seatTypeIndex + 1].sl_id,
																						allSeats[seatTypeIndex + 1].rate_card_group[0],
																						allSeats[seatTypeIndex + 1].sst_id,
																						allSeats[seatTypeIndex + 1].sl_seat_name
																					)
																					setbeanbag(false)

																				}
																		}
																		// onClick={() => addseat(seatTypes.sl_id, seatTypes.rate_card_group[0], seatTypes.sst_id, seatTypes.sl_seat_name)}

																		src={
																			seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																				? occupiedSeat
																				: selectSeats.includes(seatTypes.sl_id)
																					? selectedSeat
																					: offers.length > 0
																						? offers[0]?.available_seat_types.length == 0
																							? whiteSofa
																							: offers[0]?.available_seat_types.includes(seatTypes?.sst_id)
																								? whiteSofa
																								: occupiedSeat
																						: whiteSofa
																		}
																		className="inline bg-blue-500"
																		height="25"
																		width="25"
																	/>
																</>
																: <></>

														) : seatTypes.srt_name === 'House Seat' ? (
															<>
																<img
																	src={occupiedSeat}
																	className="inline  bg-blue-500"
																	height="25"
																	width="25"
																/>
															</>
														) : seatTypes.srt_name === 'Wheel Chair' ? (
															<>
																<img
																	// onClick={() => {
																	// 	if (
																	// 		!seatTypes?.is_booking_done &&
																	// 		seatTypes.seat_reserve_type_id !== 8 &&
																	// 		(offers.length === 0 ||
																	// 			offers[0]?.available_seat_types.length === 0 ||
																	// 			offers[0].available_seat_types.includes(seatTypes?.sst_id))
																	// 	) {
																	// 		addseat(
																	// 			seatTypes.sl_id,
																	// 			seatTypes.rate_card_group[0],
																	// 			seatTypes.sst_id,
																	// 			seatTypes.sl_seat_name
																	// 		);
																	// 		setweelchair(true); // Set wheelchair to true
																	// 	} 
																	// 	else if (offers[0]?.available_seat_types.includes(seatTypes?.sst_id)) {
																	// 		setweelchair(false);
																	// 		 // Set wheelchair to false
																	// 	}
																	// }}

																	onClick={
																		seatTypes?.is_booking_done ||
																			seatTypes.seat_reserve_type_id == 8
																			? null
																			: offers.length > 0
																				? offers[0]?.available_seat_types
																					.length == 0
																					? () => {
																						addseat(
																							seatTypes.sl_id,
																							seatTypes.rate_card_group[0],
																							seatTypes.sst_id,
																							seatTypes.sl_seat_name
																						);

																					}
																					: offers[0].available_seat_types.includes(
																						seatTypes.sst_id
																					)
																						? () => {
																							addseat(
																								seatTypes.sl_id,
																								seatTypes.rate_card_group[0],
																								seatTypes.sst_id,
																								seatTypes.sl_seat_name
																							)
																						}
																						: null
																				: () => {
																					addseat(
																						seatTypes.sl_id,
																						seatTypes.rate_card_group[0],
																						seatTypes.sst_id,
																						seatTypes.sl_seat_name
																					)

																					!selectSeats.includes(seatTypes.sl_id)
																						? setweelchair(true)
																						: setweelchair(false)

																				}
																	}


																	// src={selectSeats.includes(seatTypes.sl_id) ? selectedSeat : wheelChair}

																	src={
																		seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																			? occupiedSeat
																			: selectSeats.includes(seatTypes.sl_id)
																				? selectedSeat
																				: offers.length > 0
																					? offers[0]?.available_seat_types.length == 0
																						? wheelChair
																						: offers[0]?.available_seat_types.includes(seatTypes?.sst_id)
																							? wheelChair
																							: occupiedSeat
																					: wheelChair
																	}
																	// src={
																	//   seatTypes?.is_booking_done
																	//     ? occupiedSeat
																	//     : selectSeats.includes(seatTypes.sl_id)
																	//       ? selectedSeat
																	//       : offers.length > 0
																	//         ? offers[0]?.available_seat_types.length == 0
																	//           ? wheelChair
																	//           : offers[0]?.available_seat_types.includes(seatTypes?.sst_id)
																	//             ? wheelChair
																	//             : occupiedSeat
																	//         : wheelChair
																	// }
																	className="inline  bg-blue-500"
																	height="25"
																	width="25"
																/>
															</>
														) : (
															<span className="inline w-1 text-white"></span>
														)
												) : (
													<span className="inline w-1 p-1 text-white"></span>
												)}{' '}
											</span>
										</>
									)
									: (
										<>
											<div
												className=" seat-layout-table position-relative"
												id="seat-layout-table"
												style={{ textTransform: 'capitalize' }}
											>
												{allSeats[seatTypeIndex - 1]?.sst_seat_type != seatTypes.sst_seat_type ? (
													<>
														<div className=" category-title">
															<span
																className="text-title-mobile "
																style={{ textTransform: 'capitalize' }}
															>
																<p>{seatTypes.sst_seat_type.toLowerCase()}</p>
															</span>
														</div>
													</>
												) : (
													<></>
												)}
											</div>

											<br />

											{/* <span className="absolute left-0">
												{seatTypes?.sl_group_id?.replace(/[^a-z]/gi, '')}
											</span> */}

											{seatTypes.srt_name != 'No Seat'
												?
												(
													<>
														<div className={`${arabic ? 'text-left px-4' : ''}`}>
															<span className="position-absolute start-0 seatName w-2 d-inline ">
																<span className="fw-bold d-inline-block">
																	{allSeats[
																		seatTypeIndex
																	].sl_seat_name?.replace(/[0-9]/g, "")}
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																</span>

															</span>
														</div>

													</>
												)
												: <></>
											}


											{
												seatTypes.srt_name != 'No Seat'
													? (

														(
															seatTypes.srt_name === 'Normal' ? (
																<>
																	<img
																		onClick={
																			seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																				? null
																				: offers.length > 0
																					? offers[0]?.available_seat_types.length == 0
																						? () =>
																							addseat(
																								seatTypes.sl_id,
																								seatTypes.rate_card_group[0],
																								seatTypes.sst_id,
																								seatTypes.sl_seat_name
																							)
																						: offers[0].available_seat_types.includes(seatTypes.sst_id)
																							? () =>
																								addseat(
																									seatTypes.sl_id,
																									seatTypes.rate_card_group[0],
																									seatTypes.sst_id,
																									seatTypes.sl_seat_name
																								)
																							: null
																					: () =>
																						addseat(
																							seatTypes.sl_id,
																							seatTypes.rate_card_group[0],
																							seatTypes.sst_id,
																							seatTypes.sl_seat_name
																						)
																		}
																		// onClick={() => addseat(seatTypes.sl_id, seatTypes.rate_card_group[0], seatTypes.sst_id, seatTypes.sl_seat_name)}

																		src={
																			seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																				? occupiedSeat
																				: selectSeats.includes(seatTypes.sl_id)
																					? selectedSeat
																					: offers.length > 0
																						? offers[0]?.available_seat_types.length == 0
																							? whiteSeat
																							: offers[0]?.available_seat_types.includes(seatTypes?.sst_id)
																								? whiteSeat
																								: occupiedSeat
																						: whiteSeat
																		}
																		className="inline bg-blue-500"
																		height="25"
																		width="25"
																	/>
																</>
															) : seatTypes.srt_name === 'JR Dbb'
																? (
																	seatTypeIndex % 2 == 0
																		?
																		<>
																			<img
																				onClick={
																					seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																						? null
																						: () => {
																							addseat(
																								seatTypes.sl_id,
																								seatTypes.rate_card_group[0],
																								seatTypes.sst_id,
																								seatTypes.sl_seat_name
																							)
																							addseat(
																								allSeats[seatTypeIndex + 1].sl_id,
																								allSeats[seatTypeIndex + 1].rate_card_group[0],
																								allSeats[seatTypeIndex + 1].sst_id,
																								allSeats[seatTypeIndex + 1].sl_seat_name
																							)
																							setbeanbag(false)

																						}
																				}
																				// onClick={() => addseat(seatTypes.sl_id, seatTypes.rate_card_group[0], seatTypes.sst_id, seatTypes.sl_seat_name)}

																				src={
																					seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																						? occupiedSeat
																						: selectSeats.includes(seatTypes.sl_id)
																							? selectedSeat
																							: offers.length > 0
																								? offers[0]?.available_seat_types.length == 0
																									? whiteSofa
																									: offers[0]?.available_seat_types.includes(seatTypes?.sst_id)
																										? whiteSofa
																										: occupiedSeat
																								: whiteSofa
																				}
																				className="inline bg-blue-500"
																				height="25"
																				width="25"
																			/>
																		</>
																		: <></>

																) : seatTypes.srt_name === 'House Seat' ? (
																	<>
																		<img
																			src={occupiedSeat}
																			className="inline  bg-blue-500"
																			height="25"
																			width="25"
																		/>
																	</>
																) : seatTypes.srt_name === 'Wheel Chair' ? (
																	<>
																		<img
																			// onClick={() => {
																			// 	if (
																			// 		!seatTypes?.is_booking_done &&
																			// 		seatTypes.seat_reserve_type_id !== 8 &&
																			// 		(offers.length === 0 ||
																			// 			offers[0]?.available_seat_types.length === 0 ||
																			// 			offers[0].available_seat_types.includes(seatTypes?.sst_id))
																			// 	) {
																			// 		addseat(
																			// 			seatTypes.sl_id,
																			// 			seatTypes.rate_card_group[0],
																			// 			seatTypes.sst_id,
																			// 			seatTypes.sl_seat_name
																			// 		);
																			// 		setweelchair(true); // Set wheelchair to true
																			// 	} 
																			// 	else if (offers[0]?.available_seat_types.includes(seatTypes?.sst_id)) {
																			// 		setweelchair(false);
																			// 		 // Set wheelchair to false
																			// 	}
																			// }}

																			onClick={
																				seatTypes?.is_booking_done ||
																					seatTypes.seat_reserve_type_id == 8
																					? null
																					: offers.length > 0
																						? offers[0]?.available_seat_types
																							.length == 0
																							? () => {
																								addseat(
																									seatTypes.sl_id,
																									seatTypes.rate_card_group[0],
																									seatTypes.sst_id,
																									seatTypes.sl_seat_name
																								);

																							}
																							: offers[0].available_seat_types.includes(
																								seatTypes.sst_id
																							)
																								? () => {
																									addseat(
																										seatTypes.sl_id,
																										seatTypes.rate_card_group[0],
																										seatTypes.sst_id,
																										seatTypes.sl_seat_name
																									)
																								}
																								: null
																						: () => {
																							addseat(
																								seatTypes.sl_id,
																								seatTypes.rate_card_group[0],
																								seatTypes.sst_id,
																								seatTypes.sl_seat_name
																							)

																							!selectSeats.includes(seatTypes.sl_id)
																								? setweelchair(true)
																								: setweelchair(false)

																						}
																			}


																			// src={selectSeats.includes(seatTypes.sl_id) ? selectedSeat : wheelChair}

																			src={
																				seatTypes?.is_booking_done || seatTypes.seat_reserve_type_id == 8
																					? occupiedSeat
																					: selectSeats.includes(seatTypes.sl_id)
																						? selectedSeat
																						: offers.length > 0
																							? offers[0]?.available_seat_types.length == 0
																								? wheelChair
																								: offers[0]?.available_seat_types.includes(seatTypes?.sst_id)
																									? wheelChair
																									: occupiedSeat
																							: wheelChair
																			}
																			// src={
																			//   seatTypes?.is_booking_done
																			//     ? occupiedSeat
																			//     : selectSeats.includes(seatTypes.sl_id)
																			//       ? selectedSeat
																			//       : offers.length > 0
																			//         ? offers[0]?.available_seat_types.length == 0
																			//           ? wheelChair
																			//           : offers[0]?.available_seat_types.includes(seatTypes?.sst_id)
																			//             ? wheelChair
																			//             : occupiedSeat
																			//         : wheelChair
																			// }
																			className="inline  bg-blue-500"
																			height="25"
																			width="25"
																		/>
																	</>
																) : (
																	<span className="inline w-1 text-white"></span>
																)
														)
													)
													: (
														<>
															<div className={`${arabic ? 'text-left px-4' : ''}`}>
																<span className="position-absolute start-0 seatName w-2 d-inline ">
																	<span className="fw-bold d-inline-block">
																		{allSeats[
																			seatTypeIndex + 8
																		].sl_seat_name?.replace(/[0-9]/g, "")}
																		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																	</span>

																</span>
															</div>

															{/* <span className={` inline-block min-h-fit min-w-fit`}>{breaklength}{seatTypeIndex}</span > */}
														</>
													)
											}
										</>
									)
							)}
						</div >
						<Row className=''>
							<Col className="seats-desc py-5">
								<div className="d-flex flex-wrap justify-content-center align-items-center">
									<span>
										<img src={occupiedSeat} alt="img" />
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{t('seatlayout_page.chair-types.occupied')}
										</span>
									</span>
									<span>
										<img src={whiteSeat} alt="img" />
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{t('seatlayout_page.chair-types.open')}
										</span>
									</span>
									<span>
										<img src={selectedSeat} alt="img" />
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{t('seatlayout_page.chair-types.selected')}
										</span>
									</span>
									<span>
										<img src={whiteSofa} alt="img" />
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{t('seatlayout_page.chair-types.lounger')}
										</span>
									</span>
									<span>
										<img src={wheelChair} alt="img" />
										{t('seatlayout_page.chair-types.wheelchair')}
									</span>
								</div>
							</Col>
						</Row>

						<Row className=" align-items-center py-3 mb-40">
							<Col className=''>
								<div onClick={onClickBack} className="go_back">
									<div className="button_wrap">
										<div className="go_back_button"></div>
									</div>
									<h2 className="big_text">{t('GO BACK')}</h2>
								</div>
							</Col>
						</Row>

						<Row className="sticky-bar-margin">
							<Col sm={12}>
								{seatSelectError && (
									<Alert variant="dark" className="text-center">
										{seatSelectError.message}
										{t('seatlayout_page.errors.try-later')}
									</Alert>
								)}
							</Col>
						</Row>

						<Row className="align-items-center bgb mt-5 sticky-movie-bar">
							<Col xs={12} className="seat-footer">
								<Row className="py-4">

									{/* <Col md={4} className=''>
										<div onClick={onClickBack} className="go_back">
											<div className="button_wrap">
												<div className="go_back_button"></div>
											</div>
											<h2 className="big_text">{t('GO BACK')}</h2>
										</div>
									</Col> */}

									<Col md={8}>
										<div className={`d-flex px-3 ${arabic ? 'bl-1' : 'br-1'}`}>
											<div>
												<h3 className="f-grey">{t('seatlayout_page.Total Payable')}</h3>
												<h1 className="fw-bold">
													{totalamount.toFixed(2)} OMR
													{/* {arabic
														? currency.currency_short_code_ar + 'heyyyy'
														: currency.currency_short_code} */}
												</h1>
												{/* {selectedTicketPrice.priceInCents === 0 ? (
													<h1 className="fw-bold">
														{totalamount}{' '}
														{arabic
															? currency.currency_short_code_ar + 'heyyyy'
															: currency.currency_short_code}
													</h1>
												) : (
													<h1 className="fw-bold">
														{(
															(selectedTicketPrice.priceInCents + bookingFee) /
															currency.divided_by
														).toFixed(2)}{' '}
														{arabic
															? currency.currency_short_code_ar
															: currency.currency_short_code}
													</h1>
												)} */}
												<h5>
													{t('fnb_page.taxes')}: {taxAmount.toFixed(2)} OMR
													{/* {(
														(selectedTicketPrice.taxInCents +
															(selectedTicketPrice.priceInCents === 0 ? 0 : bookingFeeTax)) /
														currency.divided_by
													).toFixed(2)}{' '} */}
													{/* {arabic ? currency.currency_short_code_ar : currency.currency_short_code} */}
												</h5>
											</div>
											<div className={`flex-center ${arabic ? 'pr-4' : 'pl-4'}`}>
												<h6 className="custom-label">{t('seatlayout_page.Total Bill')}</h6>
											</div>
										</div>
									</Col>
									<Col md={4} className="d-flex justify-content-center align-items-center">
										<span
											className={`blue-btn btn-lg px-5 ${selectLayout.length === 0 ? 'inactive-btn' : ''
												}`}
											onClick={() => (selectLayout.length > 0 ? onProceed() : null)}
										>
											{showProceedSpinner ? (
												<Spinner animation="border" role="status" className="spinner">
													<span className="sr-only">Loading...</span>
												</Spinner>
											) : (
												t('common.PROCEED')
											)}
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>


				</Container>

				<Modal
					// show={show}
					// onHide={handle}
					centered
					aria-labelledby="contained-modal-title-vcenter"
					className="modal-4"
				>
					<div className="wrapper">
						<div className="r1 bg-light-black">
							<div className="pt-4 mb-40">
								<Col xs={10} sm={8} className="mx-auto">
									<ul className="list-inline feature-links d-flex justify-content-around">
										<li className="list-inline-item">
											<a href="#" id="pnoBtn" className="active" onClick={showPno}>
												PROMOTIONS
											</a>
										</li>
										<li className="list-inline-item">
											<a href="#" id="bankOffersBtn" className="" onClick={showBankOffers}>
												BANK OFFERS
											</a>
										</li>
									</ul>
								</Col>
							</div>
							<div id="pno" className="d-flex flex-column align-items-center">
								<Col xs={11} className="pno-section">
									<Row className="">
										{el16.map((x, index) => (
											<Col sm={6} className="mb-31">
												<div className="w-100 bg-light-black-2 d-flex justify-content-between align-items-center p-2 brds-8">
													<img className="img-fluid mxh-58 mxw-58" src={ip1} alt="Food Item" />
													<div className="d-flex flex-column justify-content-between px-2">
														<p className="kumbh fw-reg frs-14">Win 40% Cashback every minute</p>
														<p className="kumbh fw-bold frs-12 blurred-white-2 ls-1">+ DETAILS</p>
													</div>
													<a href="#" className="blue-btn mxh-31 mxw-70 frxl-14 frs-12 ls-15">
														APPLY
													</a>
												</div>
											</Col>
										))}
									</Row>
								</Col>
							</div>
							<div id="bankOffers" className="d-none flex-column align-items-center">
								<Col xs={11} className="pno-section">
									<Row className="">
										{el16.map((x, index) => (
											<Col sm={6} className="mb-31">
												<div className="w-100 bg-light-black-2 d-flex justify-content-between align-items-center p-2 brds-8">
													<img className="img-fluid mxh-58 mxw-58" src={ip1} alt="Food Item" />
													<div className="d-flex flex-column justify-content-between px-2">
														<p className="kumbh fw-reg frs-14">
															Get 40SAR Cashback on Mastercard Elite cards
														</p>
														<p className="kumbh fw-bold frs-12 blurred-white-2 ls-1">+ DETAILS</p>
													</div>
													<a href="#" className="blue-btn mxh-31 mxw-70 frxl-14 frs-12 ls-15">
														APPLY
													</a>
												</div>
											</Col>
										))}
									</Row>
								</Col>
							</div>
						</div>
						<div className="r2 bg-light-black-2 d-flex justify-content-center align-items-center mxh-107">
							<div className="d-flex kumbh">
								<span className="fw-reg blurred-white frs-14 frxl-16 pr-3">
									DISCOUNT <br />
									CODE
								</span>
								<div className="d-flex mxh-47 bg-greyish brds-8">
									<input type="text" className="h-100 code-input px-3" />
									<a
										href="#"
										className="blue-btn ls-2 h-100 fw-bold frs-12 frm-14 mxw-90"
									/*onClick={handleClose}*/
									>
										APPLY
									</a>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<Modal
					show={showVoucherPopup}
					onHide={() => setShowVoucherPopup(false)}
					centered
					aria-labelledby="contained-modal-title-vcenter"
					className="modal-4 for-btn"
				>
					<div className="wrapper">
						<div className="bg-light-black">
							<Modal.Header closeButton className={`p-0 border-0 pt-2 pr-2`}></Modal.Header>
							<div className="text-center py-md-5 px-md-5 px-sm-1 my-5 px-3">
								<h4 className="font-weight-bold">{t('seatlayout_page.voucher-popup.title')}</h4>
								<p className="px-md-5 pt-3">{t('seatlayout_page.voucher-popup.subtitle')}</p>
							</div>
							<div className="kumbh py-4 bg-greyish px-3">
								<h6 className="text-center">{t('seatlayout_page.voucher-popup.enter-code')}</h6>
								{voucherCodeError && voucherCodeError === 'error' && (
									<span className="form-error-text d-block text-align-center text-center">
										{t('seatlayout_page.errors.invalid-voucher')}
									</span>
								)}
								<div className="d-flex flex-column flex-sm-row justify-content-sm-center">
									<input
										type="text"
										onChange={(e) => setVoucherCode(e.target.value)}
										className="mx-auto mx-sm-4 code-input voucher-input px-3 py-2 mr-4 mb-3 mb-sm-0"
									/>
									<a
										href="#"
										className="mx-auto mx-sm-0 blue-btn px-4 frs-16"
										onClick={voucherHandler}
									>
										{voucherCodeLoading ? (
											<Spinner animation="border" variant="light" role="status">
												<span className="sr-only">Loading...</span>
											</Spinner>
										) : (
											t('common.APPLY')
										)}
									</a>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div >
		</div >
	);
}

export default withNamespaces()(SeatLayout);
