import React, { useEffect, useState } from 'react';
import MovieHeader from '@components/partials/MovieHeader';
import {
	Container,
	Row,
	Col,
	Card,
	ListGroup,
	Spinner,
	Alert,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';

import insertcard from '../assets/imgs/svgs/insert-card.svg';
import enterpin from '../assets/imgs/svgs/enter-pin.svg';
import ticket from '../assets/imgs/svgs/ticket.svg';
import help from '../assets/imgs/svgs/help.svg';

function KioskCollectTwo() {
	return (
		<>
			<MovieHeader />
			<div className="kumbh">
				<div className="middle-section">
					<h1 className="text-center fw-bold">
						Please insert your card and <br />
						complete the payment{' '}
					</h1>
					<div>
						<span className="d-flex py-5">
							<img src={insertcard} />
							<h2 className="pl-4">
								Enter your card in the slot <br />
								below
							</h2>
						</span>
						<span className="d-flex py-5">
							<img src={enterpin} />
							<h2 className="pl-4">
								Enter your PIN <br />
								securely
							</h2>
						</span>
						<span className="d-flex py-5">
							<img src={ticket} />
							<h2 className="pl-4">
								Collect your ticket and <br />
								enjoy the show!
							</h2>
						</span>
					</div>
				</div>
				<div className="bt-1 bottom-section">
					<span className="flex-middle py-5">
						<img src={help} />
						<p className="pl-3">
							Reach out to the counter managers or <br />
							call +1 9398483992 for any assistance
						</p>
					</span>
				</div>
			</div>
		</>
	);
}

export default KioskCollectTwo;
