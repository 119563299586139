import React, { useContext, useEffect } from 'react';
import { Container, Button, Modal, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { stopTimer } from '@store/booking/bookingActions';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { globalConfigContext } from '@context/GlobalConfigContext';

function TimeoutContainer(props) {
	const t = props.t;
	const { arabic } = useContext(globalConfigContext);

	const minutes = useSelector((state) => state.booking.timer.minutes);
	const seconds = useSelector((state) => state.booking.timer.seconds);
	const showTimeoutPopup = useSelector((state) => state.booking.showTimeoutPopup);
	const dispatch = useDispatch();
	const history = useHistory();

	const { session_id, cinema_id } = props;

	const handleGoBack = () => {
		if (session_id && cinema_id) {
			history.replace(`/seat-layout/${cinema_id}/${session_id}`);
		} else {
			history.go(-1);
		}
	};

	useEffect(() => {
		if (showTimeoutPopup) {
			dispatch(stopTimer());
		}
	}, [showTimeoutPopup]);

	const handleClose = () => {
		history.go(-1);
	};
	return (
		<div>
			<Modal
				show={showTimeoutPopup}
				onHide={handleGoBack}
				centered
				aria-labelledby="contained-modal-title-vcenter"
				className="modal-time-up"
			>
				<Modal.Header closeButton>
					<p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4">{t('timeout.time-up')}</p>
				</Modal.Header>
				<Modal.Body>
					<p className="kumbh fw-reg frxl-24 frl-20 frs-16">{t('timeout.session-expired')}</p>
				</Modal.Body>
				<Modal.Footer>
					<span onClick={handleGoBack} className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14">
						{t('timeout.go-back')}
					</span>
				</Modal.Footer>
			</Modal>
			{/* <Container fluid> */}
			{/* <Row className="align-items-center bg-light-black p-3 my-5 mmx-30">
                <Col md={6} lg={6} className={`d-flex align-items-center ${arabic ? "bl-lg-1" : "br-lg-1"}`}>
                    <span className={`fw-bold frxl-24 frl-20 frm-16 frs-12 cursor-pointer ${arabic ? "ml-3" : "mr-3"}`}
                        onClick={() => history.go(-1)}
                    >
                        <img src={arabic ? rightArrow : leftArrow} alt="arrow" className={`mxh-31 ${arabic ? "ml-2" : "mr-2"}`} />{t('common.Back')}
                        </span>
                    <img src={props.icon} className={`mxh-69 ${arabic ? "ml-2" : "mr-2"}`} alt="snack" />

                    <p className={`fw-bold frxl-40 frl-32 frm-24 frs-16 text-light ${arabic ? "pr-3" : "pl-3"}`}>
                        {props.title}</p>
                </Col>
                <Col md={6} lg={6} className="text-right d-flex justify-content-end align-items-center">
                    <div className="d-flex justify-content-end align-items-center">
                        <p className="kumbh fw-bold frxl-40 frl-36 frm-32 frs-28 text-light ">{minutes}:{seconds < 10 ? '0' : null}{seconds}</p>
                        <img src={clock} alt="clock" className={`mxh-29 ${arabic ? "pr-2" : "pl-2"}`} />
                    </div>
                    <p className={`fw-reg opacity-40 frxl-14 frl-12 frm-10 frs-8 text-light ${arabic ? "pr-3" : "pl-3"}`}>{t('timeout.till-your-booking-expires')}</p>
                </Col>
            </Row> */}
			{/* </Container> */}
		</div>
	);
}

export default withNamespaces()(TimeoutContainer);
