import {
	FETCH_SEAT_LAYOUT_LOADING,
	FETCH_SEAT_LAYOUT_SUCCESS,
	FETCH_SEAT_LAYOUT_ERROR,
	FETCH_SHOW_DETAILS_SUCCESS,
	SET_SEAT_SELECTED,
	SET_OFFER_SEAT_SELECTED,
	SET_RESERVATION_ID,
	SET_SEAT_SELECT_ERROR,
	SET_TRANSACTION_ID,
	SET_TRANSACTION_ERROR,
	CLEAR_BOOKING_STATE,
	SET_TICKET_PRICE,
	RESET_RESERVATION_ID,
	SET_GUEST_ID,
	START_TIMER,
	STOP_TIMER,
	SET_BOOKING_FEE_DATA,
	SET_ADDITIONAL_DATA,
	CLEAR_ERRORS,
	CLEAR_FOOD_AND_BEVERAGES_STATE,
	CLEAR_SELECTED_SEATS,
	SET_TICKET_DATA,
	CLEAR_TIMEOUT_POPUP,
	SET_VOUCHER_CODE_SUCCESS,
	SET_VOUCHER_CODE_ERROR,
	SET_SESSION_DETAILS,
	SET_USER_DETAILS,
	SET_FINAL_TICKET_DETAILS,
	SET_RESERVATION_DATA
} from './bookingTypes';

import { setTaxPercentInFnb } from '../foodAndBeverages/foodAndBeveragesActions';
import BookingService from '@apiService/BookingService';

var timerInterval = null;

const fetchSeatLayoutLoading = () => {
	return {
		type: FETCH_SEAT_LAYOUT_LOADING,
	};
};

const fetchSeatLayoutSuccess = (data) => {
	return {
		type: FETCH_SEAT_LAYOUT_SUCCESS,
		payload: data,
	};
};

const fetchSeatLayoutError = (err) => {
	return {
		type: FETCH_SEAT_LAYOUT_ERROR,
		payload: err,
	};
};

const fetchShowDetailsSuccess = (data) => {
	return {
		type: FETCH_SHOW_DETAILS_SUCCESS,
		payload: data,
	};
};

const setSeatsSelected = (payload) => {
	return {
		type: SET_SEAT_SELECTED,
		payload: payload,
	};
};

const setOfferSeatsSelected = (payload) => {
	return {
		type: SET_OFFER_SEAT_SELECTED,
		payload: payload,
	};
};

export const setReservationID = (payload) => {
	return {
		type: SET_RESERVATION_ID,
		payload: payload,
	};
};

export const setReservationData = (payload) => {
	return {
		type: SET_RESERVATION_DATA,
		payload: payload,
	};
};


export const resetReservationID = () => {
	return {
		type: RESET_RESERVATION_ID,
		payload: {},
	};
};

export const selectSeats = (payload) => {
	return function (dispatch) {
		dispatch(setSeatsSelected(payload));
	};
};

export const selectOfferSeats = (payload) => {
	console.log(payload, "selectOfferSeats");
	return function (dispatch) {
		dispatch(setOfferSeatsSelected(payload));
	};
};


export const setSeatSelectError = (error) => ({
	type: SET_SEAT_SELECT_ERROR,
	payload: error,
});

export const setTransactionId = (payload) => ({
	type: SET_TRANSACTION_ID,
	payload,
});

export const setTransactionError = (error) => ({
	type: SET_TRANSACTION_ERROR,
	payload: error,
});

export const clearBookingState = () => ({
	type: CLEAR_BOOKING_STATE,
});

export const clearFoodAndBeveragesState = () => ({
	type: CLEAR_FOOD_AND_BEVERAGES_STATE,
});

export const setTicketData = (payload) => ({
	type: SET_TICKET_DATA,
	payload,
});

const setTicketPrice = (payload) => ({
	type: SET_TICKET_PRICE,
	payload,
});

const setGuestId = (payload) => ({
	type: SET_GUEST_ID,
	payload,
});

const startTimer = () => ({
	type: START_TIMER,
	payload: timerInterval,
});

export const resetTimer = () => ({
	type: STOP_TIMER,
});

const setBookingFeeData = (payload) => ({
	type: SET_BOOKING_FEE_DATA,
	payload,
});

const setAdditionalData = (payload) => ({
	type: SET_ADDITIONAL_DATA,
	payload,
});

export const clearSeatLayoutErrors = () => ({
	type: CLEAR_ERRORS,
});

export const clearSelectedSeats = () => ({
	type: CLEAR_SELECTED_SEATS,
});

export const setVoucherCodeSuccess = (payload) => ({
	type: SET_VOUCHER_CODE_SUCCESS,
	payload,
});

export const setVoucherCodeError = (payload) => ({
	type: SET_VOUCHER_CODE_ERROR,
	payload,
});

export const setSessionDetails = (payload) => ({
	type: SET_SESSION_DETAILS,
	payload,
});

export const setUserDetails = (email, mobile) => ({
	type: SET_USER_DETAILS,
	payload: {
		email,
		mobile,
	},
});

export const stopTimer = () => {
	return function (dispatch) {
		clearInterval(timerInterval);
		dispatch(resetTimer());
	};
};

export const clearTimeoutPopup = () => ({
	type: CLEAR_TIMEOUT_POPUP,
});

export const setFinalTicketDetails = (payload) => ({
	type: SET_FINAL_TICKET_DETAILS,
	payload,
});

export const fetchSeatLayoutData = (payload) => {
	// console.log(payload, "Hi mk");
	return function (dispatch) {

		BookingService.GetSeatLayout(payload)
			.then((response) => {
				const seats = response.data;
				console.log(seats, "from")
				dispatch(fetchShowDetailsSuccess(seats));

				if (response.status) {
					// dispatch(
					//   fetchSeatLayoutSuccess(
					//     data.data.seatPlan.SeatLayoutData.Areas,
					//     data.data.isVoucherAvailable
					//   )
					// );

					// dispatch(
					//   setBookingFeeData(data.data.showDetails[0].booking_fee_in_cents)
					// );
					// if (
					//   (data.data.showDetails[0].fnb_tax_percent !== null) |
					//   (data.data.showDetails[0].fnb_tax_percent !== undefined)
					// ) {
					//   dispatch(
					//     setTaxPercentInFnb(data.data.showDetails[0].fnb_tax_percent)
					//   );
					// }
					// if (data.data.covidMessage) {
					//   dispatch(
					//     setAdditionalData({ covidMessage: data.data.covidMessage })
					//   );
					// }
				}
			})
			.catch((err) => {
				dispatch(fetchSeatLayoutError(err.message));
			});
	};
};

export const vistaSetSeats = (
	{
		cinema_id,
		seats_name,
		user_selected_seats,
		session_id,
		selectedTicketPrice,
		userId,
		isGuest,
		voucher_data,
	},
	callback
) => {
	return async (dispatch) => {
		try {
			const response = await BookingService.SelectSeats({
				cinema_id,
				seats_name,
				user_selected_seats,
				session_id,
				selectedTicketPrice,
				userId,
				isGuest,
				voucher_data,
			});
			const { data } = response;
			if (data && data.status) {
				console.log(data, 'pppp');
				dispatch(setReservationID(data.data.reservation_id));
				dispatch(setBookingFeeData(data.data.booking_fee));
				dispatch(setSessionDetails({ session_id, cinema_id }));
				timerInterval = setInterval(() => {
					dispatch(startTimer());
				}, 1000);
				callback();
			}
		} catch (error) {
			if (error.response) {
				dispatch(setSeatSelectError(error.response.data));
			} else {
				dispatch(setSeatSelectError({ message: error.message }));
			}
		} finally {
		}
	};
};

export const cancelTransaction = (payload) => {
	return function (dispatch) {
		BookingService.CancelTransaction(payload)
			.then((response) => {
				if (response && response.status === 200) {
					const { data } = response;
					console.log('data', data);
				}
			})
			.catch((err) => {
				console.log('Cancel Transaction Error', err.message);
			});
	};
};

export const performCheckout = (payload) => {
	return function (dispatch) {
		BookingService.BookSeats(payload)
			.then((response) => {
				if (response && response.status === 200) {
					const { data } = response;
					dispatch(setTransactionId(data.data));
					dispatch(clearBookingState());
					dispatch(clearFoodAndBeveragesState());
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch(setTransactionError(err.response.data.message));
				} else {
					dispatch(setTransactionError(err.message));
				}
			});
	};
};

export const reserveFnbItems = (payload) => {
	return async function (dispatch) {
		try {
			const response = await BookingService.SelectFnbItems(payload);
		} catch (err) {
			console.log(err);
			throw err;
		}
	};
};

export const fetchShowDetails = (payload) => {
	return async function (dispatch) {
		try {
			const response = await BookingService.GetBookingDetails(payload);
			if (response && response.status === 200) {
				dispatch(fetchShowDetailsSuccess(response.data.data));
			}
			return response.data.data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	};
};

export const fetchTicketPrice = (payload) => {
	return async function (dispatch) {
		try {
			const response = await BookingService.GetTicketPrice(payload);
			if (response && response.data.data) {
				dispatch(setTicketPrice(response.data.data));
			}
		} catch (err) {
			console.log(err.message);
		}
	};
};

export const validateVoucher = (payload) => {
	return async function (dispatch) {
		try {
			const response = await BookingService.ValidateVoucher(payload);
			if (response && response.data.data === 'INVALID') {
				dispatch(setVoucherCodeError('error'));
			} else {
				dispatch(
					setVoucherCodeSuccess({ ...response.data.data, voucher_code: payload.voucher_code })
				);
			}
			console.log(response.data);
			return response.data.data;
		} catch (err) {
			console.log(err.response ? err.response : err.message);
			dispatch(setVoucherCodeError(err.message));
		}
	};
};
